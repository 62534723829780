import React, { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import { dbGetActiveClaimWinningsCheck } from '../utilities/api-functions';

/** Utilities */
import useText from "../utilities/use-text";

export default function ClaimWinningsAllowed({ setIsClaimWinningsAllowed }) {
    const [activeClaimWinningsCheck, setActiveClaimWinningsCheck] = useState(null);
    const text = useText();

    async function getActiveClaimWinningsCheck() {
        try {
            const result = await dbGetActiveClaimWinningsCheck();
            console.log(result)
            if (result?.data?.docs?.length === 0) {
                setIsClaimWinningsAllowed(true)

            }
            else if (result?.data?.docs?.length > 0) {
                setActiveClaimWinningsCheck(result?.data?.docs[0]);
                console.log(result?.data?.docs[0]);
            }
        } catch (error) {
            console.log(`Error, getActiveClaimWinningsCheck, ${error}`);
        }
    }

    useEffect(() => {
        !activeClaimWinningsCheck?.id && getActiveClaimWinningsCheck();
    }, [activeClaimWinningsCheck])

    return (
        <>
            {
                activeClaimWinningsCheck?.id && (
                    <Alert
                        variant="success"
                        className="animate__animated animate__faster animate__fadeIn"
                        style={{ fontSize: "14px", margin: "0 0 10px 0" }}
                    >
                        {text('claim_winnings_allowed_message')}
                    </Alert>
                )
            }
        </>
    )
}