import { useEffect } from "react";

export default function CashierScrollTop({ step, container }) {
    useEffect(() => {
        try {
            if(container.current.parentElement.scrollTop > 0) {
                container.current.parentElement.scrollTop = 0;
            }
        } catch (error) {}
    }, [step]);
    return null;
}