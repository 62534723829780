import React, { useEffect, useState, useRef } from "react";
import { dbGetGame, dbLaunchGame, dbUpdatePlayedGames } from "../utilities/api-functions";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Helmet } from "react-helmet-async";

/** Components */
import Loading from '../components/Loading';

/** Utilities */
import useText from "../utilities/use-text";

import swipeUp from '../../assets/images/swipe-up.png';

export default function Game() {
    const { id } = useParams();
    const text = useText();

    const user = useSelector((state) => state.user.data);
    const language = useSelector((state) => state.global.data.language);

    const gameFrame = useRef(null);

    const [swipeVisible, setSwipeVisible] = useState(false);
    const [orientation, setOrientation] = useState('portrait');
    const [isFetching, setIsFetching] = useState(false);
    const [game, setGame] = useState({
        url: null,
        background: null
    });

    let touchstartY = 0
    let touchendY = 0

    function checkDirection() {
        if (touchendY < touchstartY) {
            setSwipeVisible(false);
        }
    }

    function touchStartHandler(e) {
        touchstartY = e.changedTouches[0].screenY
    }

    function touchEndHandler(e) {
        touchendY = e.changedTouches[0].screenY
        checkDirection()
    }

    function resizeHandler(e) {
        if (isMobile && orientation === 'portrait' && window.innerWidth > window.innerHeight) {
            setSwipeVisible(true);
            setOrientation('landscape')
        }

        if (isMobile && orientation === 'landscape' && window.innerWidth < window.innerHeight) {
            setSwipeVisible(false);
            setOrientation('portrait')
        }

        setTimeout(() => { window.scrollTo(0, 0); }, 0)
    }

    function gameFrameHandler(e) {
        /** Check if the iframe is loading our website, go to home page */
        try {
            if (gameFrame.current?.contentWindow?.location?.href?.includes(window?.location?.origin)) {
                window.location.href = `${window.location.origin}`;
            }
        } catch (error) { }
    }

    async function updateLastPlayedGames() {
        /** Generate last played games array */
        let lastPlayedGames = user.lastPlayedGames?.length > 0 ? user.lastPlayedGames.split(',') : [id, '']; //

        /** Add this game to the start or array */
        lastPlayedGames.unshift(id);

        for (let i = 1; i < lastPlayedGames.length; i++) {
            const currId = lastPlayedGames[i];

            if (currId === id) {
                /** If the game was already in the array, remove the game from the array */
                lastPlayedGames.splice(i, 1);
                break;
            }
        }

        const result = lastPlayedGames.join(',');

        try {
            await dbUpdatePlayedGames({ lastPlayedGames: result });
        } catch (error) {
            console.log(`Error, dbUpdatePlayedGames, ${error}`)
        }
    }

    async function launchGame() {
        try {
            if (isFetching) return;

            setIsFetching(true);

            const responseGetGame = await dbGetGame(id);

            if (responseGetGame?.data?.docs?.length < 1) return;

            const responseGame = responseGetGame?.data?.docs[0];

            const responseLaunchGame = await dbLaunchGame({
                game_uuid: isMobile ? responseGame.uuid : responseGame.uuid_desktop || responseGame.uuid,
                hasLobby: responseGame.hasLobby,
                return_url: `${window.location.origin}`,
                cashier_url: `${window.location.origin}?cashier`,
                currency: user?.currency?.code,
                firstName: user?.firstName || 'Player',
                language: language,
                source: responseGame.source
            })

            if (!responseLaunchGame?.data?.url) return;

            setGame({
                url: responseLaunchGame.data.url,
                background: responseGame.background
            })

            updateLastPlayedGames();

            setIsFetching(false);

        } catch (error) {
            console.log(`Error, launchGame, ${error}`)
        }
    }


    useEffect(() => {
        !game.url && launchGame();

        const frame = gameFrame.current;

        if (frame) {
            frame.addEventListener('load', gameFrameHandler);
        }

        if (isMobile) {
            window.addEventListener("resize", resizeHandler);
            document.addEventListener('touchstart', touchStartHandler)
            document.addEventListener('touchend', touchEndHandler)

            if (orientation === 'portrait' && window.innerWidth > window.innerHeight) {
                setSwipeVisible(true);
                setOrientation('landscape')
            }

            if (orientation === 'landscape' && window.innerWidth < window.innerHeight) {
                setSwipeVisible(false);
                setOrientation('portrait')
            }

            document.body.classList.add('game-page-body');
        }

        return () => {
            if (frame) {
                frame.removeEventListener('load', gameFrameHandler);
            }

            window.removeEventListener("resize", resizeHandler);
            document.removeEventListener('touchstart', touchStartHandler)
            document.removeEventListener('touchend', touchEndHandler)

            document.body.classList.remove('game-page-body');
        }

    }, [game.url]);

    return (
        <>
            <Helmet>
                <title>{text('page_game')}</title>
            </Helmet>
            <div className={`game-page-container ${isMobile ? `size-mobile` : ''}`}>
                <div className="game-page-full-hight">
                    <div className="game-page-wrapper">
                        <div className="game-page-background" style={{ backgroundImage: `url(${game.background})` }}></div>
                        <div className="game-frame-container">
                            <div className="game-frame-wrapper">
                                {
                                    game.url ? (
                                        <>
                                            <iframe
                                                src={game.url}
                                                className="game-frame"
                                                name='game'
                                                title="Game frame"
                                                ref={gameFrame}
                                                allowFullScreen={true}
                                                webkitallowfullscreen="true"
                                                mozallowfullscreen="true"
                                            />
                                        </>
                                    ) : (
                                        <div className="game-frame-loading"><Loading /></div>
                                    )
                                }
                            </div>
                        </div>
                        {swipeVisible && (
                            <div className="swipe-up-overlay">
                                <img src={swipeUp} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}