import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { isTablet, isDesktop } from 'react-device-detect';

/** Theme */
import themes from "../../themes";

/** Components */
import RegistrationFormWithVerify from '../components/RegistrationFormWithVerify';
import OffcanvasBtnClose from '../components/OffcanvasBtnClose';

/** Utilities */
import useText from "../utilities/use-text";

export default function Registration() {
    const navigate = useNavigate();
    const location = useLocation();
    const text = useText();

    const theme = useSelector((state) => state.global.data.theme?.name);

    const [show, setShow] = useState(true);

    function handleClose() {
        setShow(false);
        setTimeout(() => { navigate(location.pathname); }, 300);
    };

    return (
        <>
            <Helmet>
                <title>{text('page_registration')}</title>
            </Helmet>
            <Offcanvas show={show} onHide={handleClose} placement={isTablet || isDesktop ? 'end' : 'bottom'}>
                <Offcanvas.Body >
                    <div className="offcanvas-registration-section">
                        {
                            /** Registration banner based on a theme */
                            themes(theme, "registration_banner")
                        }
                        <div className="offcanvas-registration-form-wrapper">
                            <RegistrationFormWithVerify />
                        </div>
                    </div>
                    <OffcanvasBtnClose onClick={handleClose} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}