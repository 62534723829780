import Gleap from "gleap";
import { dbGetActiveWithdrawal, dbUserFromToken } from './api-functions';

export default async function itinializeGleap() { //user
    /*****************************/
    /** Check latest user information */
    let user;

    try {
        const result = await dbUserFromToken();
        user = result?.data?.user;
    } catch (error) {
        console.log(`Error, itinializeGleap, dbUserFromToken, ${error}`);
    }
    
    /*****************************/
    /** Check active withdrawal */
    let hasActiveWithdrawal;

    try {
        const result = await dbGetActiveWithdrawal();

        if (result?.data?.docs?.length > 0) {
            hasActiveWithdrawal = true;
        }
    } catch (error) {
        console.log(`Error, itinializeGleap, dbGetActiveWithdrawal, ${error}`);
    }
    
    /*****************************/
    /** Initialize Gleap */
    if (!Gleap?.getInstance()?.initialized) {
        Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
    }

    /*****************************/
    /** Identify Gleap */
    Gleap.identify(user?.id || '0', {
        name: user?.firstName || '',
        email: user?.email || '',
        phone:  user?.phone || '',
        value: parseFloat(user?.depositsAmount) || 0,
        companyId: user?.withdrawableBalance || 0,
        companyName: hasActiveWithdrawal ? '1' : '0',
        plan: user?.tags || '',

        // Passing the createdAt property allows you to set the correct createdAt date. (optional)
        // createdAt: new Date(),
        customData: {},
    });
    
    /*****************************/
    /** Open Gleap */
    Gleap.open();
}