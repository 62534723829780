import { configureStore } from '@reduxjs/toolkit';

/** Importing reducers from the core library */
import user from "./spinsoft-core-client-subtree/reducers/user";
import global from "./spinsoft-core-client-subtree/reducers/global-content";
import bonuses from "./spinsoft-core-client-subtree/reducers/bonuses";
import promotions from "./spinsoft-core-client-subtree/reducers/promotions";
import gameCategories from "./spinsoft-core-client-subtree/reducers/game-categories";

export default configureStore({
	reducer: {
		user,
		global,
		bonuses,
		promotions,
		gameCategories
	}
});