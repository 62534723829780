import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

/** Utilities */
import useText from "../utilities/use-text";

export default function BonusTimer({ bonus }) {
    const text = useText();
    
    /********************/
    /** Finish date */
    let finish = new Date(bonus.updatedAt);
    finish.setDate(finish.getDate() + 2);

    /********************/
    /** Timer */
    const timer = useRef(null);

    /********************/
    /** Functions */
    function getTimerValue(start) {
        let _second = 1000;
        let _minute = _second * 60;
        let _hour = _minute * 60;
        let _day = _hour * 24;
        let distance = Date.parse(finish) - Date.parse(start);

        let days = Math.floor(distance / _day);
        let seconds = Math.floor((distance / 1000) % 60);
        let minutes = Math.floor((distance / 1000 / 60) % 60);
        let hours = Math.floor((distance / 1000 / 60 / 60) % 24);

        if (days < 0 || seconds < 0 || minutes < 0 || hours < 0) {
            return false;
        }

        return `${leadingZero(days)}:${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`
    }

    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function updateTimerInterval(interval) {
        const value = getTimerValue(new Date());

        if (!value) {
            timer.current.innerHTML = text('bonus_timer_expired');
            clearInterval(interval);
        }
        else {
            timer.current.innerHTML = value;
        }
    }
    /********************/
    /********************/

    useEffect(() => {
        const interval = setInterval(() => { updateTimerInterval(interval) }, 1000);
        return () => { clearInterval(interval); }
    }, []);

    return (
        <span>
            <FontAwesomeIcon icon={faClock} className="me-2" />
            <span ref={timer}></span>
        </span>
    )
}




// function BonusTimer_OLD({ bonus }) {
//     const language = useSelector((state) => state?.global?.data?.language);

//     /********************/
//     /** Timer start and finish dates */
//     let start = new Date();
//     let finish = new Date(bonus.updatedAt);
//     finish.setDate(finish.getDate() + 2);

//     /********************/
//     /** Timer */
//     const [timer, setTimer] = useState('00:00:00:00');

//     /********************/
//     /** Functions */
//     function getTimerValue() {
//         let _second = 1000;
//         let _minute = _second * 60;
//         let _hour = _minute * 60;
//         let _day = _hour * 24;
//         let distance = Date.parse(finish) - Date.parse(start);

//         let days = Math.floor(distance / _day);
//         let seconds = Math.floor((distance / 1000) % 60);
//         let minutes = Math.floor((distance / 1000 / 60) % 60);
//         let hours = Math.floor((distance / 1000 / 60 / 60) % 24);

//         if (days < 0 || seconds < 0 || minutes < 0 || hours < 0) {
//             return false;
//         }

//         return `${leadingZero(days)}:${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`
//     }

//     function leadingZero(num) {
//         num = num.toString();
//         while (num.length < 2) num = "0" + num;
//         return num;
//     }

//     /********************/
//     useEffect(() => {

//         console.log('bonus timer works');

//         const value = getTimerValue();
//         let timeout;

//         if (!value) {
//             setTimer(messages[language]?.timer_expired)
//         }
//         else {
//             timeout = setTimeout(() => {
//                 setTimer(value);
//             }, 1000)
//         }

//         return () => { clearTimeout(timeout); }
//     }, [timer]);

//     return (
//         <span>
//             <FontAwesomeIcon icon={faClock} className="me-1" />
//             <span>{timer}</span>
//         </span>
//     )
// }