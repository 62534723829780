
import React from "react";

export function BackgroundGradientHome() {
    return (
        <div className="background-container-home"></div>
    )
}

export function BackgroundGradientGames() {
    return (
        <div className="background-container-games"></div>
    )
}