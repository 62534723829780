/** Theme components */
/** Default theme */
import HomeBanner from "./components/HomeBanner";
import RegistrationBanner from "./components/RegistrationBanner";
import DepositBanner from "./components/DepositBanner";
import WelcomeModal from "./components/WelcomeModal";

/** Big Bass Amazon */
import HomeBannerTheme from "./components/HomeBannerTheme";

/** Gates of Olympus */
import HomeBannerThemeOlympus from "./components/HomeBannerThemeOlympus";
import RegistrationBannerThemeOlympus from "./components/RegistrationBannerThemeOlympus";
import DepositBannerThemeOlympus from "./components/DepositBannerThemeOlympus";

/**
 * This is a function that returns components per each available theme
 * Not all of the keys have to be present. If the key is not present, the default key is used
 * 
 * @param {string} theme 
 * @param {string} key 
 */

export default function themes(theme, key) {
    try {
        const options = {
            // Default theme
            default: {
                home_banner: <HomeBanner />,
                registration_banner: <RegistrationBanner />,
                deposit_banner: <DepositBanner />,
                welcome_modal: null, // <WelcomeModal />,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/bonus-roll/card-1.png",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/bonus-roll/card-2.png",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/bonus-roll/card-3.png",
                // TO DO:
                home_spotlight_category: "home-spotlight",

            },

            // Big Bass Amazon
            big_bass_amazon: {
                home_banner: <HomeBannerTheme />
            },

            // Gates of Olympus
            olympus: {
                home_banner: <HomeBannerThemeOlympus />,
                registration_banner: <RegistrationBannerThemeOlympus />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_OLYMPUS',
                deposit_banner: <DepositBannerThemeOlympus />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-3.jpg",
            },

            // Add other themes
        }

        if (!key) {
            console.log(`Error, themes, key is not provided for ${theme} theme`);
            return null;
        }

        if (theme && options[theme]) {
            if (options[theme][key]) {
                return options[theme][key]
            }
        }

        return options.default[key];
    } catch (error) {
        console.log(`Error, themes, theme ${theme}, key ${key}: ${error}`);
    }
}

export function getLocationTheme(location) {
    let theme;
    
    /** More important goes in the end */
    /** Theme based on the ipCountryCode */
    if (location?.country_code === 'BR') { }
    if (location?.country_code === 'CA') { }

    /** Theme based on the IP */

    return theme;
}