import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

/**
 * @param {array} errors
 * @param {function} setErrors 
 * @returns 
 */

/**
 * errors = [
 *  {message: "error message", data: [{message: "error message"},{}]},
 *  {},
 * ]
 */

export default function ErrorHandler({ errors, setErrors }) {
    useEffect(() => {
        if (errors?.length > 0) {
            setTimeout(() => {
                setErrors([])
            }, 5000);
        }
    });

    return (
        <>
            {
                errors?.map((error, index) => (
                    <Alert
                        variant="danger"
                        className="error-handler text-center animate__animated animate__faster animate__fadeIn"
                        key={`error-handler-${index}`}
                    >
                        {error.message}
                    </Alert>
                ))
            }
        </>
    )
}