import React, { useRef } from "react";

import GameTile from "./GameTile";
import ScrollButtons from './ScrollButtons';

export default function GameRow({ category }) {
    const placeholder = new Array(10).fill(null);
    const container = useRef(null);

    return (
        <div className="position-relative">
            <div className="game-row-wrapper" ref={container}>
                {
                    category ? 
                        category.games.map((game, index) => 
                            <GameTile game={game} key={`game-row-${category.url}-${game.id}-${index}`} />) :
                        placeholder.map((game, index) => 
                            <GameTile game={game} key={index} />)
                }
            </div>
            <ScrollButtons container={container} />
        </div>
    );
}