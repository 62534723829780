
import React from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export default function QuestionMark({ placement, text, ...props}) {
    return (
        <OverlayTrigger
            placement={placement} // "top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip {...props} className="question-mark-tooltip">{text}</Tooltip>}
        >
            <FontAwesomeIcon icon={faQuestionCircle} className="question-mark-icon" />
        </OverlayTrigger>
    )
}