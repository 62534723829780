import { createSlice } from "@reduxjs/toolkit";
import { dbGetGlobalContent, dbGetIPData, dbGetCountries } from "../utilities/api-functions";
import getSegment from "../utilities/get-segment";
import getDeviceInformation from "../utilities/get-device-information";
import getTheme from "../utilities/get-theme";
import submitError from "../utilities/submit-error";

export const global = createSlice({
    name: "global",
    initialState: {
        isLoading: true,
        data: {
            language: '', // en | es
            location: {
                // ip
                // country
                // country_code
                // region
                // region_code
                // city
                // vpn
            },
            country: {
                // name
                // isDefaultCountry
                // ISOcode
                // currency
            },
            limits: [
                // currency
                // depositMinAmount
                // depositMaxAmount
                // withdrawalMinAmount
                // withdrawalMaxAmount
                // maxBet
            ],
            segment: {
                // params : object
                // depositMinAmount : number
                // id : string
                // countries : string
            },
            theme: {
                // id : string
                // name : string
            },
            device: {
                // isApp
                // isPWA
                // isIframe
                // isWebview
            }
        }
    },
    reducers: {
        updateGlobalContent: (state, action) => {
            Object.assign(state, { ...state, ...action.payload });
        }
    }
});

export const getGlobalContentAsync = (language, user) => async (dispatch) => {
    /*****************************/
    /** Get user IP information */
    let location;

    try {
        let lookup = await dbGetIPData();

        location = {
            ip: lookup?.data?.ip,
            country: lookup?.data?.country,
            country_code: lookup?.data?.country_code,
            region: lookup?.data?.region,
            region_code: lookup?.data?.region_code,
            city: lookup?.data?.city,
            vpn: lookup?.data?.vpn
        }
    } catch (error) {
        submitError('ERROR', `getGlobalContentAsync, dbGetIPData, ${error}`);
    }

    /*************************************/
    /** Get experience country */
    /** Country would come from a logged in user or from IP, or will recieve a default country */
    let country = user?.country;

    if (!country) {
        try {
            let countries = await dbGetCountries();
            country = countries.data.docs.filter(country => country?.ISOcode === location?.country_code?.toLowerCase())[0];

            /** If we do not support this country, you get a default country */
            if (!country) {
                country = countries.data.docs.filter(country => country.isDefaultCountry === true)[0];
            }
        } catch (error) {
            submitError('ERROR', `getGlobalContentAsync, dbGetCountries, ${error}`);
        }
    }

    /*********************************/
    /** Get segment information */
    let segment = await getSegment(user);

    /*********************************/
    /** Get theme information */
    let theme = await getTheme(segment?.params, location);

    /*********************************/
    /** Get device information */
    let device = getDeviceInformation();

    /*********************************/
    /** Get global content */
    try {
        let global = await dbGetGlobalContent();

        /**********************************/
        /** Updating the minimum deposit */
        /** Minimum deposit is updated only for logged in users */
        if (global?.data?.limits?.length > 0 && 
            user?.depositMinAmount > 0 && 
            user.depositsMade === 0 && 
            !location.vpn && 
            segment?.countries?.includes(location?.country))
        {
            for (let limit of global?.data?.limits) {
                limit.depositMinAmount = user?.depositMinAmount;
            }
        }

        // console.log('global');
        // console.log({
        //     location: location,
        //     country: country,
        //     language: language,
        //     limits: global?.data?.limits,
        //     segment: segment,
        //     theme: theme,
        //     device: device
        // })

        dispatch(
            updateGlobalContent({
                isLoading: false,
                data: {
                    location: location,
                    country: country,
                    language: language,
                    limits: global?.data?.limits,
                    segment: segment,
                    theme: theme,
                    device: device
                },
            })
        );
    } catch (error) {
        submitError('ERROR', `getGlobalContentAsync, ${error}`);
    }
};

// Action creators are generated for each case reducer function
export const {
    updateGlobalContent
} = global.actions;

export default global.reducer;