import { createSlice } from "@reduxjs/toolkit";
import { dbGetPromotions } from '../utilities/api-functions';
import submitError from "../utilities/submit-error";

export const promotions = createSlice({
    name: "promotions",
    initialState: {
        isLoading: true,
        list: []
    },
    reducers: {
        updatePromotions: (state, action) => {
            Object.assign(state, {
                ...state, ...{
                    list: action.payload.list.sort((curr, next) => curr.rank - next.rank),
                    isLoading: false
                }
            });
        }
    }
});

export const getPromotionsAsync = (global) => async (dispatch) => {
    try {
        let promotions = await dbGetPromotions(global?.language);
        dispatch(updatePromotions({ list: promotions.data.docs }));
    } catch (error) {
        submitError('ERROR', `getPromotionsAsync, ${error}`);
    }
}

// Action creators are generated for each case reducer function
export const {
    updatePromotions
} = promotions.actions;

export default promotions.reducer;