import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { dbClaimBonusWinnings } from '../utilities/api-functions';

/** Utilities */
import useText from "../utilities/use-text";

export default function ModalClaimBonusWinnings({ show, handleClose, }) {
    const navigate = useNavigate();
    const text = useText();

    const [processing, setProcessing] = useState(true)
    const [error, setError] = useState(false)
    const [maxBetExceeded, setMaxBetExceeded] = useState(false)

    async function claimBonusWinnings() {
        try {
            let result = await dbClaimBonusWinnings();

            setProcessing(false)

            if (result?.data?.success) {
                navigate(0);
                return;
            }

            setMaxBetExceeded(true);

        } catch (error) {
            console.log(`Error, Claim bonus winnings, ${error}`);
            setProcessing(false)
            setError(true)
        }
    }

    useEffect(() => {
        show && processing && claimBonusWinnings()
    }, [show, maxBetExceeded, error])

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                {text('claim_bonus_winnings_title')}
            </Modal.Header>
            <Modal.Body className='text-center'>
                <p>
                    {
                        processing && (
                            <span>{text('claim_bonus_winnings_processing')}</span>
                        )
                    }

                    {
                        maxBetExceeded && (
                            <span>{text('claim_bonus_winnings_exceeded')}</span>
                        )
                    }

                    {
                        error && (
                            <span>{text('claim_bonus_winnings_error')}</span>
                        )
                    }
                </p>
            </Modal.Body>
        </Modal>
    )
}