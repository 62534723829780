
import React, { useEffect, useState, useRef } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { dbGetGameList } from "../utilities/api-functions";

/** Components */
import CategoryTitle from "./CategoryTitle";
import ScrollButtons from './ScrollButtons';
import GameTile from "./GameTile";
import Loading from "./Loading";

export default function LastPlayedGamesRow({ start, limit, title, category }) {
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const list = user?.lastPlayedGames?.split(',').filter(id => id.length > 0);

    const [games, setGames] = useState({
        list: {},
        fetched: false
    })

    const container = useRef(null);

    async function getLastPlayedGames() {
        let result;

        try {
            result = await dbGetGameList(list.slice(start, limit));
            
            if (result.data.docs) {
                let games = {};

                result?.data?.docs.forEach(game => {
                    if (game?.id) {
                        games[game.id] = game;
                    }
                })

                setGames({ list: games, fetched: true })
            }

        } catch (error) {
            console.log(`Error, LastPlayedGamesRow, ${error}`);
            setGames({ list: {}, fetched: true })
        }
    }


    useEffect(() => {
        if (!userLoggedIn) { return; }
        list && getLastPlayedGames()
    }, []);

    return (
        <>
            {
                Object.keys(games.list).length > 0 && games.fetched && userLoggedIn && (
                    <Container fluid="lg" className="game-category-container game-row-container row-container section-margin" >
                        <CategoryTitle title={title} className="title-xs mb-3"/>

                        <div className="position-relative animate__animated animate__faster animate__fadeIn">
                            <div className="game-row-last-played-wrapper" ref={container} >
                                {
                                    list?.map((id, index) => {
                                        let game = games.list[id];

                                        if (game?.isActive) {
                                            return <GameTile
                                                game={game}
                                                isLastPlayed={true}
                                                index={index}
                                                category={category}
                                                key={`last-played-${category}-${index}`}
                                            />
                                        }

                                        return null;
                                    })
                                }
                            </div>
                            <ScrollButtons container={container} />
                        </div>
                    </Container>
                )
            }

            {
                !games.fetched && userLoggedIn && (
                    <Container fluid="lg" style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <Loading />
                    </Container>
                )
            }
        </>
    );
}