
import React from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** Components */
import CategoryTitle from "./CategoryTitle";
import GameRow from "./GameRow";

export default function GameRowCategory({ categoryURL }) {
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories[categoryURL] || null;

    return (
        <Container fluid="lg" className="category-container row-container section-margin" id={categoryURL}>
            <CategoryTitle title={category?.title} className="title-xs mb-3"/>
            <GameRow category={category} />
        </Container>
    );
}