import React, { useRef } from "react";
import { useSelector } from 'react-redux';
import { Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/** Components */
import ScrollButtons from './ScrollButtons';

export default function GameRowDouble({ category }) {
    const placeholder = new Array(10).fill(null);
    const container = useRef(null);

    return (
        <div className="position-relative">
            <div className="game-row-double-wrapper" ref={container}>
                {
                    category ? 
                        category.games.map((game, index) => 
                            <GameTile game={game} key={`game-row-${category.url}-${game.id}-${index}`} />) :
                        placeholder.map((game, index) => 
                            <GameTile game={game} key={index} />)
                }
            </div>
            <ScrollButtons container={container} />
        </div>
    );
}

function GameTile({ game }) {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    if (!game) {
        return (
            <Placeholder as="div" animation="glow">
                <Placeholder xs={12} className="game-tile-horizontal-wrapper-placeholder" />
            </Placeholder>
        )
    }

    return (
        <Link to={userLoggedIn ? `/game/${game.id}` : `?register&game=${game.id}`} 
            className="game-tile-horizontal-wrapper"
        >
            <div className="game-tile-horizontal-image-wrapper">
                <div className="game-tile-horizontal-image-cover" style={{ backgroundImage: `url(${game.image}` }}></div>
                <img className="game-tile-horizontal-image" src={game.image} alt={game.name} />
            </div>
            <div className="game-tile-horizontal-text">
                <div>
                    <p className="game-tile-horizontal-text-title">{game.name}</p>
                    <p className="game-tile-horizontal-text-provider">{game.provider}</p>
                </div>
            </div>
        </Link>
    )
}