import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";

/** Components */
import Loading from "../components/Loading";
import GameListOfRows from "./GameListOfRows";
import GameFilter from "./GameFilter";
import GamesListAll from "./GameListAll";
import GameRowColumnCategory from "./GameRowColumnCategory";

export default function GameScroll({ setSearch }) {
    const { categoryURL } = useParams();

    const location = useLocation();
    const categories = useSelector((state) => state.gameCategories.list || []);

    /** Get filter category when categories are loaded */
    const filter = categories ? categories["filter"] : null;

    /** Set the active category in the filter from the url */
    const [activeCategory, setActiveCategory] = useState(categoryURL);

    /** This is used to allow or block the intersection observer to change the active category */
    const [isScrolling, setIsScrolling] = useState(true);

    function scrollToCategory() {
        let attempts = 0;

        const interval = setInterval(() => {
            /** Stop attempting to scroll to category after 50 attempts */
            if (attempts >= 100) {
                clearInterval(interval);
                setIsScrolling(false);
            }

            /** Get the category */
            const category = document.querySelector(`[id="game-category-${categoryURL}"]`);

            console.log(`[id="game-category-${categoryURL}"]`)
            /** if category exists */
            if (category) {

                /** If this is the first category on the page, scroll to the top of the page */
                const top = category.offsetTop > 200 ? category.offsetTop - 80 : 0;

                /** scroll to element */
                window.scrollTo({
                    top: top,
                    behavior: "smooth",
                });

                /** Remove the interval */
                clearInterval(interval)

                /** Allow change state for subscriber callback */
                setIsScrolling(false);
            }
            else {
                console.log(attempts)
                attempts += 1;
            }
        }, 50);
    }

    function intersectionCallback(entries) {
        const [entry] = entries;

        if (entry.isIntersecting) {
            if (!isScrolling) {
                const categoryName = entry.target.id.split('game-category-anchor-')[1];
                const url = `/games/${categoryName}${location?.search && location?.search}${location?.hash && location?.hash}`;
                window.history.replaceState(null, null, url);
                setActiveCategory(categoryName);
            }
        }
    }

    function subscribeToRefIntersection(observer) {
        const array = document.querySelectorAll('span[id^="game-category-anchor-"]')

        for (const element of array) {
            if (element) observer.observe(element)
        }
    }

    function unsubscribeToRefIntersection(observer) {
        const array = document.querySelectorAll('span[section^="game-category-anchor-"]')

        for (const element of array) {
            if (element) observer.unobserve(element)
        }
    }

    useEffect(() => {
        /** Timeout is based on the timeout that we have for in the Scroll Resoration */
        isScrolling && setTimeout(() => { scrollToCategory(); }, 0);

        const observer = new IntersectionObserver(intersectionCallback, {
            root: null,
            rootMargin: '0px 0px -50% 0px', //"0px",
            threshold: 0.5 //1.0
        })

        subscribeToRefIntersection(observer);

        return () => {
            unsubscribeToRefIntersection(observer);
        }
    }, [isScrolling])

    return (
        <>
            <GameFilter {...{filter, setSearch, activeCategory, setIsScrolling}} />
            {
                filter?.categories?.map((category, index) => {
                    if (!category) {
                        return <div className="games-categories-loading" key={index}><Loading /></div>
                    }

                    if (category?.url.includes('themes')) {
                        return <GameRowColumnCategory categoryURL={category?.url} key={index} />
                    }

                    if (category?.url.includes('live-games')) {
                        return <GameRowColumnCategory categoryURL={category?.url} key={index} />
                    }

                    if (category?.url.includes('game-providers')) {
                        return <GameRowColumnCategory categoryURL={category?.url} key={index} />
                    }

                    if (category?.url.includes('all-games')) {
                        return <GamesListAll key={index} />
                    }

                    if (category?.categories?.length > 0) {
                        return <GameListOfRows categoryURL={category?.url} key={index} />
                    }

                    return null;
                })
            }
        </>
    )
}