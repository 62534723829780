import React from 'react';
import ReactDOM from 'react-dom/client';

/** Google log in */
import { GoogleOAuthProvider } from "@react-oauth/google"

/** App */
import './App.scss';
import App from './spinsoft-core-client-subtree/App';

/** Service Worker */
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/** Web Vitals */
import reportWebVitals from './reportWebVitals';

/** Redux */
import { Provider } from 'react-redux'
import store from './store'

/** React router */
import { BrowserRouter } from 'react-router-dom';

/** Ably */
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';

let client;

try {
	client = Ably.Realtime.Promise({
		authUrl: `${process.env.REACT_APP_PAYLOAD_URL}/ably/authenticate`,
	});
} catch (error) {
	console.log(`Error, Ably Authenticate, ${error}`)
}

/*****************/
/** Root */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AblyProvider client={client}>
		<Provider store={store}>
			<BrowserRouter>
				<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
					{/* <React.StrictMode> */}
					<App />
					{/* </React.StrictMode> */}
				</GoogleOAuthProvider>
			</BrowserRouter>
		</Provider>
	</AblyProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();