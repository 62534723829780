import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Modal, Button, FloatingLabel, Form } from 'react-bootstrap';
import { dbForgotPassword } from "../utilities/api-functions";

/** Utilities */
import useText from "../utilities/use-text";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    // State
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const handleClose = () => {
        navigate(location.pathname);
    };

    useEffect(() => { })

    const forgotPassword = () => {
        dbForgotPassword(email)
            .then((res) => {
                console.log(res)
                if (res.data.message === 'Success') {
                    setSent(true);
                }
            })
            .catch(err => { });
    };

    if (userLoggedIn) {
        return <Navigate to={location.pathname} replace />
    }

    return (
        <Modal show={true} onHide={handleClose}>

            <Modal.Header closeButton className="modal-header">
                <h1 className="offcanvas_header_title">{text('forgot_password_title')}</h1>
            </Modal.Header>

            <Modal.Body>
                {
                    !sent ? (
                        <>
                            <p>{text('forgot_password_field_email')}</p>
                            <FloatingLabel controlId="floatingInput" label="Email" className="mb-2">
                                <Form.Control type="email" placeholder="email@example.com" value={email} onChange={e => setEmail(e.target.value)} />
                            </FloatingLabel>
                        </>
                    ) : (
                        <>
                            <p>
                                {text('forgot_password_text_1')}
                            </p>
                            <p>
                                {text('forgot_password_text_2')}
                            </p>
                        </>
                    )
                }
            </Modal.Body>

            {
                !sent && (
                    <Modal.Footer>
                        <Button className="btn-secondary" onClick={handleClose}>
                            {text('forgot_password_btn_close')}
                        </Button>
                        <Button className="btn-main" onClick={forgotPassword}>
                            {text('forgot_password_btn_submit')}
                        </Button>
                    </Modal.Footer>
                )
            }
        </Modal>
    );
}