import React, { useEffect, useRef } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../utilities/use-text";

export default function GameSearchField({ search, setSearch }) {
    const location = useLocation();
    const text = useText();

    const searchInput = useRef(null);

    useEffect(() => {
        if (location.search.includes('search') && !location.search.includes('search=')) {
            searchInput.current.click();
            searchInput.current.focus();
        }
    });

    return (
        <div className="games-search-field-wrapper">
            <InputGroup className="games-search-field" >
                <FormControl
                    id="gameSearchInput"
                    type="search"
                    placeholder={text('game_search_input_placeholder')}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    ref={searchInput}
                    enterKeyHint={text('game_search_input_placeholder')}
                />
                {
                    search.length === 0 && (
                        <FontAwesomeIcon icon={faSearch} className="games-search-field-icon" />
                    )
                }
            </InputGroup>
        </div>

    )
}