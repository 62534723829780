import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import QuestionMark from "../spinsoft-core-client-subtree/components/QuestionMark";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function DepositBanner() {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    return (
        <>
            {
                user?.depositsMade === 0 && (
                    <>
                        <img className="deposit_banner_background animate__animated animate__fadeIn" src="https://spt-assets.b-cdn.net/orozino/themes/default/deposit_banner_background.jpg" />
                        <img className="deposit_banner_image animate__animated animate__fadeInLeft" src="https://spt-assets.b-cdn.net/orozino/themes/default/deposit_banner_character.png" />
                        {/* <img className="deposit_banner_arrow animate__animated animate__fadeIn animate__delay-1s" src="https://spt-assets.b-cdn.net/orozino/themes/default/registration_banner_arrow.png" /> */}
                        <div className="deposit_banner_container animate__animated animate__fadeIn animate__delay-1s">
                            <div className="deposit_banner_content">
                                <p className="deposit_banner-timer-wrapper animate__animated animate__fadeIn animate__delay-1s">
                                    <LotteryDropTimer />
                                </p>
                                <div className="deposit_banner_content_background">
                                    <p className="deposit_banner_content_subtitle">{text('deposit_banner_title_lottery')}</p>
                                    <p className="deposit_banner_content_title">$100,000</p>
                                </div>
                            </div>
                        </div>
                    </>
                    // <div className="deposit_banner_container">
                    //     <img src="https://spt-assets.b-cdn.net/orozino/assets/lottery-cat.png" />
                    //     <div className="deposit_banner_content">
                    //         <p className="deposit_banner_content_title font-full">{text('deposit_banner_title_lottery')}</p>
                    //         <p className="deposit_banner_content_money mb-0 font-full">$100,000</p>
                    //         <p className="deposit_banner-timer-wrapper animate__animated animate__fadeIn animate__delay-1s">
                    //             <LotteryDropTimer />
                    //         </p>
                    //         <div className="deposit_banner-question-mark-wrapper">
                    //             <QuestionMark placement="bottom" text={text('question_mark_deposit_lottery')} />
                    //         </div>
                    //     </div>
                    // </div>
                )
            }
        </>
    )
}

function LotteryDropTimer() {
    const [timer, setTimer] = useState('');

    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function getTime() {
        const today = new Date();
        let hours = today.getHours();
        let mimuntes = today.getMinutes();
        let seconds = today.getSeconds();
        let secondsFromStartOfDate = (hours * 60 * 60) + (mimuntes * 60) + seconds;
        let secondsUntilEndOfDate = (24 * 60 * 60) - secondsFromStartOfDate;

        let tempHours = Math.floor((secondsUntilEndOfDate / 60 / 60) % 24);
        let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
        let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

        return `${leadingZero(tempHours)}:${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`;
    }

    useEffect(() => {
        const time = getTime();
        !timer && setTimer(time);

        const timeout = setTimeout(() => {
            const time = getTime();
            setTimer(time);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }

    }, [timer])

    return (
        <span>
            <FontAwesomeIcon icon={faClock} className="me-2" />
            <span>{timer}</span>
        </span>
    )
}