import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';

/** Lootie */
import logo from '../../assets/lottie/logo.json';

export default function Loading() {
    return (
        <Player autoplay loop
            src={logo}
            style={{ height: '80px', width: '200px' }}
        />
    );
}