import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export default function OffcanvasBtnClose({ onClick }) {
    const isPWA = useSelector((state) => state?.global?.data?.device?.isPWA);

    return (
        <div className={`close_offcanvas_button_container ${isPWA && 'close_offcanvas_button_container-app'}`}>
            <Button onClick={onClick} className="close_offcanvas_button" >
                <FontAwesomeIcon icon={faClose} className="close_offcanvas_button_icon" />
            </Button>
        </div>
    )
}