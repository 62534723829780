import { useState, useEffect, useCallback } from "react";
import { dbGetAllGames, dbSearchGames } from "./api-functions";

export function useFetchAllGames(page) {
	const [games, setGames] = useState([]);

	const sendQuery = useCallback(async () => {
		// console.log('sendQuery useFetchAllGames')
		// console.log(page)
		try {
			// setLoading(true); // await setLoading(true);
			// setError(false); // await setError(false);
			const res = await dbGetAllGames(page);
			// setLoading(false);
			setGames((prev) => [...prev, ...res.data.docs]);
		} catch (error) {
			// setError(error);
		}
	}, [page]);

	useEffect(() => {
		sendQuery();
	}, [page]); //sendQuery, 

	return { games }; //loading, error,
}

export function useFetchSearchGames(search, trigger) {
	const [page, setPage] = useState(1);
	const [games, setGames] = useState([]);
    const [currentSearch, setCurrentSearch] = useState('');
	
	const sendQuery = useCallback(async () => {
		try {
			/** If search is equal to current search, that means intersection worked */
			/** We ignore the first search intersection, because it is always a duplicate */
			//if (trigger === 1) return;

			const currentPage = currentSearch === search ? page + 1 : 1;

			const res = await dbSearchGames(search, currentPage);
			currentPage > 1 ? setGames((prev) => [...prev, ...res.data.docs]) : setGames(res.data.docs);
			
			setPage(currentPage);
			setCurrentSearch(search);
		} catch (error) {
			// setError(error);
		}
	}, [search, trigger]);

	useEffect(() => {
		sendQuery();
	}, [search, trigger]); //sendQuery, 

	return { games }; //loading, error,

}




// function useFetch(query, page) {
// 	const [loading, setLoading] = useState(true);
// 	const [error, setError] = useState(false);
// 	const [list, setList] = useState([]);

// 	const sendQuery = useCallback(async () => {
// 		try {
// 			setLoading(true); // await setLoading(true);
// 			setError(false); // await setError(false);

// 			/** Call the games */
// 			const res = await axios.get('url');
// 			setList((prev) => [...prev, ...res.data]); // await setList((prev) => [...prev, ...res.data];
// 			setLoading(false);
// 		} catch (err) {
// 			setError(err);
// 		}
// 	}, [query, page]);

// 	useEffect(() => {
// 		sendQuery(query);
// 	}, [query, sendQuery, page]);

// 	return { loading, error, list };
// }

// export default useFetch;