import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faCreditCard, faSearch, faUser, faHome, faRightToBracket } from '@fortawesome/free-solid-svg-icons';

import slots from '../assets/images/slots.svg'

export function IconHome() {
    return <FontAwesomeIcon icon={faHome} />
}

export function IconGames() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="svg-inline--fa fa-house" role="img" viewBox="-80 330 576 512" fillRule="evenodd">
            <g>
                <path style={{stroke:"none", fill:"currentcolor"}} d="M 421 421.89C 300.679 421.89 180.321 421.89 60 421.89C 61.821 387.431 81.879 367.032 112.5 361.39C 133.831 361.223 155.169 361.056 176.5 360.89C 211.997 360.89 247.504 360.89 283 360.89C 319.974 360.888 370.525 355.364 393.5 370.39C 408.444 380.163 420.936 397.44 421 421.89z" />
                <path style={{stroke:"none", fill:"currentcolor"}} d="M 421 751.89C 421 761.555 421 771.224 421 780.89C 451.991 781.088 507.728 787.228 520 768.89C 527.016 758.406 525.002 737.298 525 719.89C 525 683.06 525 646.219 525 609.39C 525 593.058 525 576.721 525 560.39C 522.334 557.39 519.666 554.389 517 551.39C 508.327 537.969 508.843 515.331 517 501.89C 540.23 463.612 613.342 483.101 600 536.39C 597.288 547.221 591.76 553.442 586 560.89C 586 608.052 586 655.228 586 702.39C 586.001 727.564 588.596 757.858 582.5 777.89C 572.9 809.438 548.95 832.159 515.5 839.89C 488.644 846.097 441.768 841.89 410.5 841.89C 340.507 841.89 270.493 841.89 200.5 841.89C 158.597 841.891 106.833 847.223 83 828.89C 62.4169 813.056 59.7988 789.265 60 751.89C 28.7817 750.225 9.5786 732.213 2 706.89C -1.4516 695.357 -0.0007 678.693 0 664.39C 0 636.059 0 607.72 0 579.39C -0.0008 544.872 -4.2751 501.146 9 479.39C 18.9029 463.16 34.7394 454.568 57.5 450.89C 157.157 450.89 256.843 450.89 356.5 450.89C 410.018 450.887 449.685 445.38 471.5 478.39C 485.428 499.465 481 543.381 481 577.89C 481 603.221 481 628.559 481 653.89C 481.001 669.648 483.075 688.852 480 702.89C 473.847 730.98 453.413 750.006 421 751.89z" />
                <path style={{stroke:"none", fill:"#FFF"}} d="M 109.5 570.89C 108.823 578.946 103.359 586.279 100.5 592.89C 93.0008 610.388 85.4993 627.891 78 645.39C 71.3917 662.685 84.1081 673.771 98 675.89C 115.294 678.528 119.849 658.254 124.5 647.39C 135.832 621.226 147.168 595.054 158.5 568.89C 163.436 556.401 160.751 541.314 152 534.39C 148.209 531.39 142.878 528.61 137.5 527.39C 126.64 524.926 74.2143 525.642 66.5 528.89C 61.7728 530.88 57.4578 535.635 55.5 540.39C 53.3083 545.713 53.6748 550.963 55.5 555.89C 62.2467 574.1 84.6523 571.332 109.5 570.89z" />
                <path style={{stroke:"none", fill:"#FFF"}} d="M 244.5 570.89C 243.823 578.946 238.359 586.279 235.5 592.89C 228.001 610.388 220.499 627.891 213 645.39C 206.392 662.685 219.108 673.771 233 675.89C 250.294 678.528 254.849 658.254 259.5 647.39C 270.832 621.226 282.168 595.054 293.5 568.89C 298.436 556.401 295.751 541.314 287 534.39C 283.209 531.39 277.878 528.61 272.5 527.39C 261.64 524.926 209.214 525.642 201.5 528.89C 196.773 530.88 192.458 535.635 190.5 540.39C 188.308 545.713 188.675 550.963 190.5 555.89C 197.247 574.1 219.652 571.332 244.5 570.89z" />
                <path style={{stroke:"none", fill:"#FFF"}} d="M 379.5 570.89C 378.823 578.946 373.359 586.279 370.5 592.89C 363.001 610.388 355.499 627.891 348 645.39C 341.392 662.685 354.108 673.771 368 675.89C 385.294 678.528 389.849 658.254 394.5 647.39C 405.832 621.226 417.168 595.054 428.5 568.89C 433.436 556.401 430.751 541.314 422 534.39C 418.209 531.39 412.878 528.61 407.5 527.39C 396.64 524.926 344.214 525.642 336.5 528.89C 331.773 530.88 327.458 535.635 325.5 540.39C 323.308 545.713 323.675 550.963 325.5 555.89C 332.247 574.1 354.652 571.332 379.5 570.89z" />
            </g>
        </svg>
    )

    // return <FontAwesomeIcon icon={faGamepad} />
}

export function IconCashier() {
    return <FontAwesomeIcon icon={faCreditCard} />
}

export function IconSearch() {
    return <FontAwesomeIcon icon={faSearch} />
}

export function IconProfile() {
    return <FontAwesomeIcon icon={faUser} />
}

export function IconLogIn() {
    return <FontAwesomeIcon icon={faRightToBracket} />
}