import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { Form, FloatingLabel } from "react-bootstrap";

/** Components */
//import WithdrawMethod from "./WithdrawMethod";
import WithdrawRedirect from "./WithdrawRedirect";
import DepositButtonBack from "./DepositButtonBack";
import CashierScrollTop from "./CashierScrollTop";
import WithdrawAllowed from "./WithdrawalAllowed";
import QuestionMark from './QuestionMark';

/** Brand Components */
import WithdrawMethod from "../../components/WithdrawMethod";

/** Utilities */
import { roundNumber, bonusBalance, wagerPercentCalculate } from '../utilities/balance';
import useText from "../utilities/use-text";

export default function Withdraw() {
    const user = useSelector((state) => state.user.data)
    const text = useText();

    const globalLimits = useSelector((state) => state.global.data.limits);
    const limits = globalLimits.filter(item => {
        return item.currency === user?.currency?.id;
    })[0] || null;

    const step1 = useRef(null);
    const step2 = useRef(null);
    const container = useRef(null);

    const [step, setStep] = useState(1);
    const [amount, setAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isWithdrawAllowed, setIsWithdrawAllowed] = useState(true);

    function getWagerProgress(percent) {
        if (!percent || percent <= 0) { return 0 };
        if (percent >= 100) { return 100 };

        /** Round percent to 2 decimal places */
        percent = Math.round((percent + Number.EPSILON) * 100) / 100;

        /** Define a speed increase parameter */
        let addition = 40;

        /** Calculate initial speed increase percentage */
        if (percent <= addition) {
            addition = percent + (addition / 100);
        }

        /** Calculate button bar filling */
        let fillPercent = addition + percent * 0.6;

        /** Return bar filling rounded to 2 decimal places */
        return Math.round((fillPercent + Number.EPSILON) * 100) / 100;
    }

    function choosePaymentMethod(method) {
        if (user.withdrawableBalance < limits.withdrawalMinAmount ||
            amount < limits.withdrawalMinAmount ||
            amount > user.withdrawableBalance ||
            amount > limits.withdrawalMaxAmount) {
            return false;
        }

        setPaymentMethod(method)
        transitionToStep(2)
    }

    function transitionToStep(nextStep) {
        if (!isWithdrawAllowed) return;

        if (nextStep === 2) {
            step1.current.classList.add("animate__fadeOutLeft");
            setTimeout(() => {
                setStep(nextStep);
                step2.current.classList.remove("animate__fadeOutRight");
                step2.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (nextStep === 1) {
            step2.current.classList.add("animate__fadeOutRight");
            setTimeout(() => {
                setStep(nextStep);
                step1.current.classList.remove("animate__fadeOutLeft");
                step1.current.classList.add("animate__fadeIn");
            }, 300);
        }
    }

    return (
        <div className="withdraw-container" ref={container}>
            <CashierScrollTop {...{ step, container }} />
            <div className="animate__animated animate__faster" ref={step1}>
                <WithdrawAllowed {...{ setIsWithdrawAllowed, step }} />
                {
                    step === 1 && (
                        <section className="withdraw-section">
                            <div className="withdraw-balance">
                                <h2 className="subtitle font-mid mb-2">{text('withdraw_balance')}</h2>
                                <h3 className="title-sm font-full mb-0">
                                    {user.currency.sign}
                                    {Number.parseFloat(user.withdrawableBalance)}
                                </h3>
                            </div>

                            {
                                user?.bonus[0] && (
                                    <div className="withdraw-bonus">
                                        <div className="withdraw-bonus-item">
                                            <span className="font-mid withdraw-bonus-item-label">{text('withdraw_bonus_balance')}</span>
                                            <span className="subtitle font-full">{user?.currency?.sign}{roundNumber(bonusBalance(user) || 0)}</span>
                                        </div>
                                        <div className="withdraw-bonus-item mb-0">
                                            <span className="font-mid withdraw-bonus-item-label">{text('withdraw_wager_progress')}</span>
                                            <span className="subtitle font-full">{`${getWagerProgress(wagerPercentCalculate(user))}%`}</span>
                                        </div>
                                        <div className="withdraw-bonus-question-mark-container">
                                            <QuestionMark placement="left" text={text('question_mark_bonus_balance')} />
                                        </div>
                                    </div>
                                )
                            }

                            <div className="withraw-amount">
                                <h2 className="subtitle font-mid mb-2">{text('withdraw_choose_amount')}</h2>
                                <Form.Group controlId="amount" className="mb-2">
                                    <FloatingLabel label={text('withdraw_choose_amount_label', user?.currency?.sign)} >
                                        <Form.Control
                                            type="number"
                                            className="amount-input"
                                            value={amount}
                                            onChange={(e) => { setAmount(e.target.value) }}
                                            isValid={amount >= limits.withdrawalMinAmount && amount <= user.withdrawableBalance && amount <= limits.withdrawalMaxAmount}
                                            isInvalid={parseInt(amount) !== 0 && (amount < limits.withdrawalMinAmount || amount > user.withdrawableBalance || amount > limits.withdrawalMaxAmount)}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {/* Zero balance: You don't have any money to withdraw */}
                                            {
                                                parseInt(amount) !== 0 && user?.withdrawableBalance === 0 && (
                                                    <>{text('withdraw_error_insufficient_funds')}</>
                                                )
                                            }

                                            {/* Some balance: Minimum withdrawal amount is N */}
                                            {
                                                parseInt(amount) !== 0 && user?.withdrawableBalance > 0 && user?.withdrawableBalance < limits.withdrawalMinAmount && (
                                                    <>{text('withdraw_error_wrong_amount_limit_min', { sign: user?.currency?.sign, minAmount: limits?.withdrawalMinAmount })}</>
                                                )
                                            }

                                            {/* Enough balance: Min and max withdrawal */}
                                            {
                                                parseInt(amount) !== 0 && user?.withdrawableBalance >= limits.withdrawalMinAmount && (amount < limits.withdrawalMinAmount || amount > user.withdrawableBalance || amount > limits.withdrawalMaxAmount) && (
                                                    <>{text('withdraw_error_wrong_amount_limit_min_max', { sign: user?.currency?.sign, minAmount: limits?.withdrawalMinAmount, maxAmount: limits.withdrawalMaxAmount })}</>
                                                )
                                            }
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </div>


                            <WithdrawMethod {...{ choosePaymentMethod, isEnabled: amount >= limits.withdrawalMinAmount && amount <= user.withdrawableBalance && amount <= limits.withdrawalMaxAmount && isWithdrawAllowed }} />
                        </section>)
                }
            </div>

            <div ref={step2} className="animate__animated animate__faster">
                {
                    step === 2 && (
                        <section className="withdraw-section">
                            <WithdrawRedirect {...{ amount, paymentMethod }} />
                            <DepositButtonBack toPreviousStep={() => { transitionToStep(1) }} copy={text('withdraw_btn_back_to_payment_options')} />
                        </section>
                    )
                }
            </div>
        </div>
    )
}