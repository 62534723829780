import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav, Offcanvas, Button, ButtonGroup, ToggleButton, ProgressBar, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { dbLogout } from '../utilities/api-functions';
import { googleLogout } from '@react-oauth/google';
import { Helmet } from "react-helmet-async";
import { isTablet, isDesktop } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faHeadset, faQuestion, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ClaimWinningsAllowed from '../components/ClaimWinningsAllowed';

/** Components */
import OffcanvasBtnClose from '../components/OffcanvasBtnClose';
import ProfilePlayerInformation from '../components/ProfilePlayerInformation';
// import ModalCancelBonus from '../components/ModalCancelBonus';
import ModalCloseAccount from '../components/ModalCloseAccount';
import ModalClaimBonusWinnings from '../components/ModalClaimBonusWinnings';
import QuestionMark from '../components/QuestionMark';


/** Utilities */
import { roundNumber, totalBalance, bonusBalance, wagerPercentCalculate } from '../utilities/balance';
import itinializeGleap from '../utilities/itinialize-gleap';
import useText from "../utilities/use-text";

/** Meta */
import metadata from "../../metadata";

export default function Profile() {
    const navigate = useNavigate();
    const location = useLocation();
    const language = useSelector((state) => state?.global?.data?.language);
    const text = useText();
    const user = useSelector((state) => state.user.data)

    const [show, setShow] = useState(true);
    const [isClaimWinningsAllowed, setIsClaimWinningsAllowed] = useState(false);
    const [showCloseAccount, setShowCloseAccount] = useState(false);
    const [showClaimWinnings, setShowClaimWinnings] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);


    function handleClose() {
        setShow(false);
        setTimeout(() => { navigate(location.pathname); }, 300);
    };

    async function logout() {
        setIsLoggingOut(true);

        try {
            await dbLogout();
            googleLogout();
            navigate(0)
        } catch (error) {
            console.log(`Error, logout, ${error}`)
            setIsLoggingOut(false);
        }
    };

    function getWagerProgress(percent) {
        if (!percent || percent <= 0) { return 0 };
        if (percent >= 100) { return 100 };

        /** Round percent to 2 decimal places */
        percent = Math.round((percent + Number.EPSILON) * 100) / 100;

        /** Define a speed increase parameter */
        let addition = 40;

        /** Calculate initial speed increase percentage */
        if (percent <= addition) {
            addition = percent + (addition / 100);
        }

        /** Calculate button bar filling */
        let fillPercent = addition + percent * 0.6;

        /** Return bar filling rounded to 2 decimal places */
        return Math.round((fillPercent + Number.EPSILON) * 100) / 100;
    }

    function getProfileHeader() {
        let name = text('profile_title');

        if (user?.firstName && user?.lastName) {
            name = `${user?.firstName} ${user?.lastName}`
        }

        if (user?.firstName && !user?.lastName) {
            name = `${user?.firstName}`
        }

        return name;
    }

    return (
        <>
            <Helmet>
                <title>{text('page_profile')}</title>
            </Helmet>
            <Offcanvas show={show} onHide={handleClose} placement={isTablet || isDesktop ? 'end' : 'bottom'}>
                <Offcanvas.Body>
                    <div className='profile-container'>
                        <div className="profile-balance">
                            <div className="profile-balance-main">
                                <div className="profile-main">
                                    <h2 className='title mb-0 font-mid'>{getProfileHeader()}</h2>
                                    <p className='mb-0 profile-email'>{user?.email || ''}</p>
                                </div>
                                <div className="profile-balance-item">
                                    <span className="subtitle-xs font-mid">{text('profile_balance')}&nbsp;</span>
                                    <span className="subtitle-xs font-mid profile-balance-item-value">{user?.currency?.sign}{totalBalance(user) || 0}</span>
                                </div>
                                <div className="profile-balance-item mb-0">
                                    <span className="subtitle-xs font-mid">{text('profile_balance_withdrawable')}&nbsp;</span>
                                    <span className="subtitle-xs font-mid profile-balance-item-value">{user?.currency?.sign}{roundNumber(user?.withdrawableBalance || 0)}</span>
                                </div>
                            </div>

                            {
                                user.bonus[0] && (
                                    <div className="profile-bonus">
                                        <ClaimWinningsAllowed {...{ setIsClaimWinningsAllowed }} />

                                        <div className="profile-bonus-item">
                                            <div>
                                                <span className="subtitle-xs font-mid">{text('profile_balance_bonus')}&nbsp;</span>
                                                <QuestionMark placement="bottom" text={text('question_mark_bonus_balance')} />
                                            </div>

                                            <span className="subtitle-xs font-mid profile-balance-item-value">{user?.currency?.sign}{roundNumber(bonusBalance(user) || 0)}</span>
                                        </div>

                                        {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}

                                        <div className="profile-bonus-wager">
                                            <p className="font-mid profile-bonus-wager-title subtitle-xs">{text('profile_wager_progress')}</p>
                                            <div className="profile-bonus-wager-bar-container">
                                                <ProgressBar className="profile-bonus-wager-bar" animated now={getWagerProgress(wagerPercentCalculate(user))} />
                                                <p className="font-mid profile-bonus-wager-value">{`${getWagerProgress(wagerPercentCalculate(user))}%`}</p>
                                            </div>
                                        </div>

                                        {/* {
                                            isClaimWinningsAllowed && user?.bonus[0]?.betsMade >= user?.bonus[0]?.betsTarget && user?.bonus[0]?.betsTarget !== 0 && (
                                                <Button className="btn-main btn-cancel-bonus mt-1 mb-1 animate__animated animate__faster animate__fadeIn"
                                                    onClick={() => { setShowClaimWinnings(true) }}
                                                >
                                                    {text('profile_btn_claim_winnings')}
                                                </Button>
                                            )
                                        } */}

                                        <p className="profile-bonus-expiry-date">{text('profile_bonus_promocode')} {user.bonus[0]?.promoCode}</p>
                                    </div>
                                )
                            }
                        </div>

                        <div className="profile-support">
                            <Button className="btn-main-lg profile-support-button" onClick={() => { itinializeGleap(user) }}>
                                <FontAwesomeIcon icon={faHeadset} />&nbsp;
                                {text('profile_btn_support')}
                            </Button>
                        </div>

                        <ProfilePlayerInformation />

                        <div className="profile-close-account">
                            <p className='font-low'>{text('profile_close_account')}</p>
                            <Button className="btn-alert profile-close-account-button" onClick={() => { setShowCloseAccount(true) }}>
                                {text('profile_btn_close_account')}
                            </Button>
                        </div>

                        <div className="profile-logout">
                            <Button className="btn-danger-lg profile-logout-button" onClick={logout}>
                                {
                                    isLoggingOut && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                }
                                {text('profile_btn_log_out')}
                            </Button>
                        </div>

                        <div className="profile-bottom-space"></div>
                    </div>

                    <OffcanvasBtnClose onClick={handleClose} />
                </Offcanvas.Body>
            </Offcanvas>
            {/* <ModalCancelBonus show={showCancelBonus} handleClose={() => { setShowCancelBonus(false) }} /> */}
            <ModalClaimBonusWinnings show={showClaimWinnings} handleClose={() => { setShowClaimWinnings(false) }} />
            <ModalCloseAccount show={showCloseAccount} handleClose={() => { setShowCloseAccount(false) }} />
        </>
    )
}