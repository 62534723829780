
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../utilities/use-text";

export default function WithdrawSummary({ sign, amount }) {
    const text = useText();

    return (
        <div className="deposit-summary">
            <div>
                <p className="mb-0 font-mid">
                    {text('withdrawal_summary_amount')}&nbsp;
                    <span className="font-full">{sign}{amount}</span>
                </p>
            </div>
            <div>
                <FontAwesomeIcon icon={faCheckCircle} className="deposit-summary-icon" />
            </div>
        </div>
    )
}