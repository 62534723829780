import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Form, FloatingLabel} from 'react-bootstrap';
import { dbUpdateUserInfo } from '../utilities/api-functions';

/** Utilities */
import useText from "../utilities/use-text";

export default function ProfilePlayerInformation() {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    const [email, setEmail] = useState(user?.email || '');
    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');
    const [dateOfBirth, setDateOfBirth] = useState(user?.dob || '');
    const [street, setStreet] = useState(user?.street || '');
    const [city, setCity] = useState(user?.city || '');
    const [state, setState] = useState(user?.state || '');
    const [zip, setZip] = useState(user?.zip || '');
    const [phone, setPhone] = useState(user?.phone || '');

    async function updateUserInfo() {
        let data = {};

        if (firstName?.length > 0) {
            data.firstName = firstName;
        }

        if (lastName?.length > 0) {
            data.lastName = lastName;
        }

        if (street?.length > 0) {
            data.street = street;
        }

        if (city?.length > 0) {
            data.city = city;
        }

        if (state?.length > 0) {
            data.state = state;
        }

        if (zip?.length > 0) {
            data.zip = zip;
        }

        if (dateOfBirth) {
            data.dob = dateOfBirth?.toString();
        }

        if (!user?.phoneVerified) {
            data.phone = phone;
        }

        try {
            await dbUpdateUserInfo(data);
        } catch (error) {
            console.log(`Error, updateUserInfo, ${error}`)
        }
    }

    return (
        <div className="profile-information">
            <div className="profile-information-title-wrapper">
                <h2 className="title-xs profile-information-title">{text('profile_information_title')}</h2>
            </div>

            <Form noValidate>
                <Form.Group className="mb-3">
                    <FloatingLabel label={text('profile_information_field_first_name')} >
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)} 
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                    <FloatingLabel label={text('profile_information_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)} 
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <FloatingLabel label={text('profile_information_field_email')} className="mb-3">
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onBlur={updateUserInfo}
                        />
                </FloatingLabel>

                <Form.Group className="mb-3">
                    <FloatingLabel label={text('profile_information_field_address')} >
                        <Form.Control
                            type="text"
                            value={street}
                            onChange={e => setStreet(e.target.value)} 
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                    <FloatingLabel label={text('profile_information_field_city')} >
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={e => setCity(e.target.value)} 
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                    <FloatingLabel label={text('profile_information_field_state')} >
                        <Form.Control
                            type="text"
                            value={state}
                            onChange={e => setState(e.target.value)}
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="postalCode" className="mb-3">
                    <FloatingLabel label={text('profile_information_field_zip')}>
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={e => setZip(e.target.value)} 
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                    <FloatingLabel label={text('profile_information_field_dob')} >
                        <Form.Control
                            type="date"
                            value={dateOfBirth}
                            onChange={e => setDateOfBirth(e.target.value)}
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="postalCode" className="mb-4">
                    <FloatingLabel label={text('profile_information_field_phone')}>
                        <Form.Control
                            type="text"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={user?.phoneVerified}
                            onBlur={updateUserInfo}
                        />
                    </FloatingLabel>
                </Form.Group>
            </Form>
        </div>
    )
}