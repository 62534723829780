import React, { useEffect, useRef } from "react";
import { Container, Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ScrollButtons from './ScrollButtons';
import CategoryTitle from "./CategoryTitle";

import GameTileTags from "./GameTileTags";

export default function GameRowColumnCategory({ categoryURL }) {
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? categories[categoryURL] : null;

    return (
        <section id={`game-category-${categoryURL}`} className="game-category-container section-margin">
            <span id={`game-category-anchor-${categoryURL}`}></span>
            <Container fluid="lg" className="game-row-container row-container">
                <CategoryTitle title={category?.title} className="title-xs mb-3" />
                <GameRowColumn category={category} />
            </Container>
        </section>
    );
}

function GameRowColumn({ category }) {
    const placeholder = new Array(4).fill(null);
    const container = useRef(null);

    return (
        <div className="position-relative">
            <div className="games-row-columns-wrapper" ref={container}>
                {
                    !category ?
                        placeholder.map((item, index) =>
                            <GameColumn 
                                categoryURL={item} 
                                key={`game-column-placeholder-${index}`} />
                        ) :
                        category?.categories.map((item, index) =>
                            <GameColumn
                                categoryURL={item.url}
                                key={`game-column-${item.id}-${index}`}
                            />
                        )
                }
            </div>
            <ScrollButtons container={container} />
        </div>
    )
}

function GameColumn({ categoryURL }) {
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? categories[categoryURL] : null;
    const placeholder = new Array(5).fill(null);

    return (
        <div className="game-column">
            <div className="game-column-top-row">
                <h3 className="subtitle white-87">{category?.title}</h3>
            </div>
            {
                !category ?
                    placeholder.map((game, index) =>
                        <GameTile game={game} key={`game-column-tile-placeholder-${index}`} />
                    ) :
                    category.games.map((game, index) =>
                        <GameTile game={game} key={`game-column-tile-${game.id}-${index}`} />
                    )
            }
        </div>
    )
}

function GameTile({ game }) {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    if (!game) {
        return (
            <Placeholder as="div" animation="glow">
                <Placeholder xs={12} className="game-column-wrapper-placeholder" />
            </Placeholder>
        )
    }

    return (
        <Link to={userLoggedIn ? `/game/${game.id}` : `?register&game=${game.id}`} 
            className="game-column-wrapper"
        >
            <div className="game-column-image-wrapper">
                <div className="game-column-image-cover" style={{ backgroundImage: `url(${game.image}` }}></div>
                <img className="game-column-image" src={game.image} alt={game.name} />
            </div>
            <div className="game-column-text">
                <div>
                    <p className="game-column-text-title">{game.name}</p>
                    <p className="game-column-text-provider">{game.provider}</p>
                </div>
            </div>

            <GameTileTags tags={game.tags} isRightTag={true} />
        </Link>
    )
}