import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigationType } from "react-router-dom";
import { Helmet } from "react-helmet-async";

/** Components */
import GameSearch from "../components/GameSearch"
import GameScroll from "../components/GameScroll"
import GameSearchField from "../components/GameSearchField"

/** Utilities */
import useText from "../utilities/use-text";

export default function Games() {
    const navType = useNavigationType();
    const location = useLocation();
    const text = useText();
    
    /** Set search from the URL on first load */
    const [search, setSearch] = useState(location?.search?.split('search=')[1]?.split('&')[0] || '');

    useEffect(() => {
        navType === 'POP' && location?.search === '' && setSearch('');
    }, [navType])

    return (
        <>
            <Helmet>
                <title>{text('page_games')}</title>
            </Helmet>
            <div className="position-relative">
                <GameSearchField search={search} setSearch={setSearch} />
                {
                    search.length > 0 ? (
                        <GameSearch search={search} />
                    ) : (
                        <GameScroll setSearch={setSearch} />
                    )
                }
            </div>
        </>
    )
}