import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dbOpenAccount } from '../utilities/api-functions';
import { Button, Modal } from 'react-bootstrap';

/** Utilities */
import useText from "../utilities/use-text";

export default function ModalOpenAccount() {
    const text = useText();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.data)
    
    async function openAccount() {
        try {
            await dbOpenAccount();
            navigate(0);
        } catch (error) {
            console.log(`Error, Open account, ${error}`)
        }
    }

    return (
        <>
            {
                user?.isAccountClosed && (
                    <Modal show={true} centered>
                        <Modal.Header >
                            {text('open_account_title')}
                        </Modal.Header>
                        <Modal.Body className='text-center'>
                            <p>
                                {text('open_account_text')}
                            </p>
                            <Button className="btn-main" onClick={openAccount}>
                                {text('open_account_cta')}
                            </Button>
                        </Modal.Body>
                    </Modal>
                )
            }
        </>

    )
}