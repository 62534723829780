import React from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Form } from 'react-bootstrap';

import imageAgeLimit from "../assets/images/age-limit.png"
import License from "./License";

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function Footer() {
    const text = useText();
    const navigate = useNavigate();

    const language = useSelector((state) => state.global.data.language);
    const ipLocation = useSelector((state) => state?.global?.data?.location);

    function handleLanguageChange(value) {
        localStorage.setItem("selected_language", value);
        navigate(0);
    }

    return <>
        <Container fluid className="footer-container">

            <Container fluid="lg">

                <img src="https://cherryspins.b-cdn.net/orozino-test/logo_bw-1.png" className="footer-logo" alt="Cherry Spins" />

                <div className="footer-content">

                    <div className="footer-info">
                        {/* <p>{text('footer_legal')}</p> */}
                        
                        {
                            (
                                ipLocation.country_code === 'RU' || 
                                ipLocation.country_code === 'ES' ||
                                ipLocation.country_code === 'FI'
                            ) ? (
                                <p>{text('footer_legal')}</p>
                            ) : (
                                <p>{text('footer_legal_mock')}</p>
                            )
                        }

                        <Form.Select
                            aria-label="Choose your language"
                            className="footer-language-select"
                            value={language}
                            onChange={e => handleLanguageChange(e.target.value)}
                        >
                            <option value="pt">PT</option>
                            <option value="en">EN</option>
                            {/* <option value="fr">FR</option>
                            <option value="es">ES</option> */}
                        </Form.Select>
                    </div>

                    <div className="footer-links">
                        <a href={text('footer_btn_self_exclusion_href')} target="_blank" rel="noreferrer" className="footer-link">{text('footer_btn_self_exclusion')}</a>
                        <a href={text('footer_btn_dispute_resolution_href')} target="_blank" rel="noreferrer" className="footer-link">{text('footer_btn_dispute_resolution')}</a>
                        <a href={text('footer_btn_terms_href')} target="_blank" rel="noreferrer" className="footer-link">{text('footer_btn_terms')}</a>
                        <a href={text('footer_btn_responsible_gaming_href')} target="_blank" rel="noreferrer" className="footer-link">{text('footer_btn_responsible_gaming')}</a>
                        <a href={text('footer_btn_privacy_policy_href')} target="_blank" rel="noreferrer" className="footer-link">{text('footer_btn_privacy_policy')}</a>
                    </div>
                </div>

                <div className="footer-images">
                    {
                        (ipLocation.country_code === 'RU' || ipLocation.country_code === 'ES') && <License />
                    }
                    <img src={imageAgeLimit} className="footer-image footer-image-opacity" alt="" />
                </div>
            </Container>
        </Container>
    </>
};