import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function DepositButtonBack({ toPreviousStep, copy }) {
    const isPWA = useSelector((state) => state?.global?.data?.device?.isPWA);

    return (
        <div className={`btn-deposit-back-container ${isPWA && 'btn-deposit-back-container-app'}`}>
            <Button className="btn-secondary btn-deposit-back" onClick={toPreviousStep}>
                <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                {copy}
            </Button>
        </div>
    )
}