import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

/** Metadata */
import metadata from '../../metadata.json'

export default function RouteChange() {
    const location = useLocation()
    
    useEffect(() => {
        /************************/
        /** TO DO: Add GTM implementation */

        /************************/
        /** Theme color change on game screen */
        if (location?.pathname?.includes('/game/')) {
            document.querySelector("meta[name='theme-color']").content = "#000000";
        }
        else {
            if (document.querySelector("meta[name='theme-color']").content !== metadata?.theme_color) {
                document.querySelector("meta[name='theme-color']").content = metadata?.theme_color;
            }
        }
    }, [location])

    return null;
}