import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
// import { Form, FloatingLabel, Accordion } from 'react-bootstrap';

/** Components */
import DepositBonusCard from "./DepositBonusCard";
import ScrollButtons from "./ScrollButtons";

/** Utilities */
import isValidBonus from "../utilities/is-valid-bonus";
import isInViewport from "../utilities/is-in-viewport";
import useText from "../utilities/use-text";

export default function DepositBonusSection({ promoCode, setPromoCode, setGlobalLimits, setLimits }) {
    const location = useLocation();

    const user = useSelector((state) => state.user.data)
    const bonuses = useSelector((state) => state.bonuses.list);
    const isLoadingBonuses = useSelector((state) => state.bonuses.isLoading);
    const theme = useSelector((state) => state?.global?.data?.theme);
    const text = useText();

    // const [isSearchingPromoCode, setIsSearchingPromoCode] = useState('');
    const [checkedURL, setCheckedURL] = useState(false);

    const container = useRef(null);

    const placeholder = new Array(3).fill(null);

    // function isValidSearch(code) {
    //     if (!isSearchingPromoCode || !isValidBonus({ bonus: bonuses[code], user, type: 'DEPOSIT', showHidden: true, themeId: theme?.id })) return false;
    //     return true;
    // }

    function setBonusLimits(bonus) {
        const option = bonus.currencyList?.filter(item => {
            return item.currency.id === user.currency.id;
        })[0];

        setLimits({
            depositMinAmount: option.depositMinAmount,
            depositMaxAmount: option.depositMaxAmount,
        })
    }

    function applyBonus(code) {
        const bonus = bonuses[code];

        if (!isValidBonus({ bonus, user, type: 'DEPOSIT', showHidden: true, themeId: theme?.id })) {
            setPromoCode('');
            setGlobalLimits();
            return;
        }

        setPromoCode(code);
        bonus && setBonusLimits(bonus);
    }

    function getPromoCodeFromURL() {
        setCheckedURL(true);
        const search = location?.search;
        const code = search?.split('promoCode=')[1]?.split('&')[0];
        code && applyBonus(code);

        /** Scroll to active bonus */
        const bonusCard = document.querySelector(`#promo-${code}`);

        if (bonusCard?.offsetLeft && !isInViewport(bonusCard)) {
            container.current.scrollLeft = bonusCard.offsetLeft - 50;
        }
    }

    useEffect(() => {
        !checkedURL && Object.keys(bonuses)?.length > 0 && getPromoCodeFromURL();
    })

    return (
        <div className="deposit-bonus-section">
            {
                isValidBonus({ bonus: bonuses[promoCode], user, type: 'DEPOSIT', showHidden: true, themeId: theme?.id }) ? (
                    <h2 className="subtitle deposit-bonus-section-title">
                        {text('deposit_bonus_section_title_selected')}
                        &nbsp;
                        <span className="deposit-bonus-selected-promocode">{bonuses[promoCode]?.promoCode}</span>
                    </h2>
                ) : (
                    <h2 className="subtitle deposit-bonus-section-title">
                        {text('deposit_bonus_section_title')}
                    </h2>
                )
            }

            <div className="bonus-card-row-container">
                {
                    isLoadingBonuses && (
                        <>
                            <div className="bonus-card-row-wrapper" ref={container}>
                                {
                                    placeholder.map((bonus, index) =>
                                        <DepositBonusCard
                                            key={index}
                                            bonus={bonus}
                                            isSelected={false}
                                            selectBonusToggle={() => { }}
                                        />
                                    )
                                }
                            </div>
                        </>
                    )
                }

                {
                    !isLoadingBonuses && (
                        <>
                            <div className="bonus-card-row-wrapper" ref={container}>
                                {
                                    Object.entries(bonuses).map(([key, bonus]) => (
                                        <DepositBonusCard
                                            key={key}
                                            bonus={bonus}
                                            isSelected={promoCode === bonus.promoCode}
                                            selectBonusToggle={() => {
                                                /** Allows to toggle the bonus selection on and off */
                                                promoCode !== bonus.promoCode ? applyBonus(bonus.promoCode) : applyBonus('');
                                                // isSearchingPromoCode && setIsSearchingPromoCode(false)
                                            }}
                                        />
                                    ))
                                }
                            </div>
                            <ScrollButtons container={container} scroll={200} />
                        </>
                    )
                }

            </div>

            {/* TO DO: fix the promocode field - it doesn't allow to type */}
            {/* <Accordion className="bonus-promocode-field-accordion">
                <Accordion.Item className="bonus-promocode-field-accordion-item" eventKey="0">
                    <Accordion.Header className="bonus-promocode-field-link">{messages[language]?.bonus_section_enter_promocode}</Accordion.Header>
                    <Accordion.Body>
                        <Form.Group controlId="promoCode">
                            <FloatingLabel label={messages[language]?.bonus_section_enter_promocode}>
                                <Form.Control
                                    type="text"
                                    value={isSearchingPromoCode ? promoCode : ''}
                                    isValid={isValidSearch(promoCode)}
                                    onChange={(e) => {
                                        isSearchingPromoCode ? applyBonus(e.target.value) : setIsSearchingPromoCode(true);
                                        applyBonus(e.target.value);
                                    }} />
                            </FloatingLabel>
                        </Form.Group>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> */}
        </div>
    )
}