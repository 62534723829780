import { useSelector } from 'react-redux';
import { totalBalance } from "../utilities/balance"

export default function PlayerBalance() {
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    return !userLoggedIn ? null : (
        <span className='player-balance'>
            {user.currency.sign}
            {totalBalance(user)}
        </span>
    )
}