
import React from "react";
import { Placeholder } from "react-bootstrap";

export default function CategoryTitle({title, ...props}) {
    if (!title) {
        return (
            <Placeholder as="h3" animation="glow" className={props.className}>
                <Placeholder xs={12} style={{ width: "170px", borderRadius: "10px" }} />
            </Placeholder>
        )
    }

    return (
        <h2 className={props.className}>{title}</h2>
    )
}