import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faHome, faAngleLeft, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import { totalBalance } from '../utilities/balance';

export default function NavigationInGame() {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const user = useSelector((state) => state.user.data);
    const balance = userLoggedIn ? totalBalance(user) : 0;

    const [orientation, setOrientation] = useState('portrait');

    function resizeHandler() {
        if (isMobile && orientation === 'portrait' && window.innerWidth > window.innerHeight) {
            setOrientation('landscape')
        }

        if (isMobile && orientation === 'landscape' && window.innerWidth < window.innerHeight) {
            setOrientation('portrait')
        }
    }

    useEffect(() => {
        if (isMobile) {
            window.addEventListener("resize", resizeHandler);

            if (orientation === 'portrait' && window.innerWidth > window.innerHeight) {
                setOrientation('landscape')
            }
        }

        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    })

    return (
        <Navbar className={`navbar-game navbar-game-${orientation}`}>
            <div className="navbar-game-wrapper">
                <Nav className="navbar-back-links">
                    <Button className="navbar-game-link" onClick={() => { window.history.back(); }} >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                    <Link to="/" className="navbar-game-link" >
                        <FontAwesomeIcon icon={faHome} />
                    </Link>
                </Nav>
                <Nav>
                    <Link to="?cashier" className={`navbar-game-link btn-add-funds ${balance < 1 ? 'btn-add-funds-red' : ''}`} >
                        <FontAwesomeIcon icon={faCreditCard} />
                        {
                            balance < 1 && (
                                <FontAwesomeIcon className='btn-add-funds-alert' icon={faCircleExclamation} />
                            )
                        }
                    </Link>
                </Nav>
            </div>
        </Navbar>
    )
}