import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

/** Components */
import NavLink from './NavLink';
import PlayerBalance from './PlayerBalance';
import NavigationInGame from './NavigationInGame';
import { IconHome, IconGames, IconCashier, IconSearch, IconProfile, IconLogIn } from '../../components/Icons';

/** Utilities */
import { totalBalance } from "../utilities/balance";
import useText from "../utilities/use-text";

/** Meta */
import metadata from '../../metadata';

export default function Navigation() {
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const isPWA = useSelector((state) => state?.global?.data?.device?.isPWA);
    const text = useText();

    const location = useLocation();
    const navigate = useNavigate();

    const balanceCutOff = 15;

    /** States */
    // const [hasBackground, setHasBackground] = useState(false);
    const [isBottomNavVisible, setIsBottomNavVisible] = useState(true);

    // /** Refs */
    // const bottomNav = useRef(null)

    /** Scroll variable */
    let prevScrollYPosition;

    const searchClickHandler = () => {
        const input = document.querySelector("[id='gameSearchInput']");

        if (input) {
            input.click();
            input.focus();
            return;
        }
        navigate(`/games/popular?search`);
    };

    const scrollHandler = () => {
        let currentScrollYPosition = window.scrollY;

        /** Show bottom nav at the top of the page */
        if (parseFloat(currentScrollYPosition) < 100) {
            if (!isBottomNavVisible) {
                setIsBottomNavVisible(true)
            }

            prevScrollYPosition = currentScrollYPosition;
            return;
        }

        /** Show bottom nav on scroll up */
        if (parseFloat(prevScrollYPosition) > parseFloat(currentScrollYPosition) && !isBottomNavVisible) {
            setIsBottomNavVisible(true)
        }

        /** Hide scroll nav on scroll down */
        if (parseFloat(prevScrollYPosition) < parseFloat(currentScrollYPosition) && isBottomNavVisible) {
            setIsBottomNavVisible(false)
        }

        prevScrollYPosition = currentScrollYPosition;
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler);
        }
    })

    //////////////////
    /// Do not show navigation on the blocked IP
    /////////////////
    if (location.pathname.includes('region-blocked')) {
        return null;
    }

    //////////////////
    /// Game page navigation
    /////////////////
    if (location.pathname.includes('/game/')) {
        return <NavigationInGame />
    }

    return (
        <>
            {/* Desktop */}
            <Navbar className={`navbar-top navbar-background d-none d-md-flex`}>
                <Container fluid="lg">

                    {/* Logo */}
                    <Link to={`/`} className={`navbar-brand brand`}>
                        <img src={metadata?.logos?.main} className="logo" alt="Website Logo" />
                    </Link>

                    {/* Left */}
                    <Nav className="me-auto">
                        <NavLink to={`/`} className="navbar-link" activeClassName="navbar-link-active" end>{text('navigation_btn_home')}</NavLink>
                        <NavLink to={`/games`} className="navbar-link" activeClassName="navbar-link-active">{text('navigation_btn_games')}</NavLink>
                    </Nav>

                    {/* Right */}
                    <Nav>
                        {
                            !userLoggedIn ? (
                                <>
                                    <Link to="?login" className="btn btn-main btn-navbar me-2">{text('navigation_btn_log_in')}</Link>
                                    <Link to="?register" className="btn btn-secondary btn-navbar">{text('navigation_btn_register')}</Link>
                                </>
                            ) : (
                                <>
                                    <Link to="?cashier" className="btn-secondary-outline navbar-balance btn-navbar">
                                        <PlayerBalance /> &nbsp;&nbsp;
                                        <IconCashier />
                                    </Link>
                                    <Link to="?profile" className="btn-secondary-outline navbar-profile btn-navbar">
                                        <IconProfile />
                                    </Link>
                                </>
                            )
                        }
                    </Nav>
                </Container>
            </Navbar>

            {/* Top Mobile | Logged Out */}
            {!userLoggedIn && (
                <Navbar className={`navbar-top navbar-background d-sm-flex d-md-none`}>
                    <Container fluid >

                        {/* Logo */}
                        <Link to="/" className="navbar-brand brand">
                            <img src={metadata?.logos?.main} className="logo" alt="Website logo" />
                        </Link>

                        {/* Right */}
                        <Nav>
                            <Link to="?login" className={`btn btn-main btn-navbar me-2`} >{text('navigation_btn_log_in')}</Link>
                            <Link to="?register" className={`btn btn-secondary btn-navbar`}>{text('navigation_btn_register')}</Link>
                        </Nav>
                    </Container>
                </Navbar>
            )}

            {/* Top Mobile | Logged In */}
            {userLoggedIn && (
                <Navbar className="navbar-top-logged-in-mobile d-sm-flex d-md-none">
                    <Container fluid>

                        {/* Left */}
                        <Nav className="navbar-name-wrapper">
                            <span className="navbar-name">{`${user.firstName} ${user.lastName}`}</span>
                        </Nav>

                        <Nav className="">
                            <img src={metadata?.logos?.main} style={{ height: '18px' }} alt="Website Logo" />
                        </Nav>

                        {/* Right */}
                        <Nav className="navbar-balance-mobile-wrapper">
                            <Link to="?cashier" className="navbar-balance-mobile">
                                <PlayerBalance />&nbsp;
                                <IconCashier />
                            </Link>
                        </Nav>
                    </Container>
                </Navbar>
            )}

            {/* Bottom Mobile */}
            <Navbar className={`d-sm-flex d-md-none navbar-bottom ${isPWA && 'navbar-bottom-app'} ${!isBottomNavVisible && 'navbar-bottom-hidden'} animate__animated animate__faster animate__fadeInUp`}>
                <Container fluid className='ps-0 pe-0'>

                    {/* Left */}
                    <Nav className="w-100 justify-content-around navbar-bottom-wrapper">

                        {/* Home */}
                        <NavLink to="/" className="navbar-bottom-link navbar-bottom-link-logo" activeClassName="navbar-bottom-link-active navbar-bottom-link-logo-active" end>
                            <IconHome />
                        </NavLink>

                        {/* Games */}
                        <NavLink to="/games" className="navbar-bottom-link" activeClassName="navbar-bottom-link-active">
                            <IconGames />
                        </NavLink>

                        {/* Deposit */}
                        {
                            userLoggedIn && (
                                <Link to="?cashier" className={`navbar-bottom-link ${parseFloat(totalBalance(user)) < balanceCutOff && 'navbar-bottom-link-deposit'}`}>
                                    {
                                        parseFloat(totalBalance(user)) < balanceCutOff && (
                                            <FontAwesomeIcon icon={faExclamationCircle} className='navbar-bottom-link-deposit-alert' />
                                        )
                                    }
                                    <IconCashier />
                                </Link>
                            )
                        }

                        {/* Search */}
                        <Button onClick={searchClickHandler} className="navbar-bottom-link navbar-bottom-search" >
                            <IconSearch />
                        </Button>

                        {/* Profile */}
                        {
                            userLoggedIn ? (
                                <Link to="?profile" className="navbar-bottom-link">
                                    <IconProfile />
                                </Link>
                            ) : (
                                <Link to="?login" className="navbar-bottom-link">
                                    <IconLogIn />
                                </Link>
                            )
                        }
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}