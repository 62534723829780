import React from 'react';
import { Button, Form, FloatingLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** Components */
import DepositAmountPreset from './DepositAmountPreset';

/** Utilities */
import isValidBonus from "../utilities/is-valid-bonus";
import useText from "../utilities/use-text";

export default function DepositAmountSection({ amount, setAmount, promoCode, setPromoCode, depositMinAmount, depositMaxAmount, transitionToStep }) {
    const user = useSelector((state) => state.user.data)
    const bonuses = useSelector((state) => state.bonuses.list);
    const theme = useSelector((state) => state?.global?.data?.theme);
    const text = useText();

    return (
        <div className='deposit_amount_section'>
            <h2 className="subtitle deposit_amount_section_title">{text('deposit_amount_title')}</h2>

            <div>
                <DepositAmountPreset {...{ amount, setAmount }} />

                <Form.Group controlId="amount">
                    <FloatingLabel label={text('deposit_amount_field_label', user.currency.sign)} >
                        <Form.Control
                            type="number"
                            className="amount-input"
                            aria-label={text('deposit_amount_field')}
                            value={amount}
                            onChange={(e) => { setAmount(e.target.value) }}
                            isValid={amount >= depositMinAmount && amount <= depositMaxAmount}
                            isInvalid={amount < depositMinAmount || amount > depositMaxAmount}
                        />
                        {
                            promoCode === '' ? (
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {text('deposit_amount_limit', { sign: user.currency.sign, depositMinAmount, depositMaxAmount })}
                                </Form.Control.Feedback>
                            ) : (
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {text('deposit_amount_bonus_limit', { sign: user.currency.sign, depositMinAmount, depositMaxAmount })}
                                </Form.Control.Feedback>
                            )
                        }
                    </FloatingLabel>
                </Form.Group>
            </div>

            {/* Button */}
            <div className="btn-deposit-container">
                <Button
                    className="btn-main-lg btn-deposit"
                    disabled={amount < depositMinAmount || amount > depositMaxAmount}
                    onClick={() => {
                        if (amount >= depositMinAmount && amount <= depositMaxAmount) {
                            !isValidBonus({
                                bonus: bonuses[promoCode],
                                user,
                                type: 'DEPOSIT',
                                showHidden: true,
                                themeId: theme?.id
                            }) && setPromoCode('');
                            transitionToStep(2);
                        }
                    }}
                >
                    {text('deposit_amount_cta')}
                </Button>
            </div>

            {/* <div className='deposit_amount_section_images'>
                <img src="https://spt-assets.b-cdn.net/orozino/assets/secure-logo.png" />
                <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-pcidss.png" />
            </div> */}
        </div>
    )
}