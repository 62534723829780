import React from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

/** Components */
import BonusTimer from "./BonusTimer";

/** Utilities */
import isValidBonus from "../utilities/is-valid-bonus";
import useText from "../utilities/use-text";

export default function WeeklyBonus({ type }) {
    const text = useText();
    const user = useSelector((state) => state.user.data);
    const country = useSelector((state) => state.global.data.country)
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const bonuses = useSelector((state) => state.bonuses.list);

    const bonusArray = Object.entries(bonuses).filter(([key, bonus]) => {
        if (!key.includes(type)) return false;

        let currencyCode = user?.currency?.code || country?.currency?.code;
        let hasCurrencyOptioin = false;

        for (const { currency } of bonus?.currencyList) {
            if (currency.code === currencyCode) {
                hasCurrencyOptioin = true;
            }
        }

        return hasCurrencyOptioin;
    })[0];

    const bonus = bonusArray ? bonusArray[1] : null;

    return (
        <>
            {
                bonus && (!userLoggedIn || isValidBonus({ bonus, user, type: 'DEPOSIT', showHidden: false })) && (
                    <Container fluid="lg" className="section-margin weekly-bonus-section" >
                        <div className="weekly-bonus-tile-container" >
                            <div className="weekly-bonus-timer-wrapper">
                                <BonusTimer {...{ bonus }} />
                            </div>
                            <div className="weekly-bonus-image-wrapper">
                                <img className="weekly-bonus-image" src={bonus?.content?.imageUrl} alt="" />
                            </div>
                            <div className="weekly-bonus-tile-content">
                                <div className="weekly-bonus-tile-content-inner">
                                    {/* {
                                        bonus?.content?.badge && (
                                            <span className="weekly-bonus-badge mb-0">
                                                {bonus?.content?.badge}
                                            </span>
                                        )
                                    } */}
                                    <h2 className="subtitle white-100 mb-0">{bonus?.content?.title}</h2>
                                    {/* {
                                        !bonus?.content?.subtitle ? null :
                                            <p className="">{bonus.content.subtitle}</p>
                                    } */}
                                </div>
                                <div className="weekly-bonus-tile-buttons">
                                    {
                                        userLoggedIn ? (
                                            <Link to={`?cashier&promoCode=${bonus.promoCode}`} className="btn btn-main">{text('weekly_bonus_cta')}</Link>
                                        ) : (
                                            <Link to={`?register`} className="btn btn-main">{text('weekly_bonus_cta')}</Link>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Container>
                )
            }
        </>
    )
}