import React, { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { dbGetActiveWithdrawal } from '../utilities/api-functions';

/** Utilities */
import useText from "../utilities/use-text";

export default function WithdrawAllowed({ step, setIsWithdrawAllowed }) {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    const [activeWithdrawal, setActiveWithdrawal] = useState(null);

    async function getActiveWithdrawal() {
        try {
            const result = await dbGetActiveWithdrawal();

            if (result?.data?.docs?.length > 0) {
                setIsWithdrawAllowed(false)
                setActiveWithdrawal(result?.data?.docs[0]);
                console.log(result?.data?.docs[0])
            }
        } catch (error) {
            console.log(`Error, getActiveWithdrawal, ${error}`);
        }
    }

    useEffect(() => {
        step === 1 && getActiveWithdrawal();
    }, [step])

    return (
        <>
            {
                activeWithdrawal && (
                    <Alert
                        variant="success"
                        className="animate__animated animate__faster animate__fadeIn"
                        style={{fontSize: "14px", margin: "0 10px"}}
                    >
                        {text('withdrawal_allowed_message', {sign: user?.currency?.sign, activeWithdrawal})}
                    </Alert>
                )
            }
        </>
    )
}