import React, { useState } from "react";
import { Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

/** Components */
import GameTileTags from './GameTileTags'

export default function GameTile({ game, category, index, isLastPlayed}) {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    // const [isLoadingImage, setIsLoadingImage] = useState(true);

    if (!game) {
        return (
            <div className="game-tile-placeholder-wrapper">
                <Placeholder as="div" animation="glow" className='mb-1 game-tile-image-placeholder-wrapper'>
                    <Placeholder xs={12} className="game-tile-image-placeholder" />
                </Placeholder>
                {
                    !isLastPlayed && (
                        <>
                            <Placeholder as="p" animation="glow" className='mb-0'>
                                <Placeholder xs={8} size="sm" style={{ borderRadius: '6px' }} />
                            </Placeholder>
                            <Placeholder as="p" animation="glow" className='mb-0'>
                                <Placeholder xs={5} size="sm" style={{ borderRadius: '6px' }} />
                            </Placeholder>
                        </>
                    )
                }

            </div>
        )
    }

    return (
        <>
            <Link to={`${userLoggedIn ? `/game/${game.id}` : `?register&game=${game.id}`}`}
                className={`game-tile-container gtm-potision-${index}-${category}`}
            >
                <div className="game-tile-image-wrapper">
                    <GameTileTags tags={game?.tags} />

                    {/* Back cover image */}
                    <div className="game-tile-image-cover" style={{ backgroundImage: `url(${game.image}?quality=0&auto_optimize=low` }}></div>

                    {/* Low resolution image */}
                    {/* <img className="game-tile-image"
                        src={`${game.image}?quality=0&auto_optimize=low`}
                        alt={game.name}
                        style={{ visibility: isLoadingImage ? 'visible' : 'hidden' }}
                    /> */}

                    {/* Full resolution image */}
                    <img className="game-tile-image"
                        src={`${game.image}?quality=50`}
                        alt={game.name}
                        // style={{ visibility: isLoadingImage ? 'hidden' : 'visible' }}
                        // onLoad={() => { setIsLoadingImage(false) }}
                    />

                    <div className="game-tile-overlay">
                        <FontAwesomeIcon icon={faPlay} className="game-tile-overlay-icon" />
                    </div>
                </div>
                {
                    !isLastPlayed && (
                        <>
                            <p className="game-tile-title">{game.name}</p>
                            <p className="game-tile-provider">{game.provider}</p>
                        </>
                    )
                }
            </Link>
        </>
    )
}