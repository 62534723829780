import React from "react";
import { Container } from "react-bootstrap";

/** Utilities */
import useText from "../utilities/use-text";

export default function RegionBlocked() {
    const text = useText();

    return (
        <Container className="region_blocked-section">
            <div className="region_blocked-description">
                <p className="white-60">{text('region_blocked_title')}</p>
            </div>
        </Container>
    );
}