import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** Utilities */
import useText from "../utilities/use-text";

export default function DepositToggle({ toggle, setToggle }) {
    const text = useText();

    return (
        <ButtonGroup className="cashier_toggle">
            <ToggleButton
                className='cashier_toggle_button cashier_toggle_button-left'
                id="toggle-check-deposit"
                type="checkbox"
                variant="secondary"
                checked={toggle === 'deposit'}
                onChange={() => toggle !== 'deposit' && setToggle('deposit')}
            >
                {text('deposit_toggle_btn_deposit')}
            </ToggleButton>
            <ToggleButton
                className='cashier_toggle_button cashier_toggle_button-right'
                id="toggle-check-withdraw"
                type="checkbox"
                variant="secondary"
                checked={toggle === 'withdraw'}
                onChange={() => toggle !== 'withdraw' && setToggle('withdraw')}
            >
                {text('deposit_toggle_btn_withdraw')}
            </ToggleButton>
        </ButtonGroup>
    )
}