import React, { useEffect, useState, useRef } from "react";
import { Spinner } from 'react-bootstrap';

/** Utilities */
import useText from "../utilities/use-text";

// export function _DailyJackpot() {
//     const text = useText();

//     const [amount, setAmount] = useState(0);
//     const [timer, setTimer] = useState('');

//     function leadingZero(num) {
//         num = num.toString();
//         while (num.length < 2) num = "0" + num;
//         return num;
//     }

//     function withComas(amount) {
//         return Number.parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//     }

//     useEffect(() => {
//         const contributionPerSecond = 1.38719;
//         const today = new Date();
//         let hours = today.getHours();
//         let mimuntes = today.getMinutes();
//         let seconds = today.getSeconds();
//         let secondsFromStartOfDate = (hours * 60 * 60) + (mimuntes * 60) + seconds;
//         let secondsUntilEndOfDate = (24 * 60 * 60) - secondsFromStartOfDate;

//         let tempAmount = (Math.round((secondsFromStartOfDate * contributionPerSecond) * 100) / 100).toFixed(2);
//         let tempHours = Math.floor((secondsUntilEndOfDate / 60 / 60) % 24);
//         let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
//         let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

//         const timeout = setTimeout(() => {
//             (amount === 0 || tempSeconds % 9 === 0) && setAmount(tempAmount);
//             setTimer(`${leadingZero(tempHours)}:${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`)
//         }, 1000);

//         return () => {
//             clearTimeout(timeout);
//         }

//     }, [timer])

//     return (
//         <div className="must-drop-jackpot-tile-container daily-drop">
//             <div className="must-drop-content-top">
//                 <p className="mb-0 must-drop-timer-lable">{text('jackpot_types_drops_in')?.toUpperCase()}</p>
//                 <p className="mb-0 must-drop-timer" >{timer}</p>
//             </div>
//             <div className="must-drop-content-bottom">
//                 <span className="title white-100 must-drop-title-daily">${withComas(amount)}</span>
//             </div>
//         </div>
//     )
// }

export function DailyJackpot() {
    const text = useText();

    /********************/
    /** Updates */
    const amount = useRef(null);
    const timer = useRef(null);

    /** Functions */
    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function withComas(amount) {
        return Number.parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function getValue() {
        const contributionPerSecond = 1.38719;
        const today = new Date();
        let hours = today.getHours();
        let mimuntes = today.getMinutes();
        let seconds = today.getSeconds();
        let secondsFromStartOfDate = (hours * 60 * 60) + (mimuntes * 60) + seconds;
        let secondsUntilEndOfDate = (24 * 60 * 60) - secondsFromStartOfDate;

        let tempAmount = (Math.round((secondsFromStartOfDate * contributionPerSecond) * 100) / 100).toFixed(2);
        let tempHours = Math.floor((secondsUntilEndOfDate / 60 / 60) % 24);
        let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
        let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

        /***********/
        /** Response */
        let data = {};

        data.timer = `${leadingZero(tempHours)}:${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`

        if (amount === 0 || tempSeconds % 9 === 0) {
            data.amount = withComas(tempAmount);
        }
        
        return data;
    }

    function updateTimerInterval() {
        const value = getValue();

        try {
            if (value.timer) {
                timer.current.innerHTML = value.timer;
            }
        } catch (error) {}
        
        try {
            if (value.amount) {
                amount.current.innerHTML = value.amount;
            }
        } catch (error) {}
    }

    /********************/
    /********************/

    useEffect(() => {
        const interval = setInterval(updateTimerInterval, 1000);
        return () => { clearInterval(interval); }
    }, []);
    
    return (
        <div className="must-drop-jackpot-tile-container daily-drop">
            <div className="must-drop-content-top">
                <p className="mb-0 must-drop-timer-lable">{text('jackpot_types_drops_in')?.toUpperCase()}</p>
                <p ref={timer} className="mb-0 must-drop-timer"><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></p>
            </div>
            <div className="must-drop-content-bottom">
                <span ref={amount} className="title white-100 must-drop-title-daily">
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                </span>
            </div>
        </div>
    )
}


export function HourlyJackpot() {
    const text = useText();

    /********************/
    /** Updates */
    const amount = useRef(null);
    const timer = useRef(null);

    /** Functions */
    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function withComas(amount) {
        return Number.parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function getValue() {
        const contributionPerSecond = 4.433719;
        const today = new Date();
        let mimuntes = today.getMinutes();
        let seconds = today.getSeconds();
        let secondsFromStartOfDate = (mimuntes * 60) + seconds;
        let secondsUntilEndOfDate = (60 * 60) - secondsFromStartOfDate;

        let tempAmount = (Math.round((secondsFromStartOfDate * contributionPerSecond) * 100) / 100).toFixed(2);
        let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
        let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

        /***********/
        /** Response */
        let data = {};

        data.timer = `${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`

        if (amount === 0 || tempSeconds % 7 === 0) {
            data.amount = withComas(tempAmount);
        }
        
        return data;
    }

    function updateTimerInterval() {
        const value = getValue();

        try {
            if (value.timer) {
                timer.current.innerHTML = value.timer;
            }
        } catch (error) {}
        
        try {
            if (value.amount) {
                amount.current.innerHTML = value.amount;
            }
        } catch (error) {}
    }

    /********************/
    /********************/

    useEffect(() => {
        const interval = setInterval(updateTimerInterval, 1000);
        return () => { clearInterval(interval); }
    }, []);
    
    return (
        <div className="must-drop-jackpot-tile-container hourly-drop">
            <div className="must-drop-content-top">
                <p className="mb-0 must-drop-timer-lable">{text('jackpot_types_drops_in')?.toUpperCase()}</p>
                <p ref={timer} className="mb-0 must-drop-timer"><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></p>
            </div>
            <div className="must-drop-content-bottom">
                <span ref={amount} className="title white-100 must-drop-title-daily">
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                </span>
            </div>
        </div>
    )
}

// export function _HourlyJackpot() {
//     const text = useText();

//     const [amount, setAmount] = useState(0);
//     const [timer, setTimer] = useState('');

//     function leadingZero(num) {
//         num = num.toString();
//         while (num.length < 2) num = "0" + num;
//         return num;
//     }

//     function withComas(amount) {
//         return Number.parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//     }

//     useEffect(() => {
//         const contributionPerSecond = 4.433719;
//         const today = new Date();
//         let mimuntes = today.getMinutes();
//         let seconds = today.getSeconds();
//         let secondsFromStartOfDate = (mimuntes * 60) + seconds;
//         let secondsUntilEndOfDate = (60 * 60) - secondsFromStartOfDate;

//         let tempAmount = (Math.round((secondsFromStartOfDate * contributionPerSecond) * 100) / 100).toFixed(2);
//         let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
//         let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

//         const timeout = setTimeout(() => {
//             (amount === 0 || tempSeconds % 7 === 0) && setAmount(tempAmount);
//             setTimer(`${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`)
//         }, 1000);

//         return () => {
//             clearTimeout(timeout);
//         }

//     }, [timer])

//     return (
//         <div className="must-drop-jackpot-tile-container hourly-drop">
//             <div className="must-drop-content-top">
                
//                 <p className="mb-0 must-drop-timer-lable">{text('jackpot_types_drops_in')?.toUpperCase()}</p>
//                 <p className="mb-0 must-drop-timer" >{timer}</p>
//             </div>
//             <div className="must-drop-content-bottom">
//                 <span className="title white-100 must-drop-title-hourly">${withComas(amount)}</span>
//             </div>
//         </div>
//     )
// }