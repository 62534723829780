import React, { useRef } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** Components */
import GameTile from "./GameTile";
import ScrollButtons from './ScrollButtons';
import CategoryTitle from "./CategoryTitle";

export default function GameRowSpotlightCategory({ categoryURL }) {
    const container = useRef(null);
    const placeholder = new Array(10).fill(null);
    
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? categories[categoryURL] : null;

    return (
        <Container fluid="lg" className="category-container row-container section-margin" id={categoryURL}>
            <CategoryTitle title={category?.title} className="title-xs mb-3" />
            <div className="position-relative">
                <div className="spotlight-row-wrapper" ref={container}>
                    {
                        !category ? placeholder.map((game, index) => <GameTile game={game} key={`spotlight-${index}`} />) :
                            category.games.map((game, index) => {
                                return <GameTile 
                                    game={game}
                                    index={index}
                                    category={categoryURL}
                                    key={`spotlight-${categoryURL}-${game.id}-${index}`}
                                />
                            })
                    }
                </div>
                <ScrollButtons container={container} />
            </div>
        </Container>
    );
}