export const pt = {
    // ClaimWinningsAllowed.js
    claim_winnings_allowed_message: 'Estamos revisando seus ganhos e entraremos em contato o mais rápido possível.',

    // WithdrawAllowed.js
    withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>Atualmente você tem um saque ativo de {sign}{activeWithdrawal?.amount}. Por favor aguarde enquanto processamos seu saque antes de solicitar um novo.</span>,

    // BonusRowCategory.js
    bonus_row_title: "Escolha seu primeiro bônus",
    bonus_row_title_2: "Escolha seu próximo bônus",

    // BonusTile.js
    bonus_tile_cta: "Reivindique agora",

    // BonusTimer.js
    bonus_timer_expired: "Caduca hoje!",

    // Deposit.js
    deposit_btn_back_to_amount: " Voltar ao valor do depósito ",
    deposit_btn_back_to_payment_options: " Voltar para opções de pagamento ",

    // DepositAmountSection.js
    deposit_amount_title: "Valor",
    deposit_amount_cta: " Depósito ",
    deposit_amount_field: " Valor do depósito ",
    deposit_amount_field_label: (sign) => {
        return ` Valor do depósito (${sign})`
    },
    deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return ` O valor do depósito deve estar entre ${sign}${depositMinAmount} e ${sign}${depositMaxAmount}.`
    },
    deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `O valor do depósito para o bônus selecionado deve estar entre ${sign}${depositMinAmount} e ${sign}${depositMaxAmount}.`
    },

    // DepositBonusCard.js
    deposit_bonus_card_min_deposit: "Depósito mínimo:",

    // DepositBonusSection.js
    deposit_bonus_section_title: "Escolha seu bônus",
    deposit_bonus_section_title_selected: " Bônus selecionado:",

    // DepositPaymentMethod.js
    deposit_payment_method_title: " Escolha o seu método de pagamento ",
    deposit_payment_method_bonus: " Bônus extra de 15% ",

    // DepositRedirect.js
    deposit_redirect_title: "Complete seu pagamento",
    deposit_redirect_cta: "Deposite",
    deposit_redirect_cta_processing: "Processando",
    deposit_redirect_success_title: " Quase lá!",
    deposit_redirect_success_text: " Clique abaixo para recarregar e começar a jogar.",
    deposit_redirect_success_cta: " Conclua seu pagamento ",
    deposit_redirect_error: (error) => {
        return `${error ? error : 'Ocorreu um erro.'} Por favor, tente novamente ou escolha um método de pagamento diferente.`
    },
    deposit_redirect_field_first_name: 'Primeiro nome',
    deposit_redirect_field_last_name: 'Sobrenome',
    deposit_redirect_field_email: 'Email',
    deposit_redirect_field_phone: 'Telefone',
    deposit_redirect_field_address: 'Endereço',
    deposit_redirect_field_city: 'Cidade',
    deposit_redirect_field_state: 'Província/Estado',
    deposit_redirect_field_zip: 'CEP',

    // DepositSummary.js
    deposit_summary_amount: "Valor:",
    deposit_summary_bonus: "Bônus",
    deposit_summary_no_bonus: " Nenhum bônus selecionado.",

    // DepositToggle.js
    deposit_toggle_btn_deposit: "Depositar",
    deposit_toggle_btn_withdraw: "Sacar",

    // FreespinsRow.js
    freespin_row_title: ({ number, game }) => <> Você tem <span className="">{number} giros grátis </span> em <span className="">{game}</span>!</>,
    freespin_row_cta: " Jogue agora ",

    // GameRowJackpotTypes.js
    jackpot_types_daily: 'Jackpot diário',
    jackpot_types_hourly: 'Jackpot por hora',
    jackpot_types_drops_in: 'Sorteios dentro de',

    // GameSearchField.js
    game_search_input_placeholder: "Digite o jogo ou o provedor",
    game_search_input_hint: "Buscar",

    // IdleTimer.js
    idle_timer_title: " A sessão expirou ",
    idle_timer_text: "Você esteve inativo. Por favor faça login novamente.",
    idle_timer_cta: 'Login',

    // ModalClaimBonusWinnings.js
    claim_bonus_winnings_title: 'Reivindique seus ganhos',
    claim_bonus_winnings_processing: 'Processando...',
    claim_bonus_winnings_exceeded: 'Sua solicitação foi enviada para aprovação. Entraremos em contato com você o mais breve possível..',
    claim_bonus_winnings_error: 'Um erro ocorreu. Tentar novamente ou entrar em contato com o suporte.',

    // ModalCloseAccount.js
    close_account_title: 'Fechar conta',
    close_account_text: <span> Tem certeza de que deseja fechar sua conta?<br /><br /><i> Para encerrar sua autoexclusão, faça login com seu e-mail e senha e siga as instruções adicionais.</i></span>,
    close_account_cta: 'Sim, encerrar minha conta',

    // ModalOpenAccount.js
    open_account_title: 'Conta encerrada',
    open_account_text: 'Sua conta está atualmente encerrada. Deseja abrir sua conta?',
    open_account_cta: 'Sim, por favor abra minha conta',

    // Navigation.js
    navigation_btn_home: "Home",
    navigation_btn_games: "Jogos",
    navigation_btn_log_in: "Entrar",
    navigation_btn_register: "Criar Conta",
    navigation_balance: "Saldo",

    // PhoneVerification.js
    phone_verification_log_out_title: (number) => <>Ganhe <span className="verify-phone-title-spins">{number}</span> sem depósito&nbsp;obrigatório!</>,
    phone_verification_log_in_title: (number) => <> Verifique seu telefone e ganhe <span className="verify-phone-title-giros">{number}</span>!</>,
    phone_verification_field_code_title: 'Digite seu código de confirmação.',
    phone_verification_field_phone_label: 'Digite seu número de telefone',
    phone_verification_field_code_label: 'Código',
    phone_verification_btn_claim: 'Reivindique  agora',
    phone_verification_btn_verify_claim: 'Verifique e reivindique',
    phone_verification_btn_sms: 'SMS',
    phone_verification_btn_phone_call: 'Chamada telefónica',
    phone_verification_btn_confirm: "Confirmar",
    phone_verification_btn_cancel: 'Cancelar',
    phone_verification_btn_try_again: 'Tentar novamente',
    phone_verification_confirmation_title: "Este número está correto?",
    phone_verification_error_phone_already_verified: 'Este telefone já foi verificado.',
    phone_verification_error_wrong_phone: 'Este número não corresponde ao telefone em seu perfil.',
    phone_verification_error_active_bonus: 'Você já possui um bônus. Por favor atualize a página.',
    phone_verification_error_general: 'Ocorreu um erro. Por favor, verifique seu telefone e tente novamente.',
    phone_verification_example: 'Por exemplo:',

    // ProfilePlayerInformation.js
    profile_information_title: 'Informações de cobrança',
    profile_information_btn_save: 'Salvar',
    profile_information_change_phone: 'Nota: se você deseja alterar seu número, entre em contato com o suporte.',
    profile_information_field_first_name: 'Primeiro nome',
    profile_information_field_last_name: 'Sobrenome',
    profile_information_field_email: 'Email',
    profile_information_field_phone: 'Telefone',
    profile_information_field_address: 'Endereço',
    profile_information_field_city: 'Cidade',
    profile_information_field_state: 'Província/Região',
    profile_information_field_zip: 'CEP',
    profile_information_field_dob: 'Data de nascimento',

    // RegistrationForm.js
    registration_form_email_label: " Endereço de email ",
    registration_form_or: 'OU',
    registration_form_email_placeholder: "nome@exemplo.com",
    registration_form_password_label: "Senha",
    registration_form_key_hint: "Registre-se",
    registration_form_terms: 'Concordo com os Termos e Condições.',
    registration_form_btn_cta: "Criar conta gratuita",
    registration_form_verify_email_title: "Verifique seu e-mail",
    registration_form_verify_email_subtitle: "Enviamos um e-mail para o endereço que você forneceu. Verifique sua caixa de entrada e siga as instruções para ativar sua conta.",
    registration_form_error_email: " Por favor, forneça um endereço de e-mail válido.",
    registration_form_error_email_exists: <span> Esse e-mail já existe.<br />Tente <a href="?login">entrar em</a>?</span>,
    registration_form_error_password: " A senha precisa ter pelo menos 7 caracteres ",
    registration_form_error_terms: "Por favor, concorde com nossos Termos e Condições para completar o registro",
    registration_form_error_general: "Ocorreu um erro. Por favor, tente novamente.",

    // WeeklyBonus.js
    weekly_bonus_cta: 'Garanta agora',

    // Withdraw.js
    withdraw_balance: 'Saldo disponível para saque',
    withdraw_bonus_balance: 'Saldo de bônus',
    withdraw_wager_progress: 'Progresso de apostas',
    withdraw_choose_amount: 'Digite o valor a ser sacado',
    withdraw_choose_amount_label: (sign) => `Valor (${sign})`,
    withdraw_error_insufficient_funds: "Você não possui fundos para sacar.",
    withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => ` Fundos insuficientes. O valor mínimo para saque é ${sign}${minAmount}`,
    withdraw_error_wrong_amount_limit_min_max: ({ sign, maxAmount }) => ` O valor máximo para saque é ${sign}${maxAmount}`,
    withdraw_btn_back_to_payment_options: 'Voltar às opções de saque',

    // WithdrawMethod.js
    withdraw_method_interac_legal: '®Marca registrada da Interac Corp. Usada sob licença.',
    withdraw_method_choose_amount: 'Por favor, digite o valor para continuar',

    // WithdrawRedirect.js
    withdraw_redirect_title: 'Complete sua saque',
    withdraw_redirect_cta: 'Sacar',
    withdraw_redirect_cta_processing: 'Processando...',
    withdraw_redirect_success_title: ' Sucesso!',
    withdraw_redirect_success_text_1: 'Seu pedido de saque está sendo processado e deve ser concluído de 1 a 5 dias úteis, dependendo do método de saque escolhido..',
    withdraw_redirect_success_text_2: 'Assim que seu saque for processado, o valor escolhido será deduzido do saldo da sua conta.',
    withdraw_redirect_error: (error) => {
        return `${error ? error : 'Ocorreu um erro. Por favor, tente novamente ou escolha um método de saque diferente.'}`
    },
    withdraw_redirect_field_first_name: 'Primeiro nome',
    withdraw_redirect_field_last_name: 'Sobrenome',
    withdraw_redirect_field_email: 'Email',
    withdraw_redirect_field_phone: 'Telefone',
    withdraw_redirect_field_address: 'Endereço',
    withdraw_redirect_field_city: 'Cidade',
    withdraw_redirect_field_state: 'Província/Região',
    withdraw_redirect_field_zip: 'CEP',
    withdraw_redirect_field_card_number: 'Número do cartão',
    withdraw_redirect_field_exp_month: 'Mês de validade (MM)',
    withdraw_redirect_field_exp_year: 'Ano de validade (YY)',
    withdraw_redirect_wallet_id: 'ID da carteira',

    // WithdrawSummary.js
    withdrawal_summary_amount: 'Valor:',

    // ForgotPassword.js
    forgot_password_title: 'Recuperar sua senha',
    forgot_password_field_email: " Digite seu e-mail de registro",
    forgot_password_text_1: "Verifique sua caixa de entrada e pasta de spam.",
    forgot_password_text_2: "Se você não receber um e-mail dentro de 30 minutos, por favor, tente enviar o formulário novamente ou entre em contato com o suporte.",
    forgot_password_btn_close: "Fechar",
    forgot_password_btn_submit: "Enviar",

    // Login.js
    login_forgot_password_cta: " Esqueceu sua senha?",
    login_create_account: "Ainda não tem uma conta?",
    login_create_account_cta: "Registre-se",
    login_btn_close: "Fechar",
    login_cta: "Entrar",

    // Profile.js
    profile_title: 'Perfil do cliente',
    profile_balance: 'Saldo total:',
    profile_balance_withdrawable: 'Saldo disponível para saque:',
    profile_balance_bonus: 'Saldo de bônus:',
    profile_wager_progress: 'Progresso de apostas:',
    profile_bonus_promocode: ' Código promocional:',
    profile_bonus_expires: 'Expira em',
    profile_btn_claim_winnings: 'Reivindique seus ganhos',
    profile_btn_bonus_cancel: 'Cancelar bônus',
    profile_btn_support: 'Entre em contato com o suporte',
    profile_close_account: 'Atenção! Para iniciar o encerramento da sua conta, por favor pressione o botão abaixo.',
    profile_btn_close_account: 'Fechar conta',
    profile_btn_log_out: 'Sair',

    // RegionBlocked.js
    region_blocked_title: 'Região bloqueada.',

    // ResetPassword.js
    reset_password_title: 'Redefina sua senha',
    reset_password_field_new_password: 'Nova senha',
    reset_password_field_new_password_error: 'Por favor, digite sua senha',
    reset_password_field_confirm_password: 'Confirmar sua senha',
    reset_password_field_confirm_password_error: 'Por favor, digite a sua senha novamente',
    reset_password_error: 'Ocorreu um erro. Por favor, tente seguir o link de redefinição novamente ou entre em contato com o suporte.',
    reset_password_error_match: 'As senhas não coincidem! Por favor, ajuste e tente novamente.',
    reset_password_cta: 'Redefina sua  senha',
    reset_password_redirect_text: "Redirecionando para a página inicial...",

    // VerifyEmail.js
    verify_email_success_title: "Sucesso! Seu e-mail foi verificado",
    verify_email_success_subtitle: "Agora você será redirecionado para a página de login...",
    verify_email_error_title: "Sua conta já foi verificada",
    verify_email_error_subtitle: <span>Sua conta já foi verificada. Se você estiver tendo problemas para fazer login, envie-nos um e-mail para <b>info@orozinosupport.com</b>.</span>,
    verify_email_error: "O token de verificação é inválido"
}