import { dbGetTheme } from './api-functions';
import { getLocationTheme } from '../../themes';

/**
 * Segment params are used to identify what segment does a particular user belongs to
 */
export default async function getTheme(params, location) {
    let themeName = params?.theme;

    /*******************/
    /** If there is no theme in the link or in the user, attempt to define theme */
    if (!themeName) {
        themeName = getLocationTheme(location);
    }

    /*******************/
    /** Attempt to get a name of the theme */
    if (themeName) {
        /******************/
        /** Get the theme id */
        try {
            const response = await dbGetTheme(themeName);
            const theme = response?.data?.docs[0];

            if (theme) {
                return {
                    id: theme.id,
                    name: theme.name,
                }
            };
        } catch (error) {
            console.log(`Error, dbGetTheme, getTheme, ${error}`)
        }
    }

    return {
        id: '',
        name: '',
    }
}