import { createSlice } from "@reduxjs/toolkit";
import { dbGetBonuses } from "../utilities/api-functions";
import overrideMinDepositBonus from "../utilities/override-min-deposit-bonus";
import submitError from "../utilities/submit-error";

export const bonuses = createSlice({
    name: "bonuses",
    initialState: {
        isLoading: true,
        list: {}
    },
    reducers: {
        updateBonuses: (state, action) => {
            Object.assign(state, { ...state, ...{ 
                isLoading: false,
                list: action.payload.list 
            } });
        }
    }
});

export const getBonusesAsync = (global, user) => async (dispatch) => {
    try {
        let bonuses = await dbGetBonuses(global?.language);
        let newBonusList = {};

        /** Creating an object out bonus array */
        bonuses?.data?.docs?.forEach(bonus => {
            newBonusList[bonus.promoCode] = bonus;
        });

        /** Updating the minimum deposit amount on bonuses */
        if (user?.depositMinAmount > 0 && 
            user?.depositsMade === 0 &&
            !global?.location?.vpn && 
            global?.segment?.countries?.includes(global?.location?.country))
            // Array(user?.country?.name, global?.location?.country).some(country => country && global?.traffic?.countries?.includes(country))) 
        {
            newBonusList = overrideMinDepositBonus(newBonusList, user?.depositMinAmount);
        }
        
        /** dispatching the event */
        dispatch(updateBonuses({
            list: newBonusList 
        }));
    } catch (error) {
        submitError('ERROR', `getBonusesAsync, ${error}`);
    }
}

// Action creators are generated for each case reducer function
export const {
    updateBonuses
} = bonuses.actions;

export default bonuses.reducer;