import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dbCloseAccount, dbLogout } from '../utilities/api-functions';
import { Button, Modal } from 'react-bootstrap';

/** Utilities */
import useText from "../utilities/use-text";

export default function ModalCloseAccount({ show, handleClose, }) {
    const navigate = useNavigate();
    const text = useText();

    async function closeAccount() {
        try {
            await dbCloseAccount();
            await dbLogout();
            navigate(0);
        } catch (error) {
            console.log(`Error, Close account, ${error}`)
        }
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton >
                {text('close_account_title')}
            </Modal.Header>
            <Modal.Body className='text-center'>
                <p>
                    {text('close_account_text')}
                </p>
                <Button className="btn-alert" onClick={closeAccount}>
                    {text('close_account_cta')}
                </Button>
            </Modal.Body>
        </Modal>
    )
}