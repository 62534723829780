export const fr = {

    // ClaimWinningsAllowed.js
    claim_winnings_allowed_message: 'We are currently reviewing your winnings and will contact you asap.',

    // WithdrawAllowed.js
    withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>You currently have an active withdrawal for {sign}{activeWithdrawal?.amount}. Please wait while we process to request your next withdrawal.</span>,

    // BonusRowCategory.js
    bonus_row_title: "Choisissez votre premier bonus",
    bonus_row_title_2: "Choisissez votre prochain bonus",

    // BonusTile.js
    bonus_tile_cta: 'Réclamer maintenant',

    // BonusTimer.js
    bonus_timer_expired: "Expire aujourd'hui!",

    // Deposit.js
    deposit_btn_back_to_amount: "Retour au montant du dépôt",
    deposit_btn_back_to_payment_options: "Retour aux options de paiement",

    // DepositAmountSection.js
    deposit_amount_title: "Montant",
    deposit_amount_cta: "Déposer",
    deposit_amount_field: "Montant du dépôt",
    deposit_amount_field_label: (sign) => {
        return `Montant du dépôt (${sign})`
    },
    deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `Le montant du dépôt doit être compris entre ${sign}${depositMinAmount} et ${sign}${depositMaxAmount}.`
    },
    deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `Le montant du dépôt pour le bonus sélectionné doit être compris entre ${sign}${depositMinAmount} et ${sign}${depositMaxAmount}.`
    },

    // DepositBonusCard.js
    deposit_bonus_card_min_deposit: "Dépôt minimum:",

    // DepositBonusSection.js
    deposit_bonus_section_title: "Choisissez votre bonus",
    deposit_bonus_section_title_selected: "Bonus sélectionné:",

    // DepositPaymentMethod.js
    deposit_payment_method_title: "Choisissez votre méthode de paiement",
    deposit_payment_method_bonus: "Bonus supplémentaire de 15 %",

    // DepositRedirect.js
    deposit_redirect_title: "Complétez votre paiement",
    deposit_redirect_cta: "Déposer",
    deposit_redirect_cta_processing: "Traitement en cours",
    deposit_redirect_success_title: "Presque fini!",
    deposit_redirect_success_text: "Cliquez ci-dessous pour recharger et commencer à jouer.",
    deposit_redirect_success_cta: "Complétez votre paiement",
    deposit_redirect_error: (error) => {
        return `${error ? error : 'Une erreur est survenue.'} Veuillez réessayer ou choisir un autre mode de paiement`;
    },
    deposit_redirect_field_first_name: 'Prénom',
    deposit_redirect_field_last_name: 'Nom',
    deposit_redirect_field_email: 'E-mail',
    deposit_redirect_field_phone: 'Téléphone',
    deposit_redirect_field_address: 'Adresse',
    deposit_redirect_field_city: 'Ville',
    deposit_redirect_field_state: 'Province/État',
    deposit_redirect_field_zip: 'Code postal',

    // DepositSummary.js
    deposit_summary_amount: "Montant:",
    deposit_summary_bonus: "Bonus:",
    deposit_summary_no_bonus: "Aucun bonus sélectionné.",

    // DepositToggle.js
    deposit_toggle_btn_deposit: "Déposer",
    deposit_toggle_btn_withdraw: "Retirer",

    // FreespinsRow.js
    freespin_row_title: ({ number, game }) => <>Vous avez <span className="">{number} tours gratuits</span> dans <span className="">{game}</span>!</>,
    freespin_row_cta: "Jouer maintenant",

    // GameRowJackpotTypes.js
    jackpot_types_daily: 'Jackpot quotidien',
    jackpot_types_hourly: 'Jackpot horaire',
    jackpot_types_drops_in: 'Tombe au cours de',

    // GameSearchField.js
    game_search_input_placeholder: "Saisir le jeu ou le fournisseur",
    game_search_input_hint: "Rechercher",

    // IdleTimer.js
    idle_timer_title: "Session expirée",
    idle_timer_text: "Vous avez été inactif. Veuillez vous connecter à nouveau.",
    idle_timer_cta: 'Se connecter',

    // ModalClaimBonusWinnings.js
    claim_bonus_winnings_title: 'Réclamez vos gains',
    claim_bonus_winnings_processing: 'Traitement en cours...',
    claim_bonus_winnings_exceeded: 'Votre demande a été envoyée pour approbation. Nous vous répondrons dans les plus brefs délais.',
    claim_bonus_winnings_error: "Une erreur est survenue. Veuillez réessayer ou contacter l'assistance.",

    // ModalCloseAccount.js
    close_account_title: 'Fermer le compte',
    close_account_text: <span>Êtes-vous sûr de vouloir supprimer votre compte?<br /><br /><i>Afin de mettre fin à votre auto-exclusion, connectez-vous avec votre e-mail et votre mot de passe et suivez les instructions supplémentaires.</i></span>,
    close_account_cta: 'Oui, fermer mon compte',

    // ModalOpenAccount.js
    open_account_title: 'Compte fermé',
    open_account_text: 'Votre compte est actuellement fermé. Souhaitez-vous ouvrir votre compte?',
    open_account_cta: 'Oui, veuillez bien ouvrir mon compte',

    // Navigation.js
    navigation_btn_home: "Accueil",
    navigation_btn_games: "Jeux",
    nav_button_help: "Aide",
    navigation_btn_log_in: "Se connecter",
    navigation_btn_register: "S'inscrire",
    navigation_balance: "Solde",

    // PhoneVerification.js
    phone_verification_log_out_title: (number) => <>Get <span className="verify-phone-title-spins">{number}</span> sans dépôt&nbsp;requis!</>,
    phone_verification_log_out_text: (number) => `Durée limitée seulement! Recevez ${number} après avoir terminé votre inscription.`,
    phone_verification_log_in_title: (number) => <>Vérifiez votre téléphone et obtenez <span className="verify-phone-title-spins">{number}</span>!</>,
    phone_verification_field_code_title: 'Saisir le code de confirmation',
    phone_verification_field_phone_label: 'Saisir votre téléphone',
    phone_verification_field_code_label: 'Code',
    phone_verification_btn_claim: 'Réclamer maintenant',
    phone_verification_btn_verify_claim: 'Vérifier et réclamer',
    phone_verification_btn_confirm: 'Confirmer',
    phone_verification_btn_try_again: ' Réessayer',
    phone_verification_confirmation_title: "Veuillez vérifier votre téléphone. Une fois confirmé, vous ne pourrez plus le modifier vous-même",
    phone_verification_error_phone_already_verified: 'Ce téléphone a déjà été vérifié.',
    phone_verification_error_wrong_phone: ' Ce téléphone ne correspond pas à celui de votre profil.',
    phone_verification_error_active_bonus: "Vous disposez déjà d'un bonus. Veuillez actualiser la page.",
    phone_verification_error_general: 'Une erreur est survenue. Veuillez vérifier votre téléphone et réessayer.',

    // ProfilePlayerInformation.js
    profile_information_title: 'Données de facturation',
    profile_information_btn_save: 'Enregistrer',
    profile_information_change_phone: "À noter: si vous souhaitez modifier votre numéro, veuillez contacter l'assistance.",
    profile_information_field_first_name: 'Prénom',
    profile_information_field_last_name: 'Nom',
    profile_information_field_email: 'E-mail',
    profile_information_field_phone: 'Téléphone',
    profile_information_field_address: 'Adresse',
    profile_information_field_city: 'Ville',
    profile_information_field_state: 'Province/Région',
    profile_information_field_zip: 'Code postal',
    profile_information_field_dob: 'Date de naissance',

    // RegistrationForm.js
    registration_form_email_label: "Adresse e-mail",
    registration_form_or: 'OR',
    registration_form_email_placeholder: "name@example.com",
    registration_form_password_label: "Mot de passe",
    registration_form_key_hint: "S'inscrire",
    registration_form_terms: "J'accepte les termes et conditions.",
    registration_form_btn_cta: "S'inscrire maintenant",
    registration_form_error_email: "Veuillez fournir une adresse e-mail valide.",
    registration_form_error_password: "Le mot de passe doit comporter au moins 7 caractères ",
    registration_form_error_terms: "Veuillez accepter nos termes et conditions pour finaliser votre inscription ",
    registration_form_error_general: "Une erreur est survenue. Veuillez réessayer.",
    error_turnstile: "Veuillez compléter le défi CAPTCHA.",
    registration_form_error_email_exists: <span>Cet e-mail existe déjà.<br />Essayez peut-être <a href="?login">logging in</a>?</span>,

    // WeeklyBonus.js
    weekly_bonus_cta: 'Réclamer maintenant',

    // Withdraw.js
    withdraw_balance: ' Solde retirable',
    withdraw_bonus_balance: 'Solde bonus',
    withdraw_wager_progress: 'Progression des mises',
    withdraw_choose_amount: 'Saisir le montant à retirer',
    withdraw_choose_amount_label: (sign) => `Montant (${sign})`,
    withdraw_error_insufficient_funds: "Fonds insuffisants",
    withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => `Fonds insuffisants. Le montant minimum du retrait est de ${sign}${minAmount}`,
    withdraw_error_wrong_amount_limit_min_max: ({ sign, maxAmount }) => `Le montant maximum du retrait est de ${sign}${maxAmount}`,
    withdraw_btn_back_to_payment_options: 'Retour aux options de retrait',

    // WithdrawMethod.js
    withdraw_method_interac_legal: "®Marque de commerce d'Interac Corp. Utilisée sous licence.",
    withdraw_method_choose_amount: 'Veuillez saisir le montant pour continuer',

    // WithdrawRedirect.js
    withdraw_redirect_title: 'Terminez votre retrait',
    withdraw_redirect_cta: 'Retirer',
    withdraw_redirect_cta_processing: 'Traitement en cours...',
    withdraw_redirect_success_title: 'Succès!',
    withdraw_redirect_success_text_1: 'Votre demande de retrait est en cours de traitement et devrait être complétée dans un délai de 1 à 5 jours ouvrables, selon la méthode de retrait.',
    withdraw_redirect_success_text_2: 'Dès que votre retrait sera traité, le montant choisi sera déduit du solde de votre compte.',
    withdraw_redirect_error: (error) => {
        return `${error ? error : 'Une erreur est survenue.'} Veuillez réessayer ou choisir une autre méthode de retrait.`;
    },
    withdraw_redirect_field_first_name: 'Prénom',
    withdraw_redirect_field_last_name: 'Nom',
    withdraw_redirect_field_email: 'E-mail',
    withdraw_redirect_field_phone: 'Téléphone',
    withdraw_redirect_field_address: 'Adresse',
    withdraw_redirect_field_city: 'Ville',
    withdraw_redirect_field_state: 'Province/Région',
    withdraw_redirect_field_zip: 'Code postal',
    withdraw_redirect_field_card_number: 'Numéro de carte',
    withdraw_redirect_field_exp_month: "Mois d'expiration (MM)",
    withdraw_redirect_field_exp_year: "Année d'expiration (AA)",

    // WithdrawSummary.js
    withdrawal_summary_amount: "Montant:",

    // ForgotPassword.js
    forgot_password_title: 'Restaurez votre mot de passe',
    forgot_password_field_email: "Saisissez votre e-mail d'inscription",
    forgot_password_text_1: "Vérifiez votre boîte de réception et votre dossier spam.",
    forgot_password_text_2: "Si vous ne recevez pas d'e-mail dans les 30 minutes, veuillez réessayer de soumettre ce formulaire ou contacter l'assistance.",
    forgot_password_btn_close: "Fermer",
    forgot_password_btn_submit: "Soumettre",

    // Login.js
    login_forgot_password_cta: "Mot de passe oublié?",
    login_create_account: "Vous n'avez pas encore de compte?",
    login_create_account_cta: "S'inscrire",
    login_btn_close: "Fermer",
    login_cta: "S'identifier",

    // Profile.js
    profile_title: 'Profil client',
    profile_balance: 'Solde total:',
    profile_balance_withdrawable: 'Solde retirable:',
    profile_balance_bonus: 'Solde bonus:',
    profile_wager_progress: 'Progression des mises:',
    profile_bonus_promocode: 'Code promotionnel:',
    profile_bonus_expires: 'Expire le',
    profile_btn_claim_winnings: 'Réclamez vos gains',
    profile_btn_bonus_cancel: 'Annuler le bonus',
    profile_btn_support: "Contacter l'assistance",
    profile_close_account: 'Envie de faire une pause ?',
    profile_btn_close_account: 'Fermer le compte',
    profile_btn_log_out: 'Se déconnecter',

    // RegionBlocked.js
    region_blocked_title: 'Région bloquée.',

    // ResetPassword.js
    reset_password_title: 'Réinitialisez votre mot de passe',
    reset_password_field_new_password: 'Nouveau mot de passe',
    reset_password_field_new_password_error: 'Veuillez saisir votre mot de passe',
    reset_password_field_confirm_password: 'Confirmer le mot de passe',
    reset_password_field_confirm_password_error: 'Veuillez saisir à nouveau votre mot de passe',
    reset_password_error: "Une erreur est survenue. Veuillez essayer de suivre à nouveau le lien de réinitialisation ou de contacter l'assistance.",
    reset_password_error_match: 'Les mots de passe ne correspondent pas! Veuillez ajuster et réessayer.',
    reset_password_cta: 'Réinitialiser le mot de passe',
    reset_password_redirect_text: "Redirection vers la page d'accueil..."
}