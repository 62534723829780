export default function getDeviceInformation() {
    let isIframe = false;
    let isPWA = false;
    let isWebview = false;
    let isApp = false;

    /*********************/
    /** Check for PWA */
    try {
        if (window?.navigator?.standalone || window?.matchMedia("(display-mode: standalone)")?.matches) {
            isPWA = true;
        }
    } catch (error) {
        console.log(`Error, getDeviceInformation, PWA check, ${error}`);
    }

    /*********************/
    /** Check for iFrame */
    try {
        if (window?.self !== window?.top) {
            isIframe = true;
        }
    } catch (error) {
        console.log(`Error, getDeviceInformation, iFrame check, ${error}`);
    }

    /*********************/
    /** Check for Webview */
    let standalone = window.navigator.standalone;
    let userAgent = window.navigator.userAgent.toLowerCase();
    let safari = /safari/.test(userAgent);
    let ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        if (!standalone && safari) {
            // Safari
        } else if (!standalone && !safari) {
            // iOS webview
            isWebview = true;
        };
    } else {
        if (userAgent.includes('wv')) {
            // Android webview
            isWebview = true;
        } else {
            // Chrome
        }
    };

    /*********************/
    /** If one of the parameteres is true, isApp is true */
    isApp = isIframe || isPWA || isWebview;

    /*********************/
    /** Result */
    return {
        isApp,
        isIframe,
        isPWA,
        isWebview
    }
}