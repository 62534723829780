import { dbGetSegment } from './api-functions';

/** Metadata */
import metadata from '../../metadata';

/**
 * Segment params are used to identify if a particular traffic source has a specifically set min dep
 * @param {object} user 
 * @returns 
 */

export default async function getSegment(user) {
    /*******************/
    /** Logged out */
    let params;

    if (Object.keys(user)?.length === 0) {

        /*******************/
        /** Get/Set parameters */
        params = getActiveParams();

        /*******************/
        /** Return if there is source parameter */
        if (params?.source) {

            /******************/
            /** Get segment via parameters */
            try {
                const response = await dbGetSegment([params.source]);
                const segment = response?.data?.docs[0];

                if (segment) {
                    return {
                        id: segment.id,
                        params: params,
                        depositMinAmount: user?.depositMinAmount || segment.value || 0,
                        countries: segment.countries || ''
                    }
                };
            } catch (error) {
                console.log(`Error, getSegment, dbGetSegment, ${error}`)
            }
        }

        return {
            id: '',
            params: params,
            depositMinAmount: 0,
            countries: ''
        }
    }

    /*******************/
    /** Logged in */
    if (Object.keys(user)?.length > 0) {

        /*******************/
        /** Attempt to parse user parameters */
        try {
            params = JSON.parse(user?.params);
        }
        catch (error) {
            params = {};
        }

        /*******************/
        /** Return if there is source parameter */
        if (params?.source) {

            /******************/
            /** Get segment via parameters */
            try {
                const response = await dbGetSegment([params.source]);
                const segment = response?.data?.docs[0];

                if (segment) {
                    return {
                        id: segment.id,
                        params: params,
                        depositMinAmount: user?.depositMinAmount || segment.value || 0,
                        countries: segment.countries || ''
                    }
                };
            } catch (error) {
                console.log(`Error, getSegment, dbGetSegment, ${error}`)
            }
        }

        return {
            id: '',
            params: params,
            depositMinAmount: 0,
            countries: ''
        }
    }
}

function getActiveParams() {
    try {
        /*************************/
        /** Check if there is a brand specific parameter id */
        if (!metadata?.brandParamsId) {
            console.log('Error, no brandParamsId');
            return {};
        }

        /*************************/
        /** Get parameters from URL */
        const urlParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlParams);

        /*************************/
        /** Add URL params to cookie */
        if (Object.keys(params).length > 0) {
            // window.localStorage.setItem(`${metadata?.brandParamsId}_params`, JSON.stringify(params));

            let cookie = `${metadata?.brandParamsId}_params` + "=" + encodeURIComponent(JSON.stringify(params));
            let days = 30;
            cookie += "; max-age=" + (days * 24 * 60 * 60);
            document.cookie = cookie + ";path=/";
        }

        /*************************/
        /** If there are no params in the url, get params from coockie */
        if (Object.keys(params).length === 0) {
            let stringParams = '{}';
            let cookieArr = document.cookie.split(";");

            for (let i = 0; i < cookieArr.length; i++) {
                let cookiePair = cookieArr[i].split("=");
                if (`${metadata?.brandParamsId}_params` == cookiePair[0].trim()) {
                    stringParams = decodeURIComponent(cookiePair[1].trim());
                }
            }

            //let stringParams = window.localStorage.getItem(`${metadata?.brandParamsId}_params`) || '{}';
            params = JSON.parse(stringParams);
        }

        /*************************/
        /** Response */
        return params;
    } catch (error) {
        console.log(`Error, getActiveParamsJSONString, could not get params, ${error}`);
        return {};
    }
}