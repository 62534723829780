import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function WithdrawMethod({ choosePaymentMethod, isEnabled }) {
    const user = useSelector((state) => state.user.data)
    const text = useText();

    return (
        <div className="withdraw-method">
            {
                !isEnabled && (
                    <p className="font-mid text-center">{text('withdraw_method_choose_amount')}</p>
                )
            }
            {
                {
                    'CAD': <PaymentMethods_CAD {...{ choosePaymentMethod, isEnabled }} />,
                    'USD': <PaymentMethods_USD {...{ choosePaymentMethod, isEnabled }} />,
                    'EUR': <PaymentMethods_EUR {...{ choosePaymentMethod, isEnabled }} />,
                    'NZD': <PaymentMethods_NZD {...{ choosePaymentMethod, isEnabled }} />,
                    'BRL': <PaymentMethods_BRL {...{ choosePaymentMethod, isEnabled }} />,
                    'PEN': <PaymentMethods_PEN {...{ choosePaymentMethod, isEnabled }} />,
                }[user?.currency?.code]
            }
        </div>
    )
}

function PaymentMethods_CAD({ choosePaymentMethod, isEnabled }) {
    const text = useText();

    return (
        <>
            <Button onClick={() => { choosePaymentMethod('interac_etransfer_cad') }}
                className="btn-secondary btn-choose-payment interac"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/interac-logo-logo.png" alt="Interac" />
                    <span>Interac® e-Transfer</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa/Mastercard" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}

            <Crypto {...{ choosePaymentMethod, isEnabled }} />

            <p style={{ textAlign: "left", fontSize: "12px" }} className="font-low">{text('withdraw_method_interac_legal')}</p>
        </>
    )
}

function PaymentMethods_USD({ choosePaymentMethod, isEnabled }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa/Mastercard" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Crypto {...{ choosePaymentMethod, isEnabled }} /> */}
        </>
    )
}

function PaymentMethods_EUR({ choosePaymentMethod, isEnabled }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa/Mastercard" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Crypto {...{ choosePaymentMethod, isEnabled }} />
        </>
    )
}

function PaymentMethods_NZD({ choosePaymentMethod, isEnabled }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa/Mastercard" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Crypto {...{ choosePaymentMethod, isEnabled }} /> */}
        </>
    )
}

function PaymentMethods_BRL({ choosePaymentMethod, isEnabled }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('pix_brl') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/orozino-test/pix.png" alt="Visa/Mastercard" />
                    <span>Pix</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa/Mastercard" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}

            <Crypto {...{ choosePaymentMethod, isEnabled }} />
        </>
    )
}

function PaymentMethods_PEN({ choosePaymentMethod, isEnabled }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa/Mastercard" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Crypto {...{ choosePaymentMethod, isEnabled }} /> */}
        </>
    )
}

/********************/
/** Crypto options */
function Crypto({ choosePaymentMethod, isEnabled }) {
    const [show, setShow] = useState(false);

    return (
        <>
            {/* <Button onClick={() => { setShow(true) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={!isEnabled}
            >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/coinspaid-logo.png" alt="Coinspaid" />
                    <span>Crypto</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Modal show={show} onHide={() => { setShow(false) }} centered>
                <Modal.Header closeButton >
                    <span className="subtitle font-mid">Choose your currency</span>
                </Modal.Header>
                <Modal.Body className='text-center pt-0'> */}
                    <Button onClick={() => { setShow(false); choosePaymentMethod('bitcoin_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin.png" alt="Bitcoin" />
                            <span>Bitcoin</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                    <Button onClick={() => { setShow(false); choosePaymentMethod('bitcoin_cash_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin-cash.png" alt="Bitcoin Cash" />
                            <span>Bitcoin Cash</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                    <Button onClick={() => { setShow(false); choosePaymentMethod('tether_trc20_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether-trc20.png" alt="Tether TRC20" />
                            <span>Tether TRC20</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                    <Button onClick={() => { setShow(false); choosePaymentMethod('tether_erc20_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether-erc20.png" alt="Tether ERC20" />
                            <span>Tether ERC20</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                    <Button onClick={() => { setShow(false); choosePaymentMethod('ethereum_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-etherium.png" alt="Ethereum" />
                            <span>Ethereum</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                    <Button onClick={() => { setShow(false); choosePaymentMethod('ripple_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-ripple.png" alt="Ripple" />
                            <span>Ripple</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                    <Button onClick={() => { setShow(false); choosePaymentMethod('litecoin_eur'); }}
                        className="btn-secondary btn-choose-payment coinspaid"
                        disabled={!isEnabled}
                    >
                        <div>
                            <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-litecoin.png" alt="Litecoin" />
                            <span>Litecoin</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>

                {/* </Modal.Body>
            </Modal> */}
        </>
    )
}