import React from "react";
import { useSelector } from 'react-redux';

/** Components */
import GameRowCategory from './GameRowCategory';
import GameRowDoubleCategory from "./GameRowDoubleCategory";
import GameRowJackpotCategory from './GameRowJackpotCategory';

export default function GameListOfRows({ categoryURL }) {
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? categories[categoryURL] : null;

    return (
        <section className="game-list-rows-section" id={`game-category-${categoryURL}`}>
            <span id={`game-category-anchor-${categoryURL}`}></span>
            <div className="game-list-rows-container">
                {
                    category?.categories && category.categories.map((subcategory, index) => {

                        if (subcategory?.url?.includes('jackpot-category')) {
                            return <GameRowJackpotCategory categoryURL={subcategory.url} key={`${index}${subcategory.id}`} />
                        }

                        if (subcategory?.url?.includes('table-games')) {
                            return <GameRowDoubleCategory categoryURL={subcategory.url} key={`${index}${subcategory.id}`} />
                        }

                        /** By default return a generic category */
                        return <GameRowCategory categoryURL={subcategory.url} key={`${index}${subcategory.id}`} />
                    })
                }
            </div>
        </section>
    );
}