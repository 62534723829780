import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Modal, Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { dbLogin } from "../utilities/api-functions";
import { useGoogleLogin } from '@react-oauth/google';
import { Helmet } from "react-helmet-async";
import getPasswordHash from "../utilities/get-password-hash";
import Axios from 'axios';

/** Components */
import ErrorHandler from '../components/ErrorHandler';

/** Brand Components */
import GoogleButton from "../../components/GoogleButton";

/** Utilities */
import useText from "../utilities/use-text";

/** Meta */
import metadata from '../../metadata';

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const isWebview = useSelector((state) => state?.global?.data?.device?.isWebview);
    const text = useText();

    /** State */
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [btnLoaderGoogle, setBtnLoaderGoogle] = useState(false);

    const handleClose = () => { navigate(location.pathname); };

    const handleLogin = (event) => {
        event.preventDefault();
        login();
    }

    /********************/
    /********************/
    /** Google Login */
    const googleGoogleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => googleLogin(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    async function googleLogin(credentials) {
        try {
            const result = await Axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentials.access_token}`, {
                headers: {
                    Authorization: `Bearer ${credentials.access_token}`,
                    Accept: 'application/json'
                }
            })

            if (result?.data?.email) {
                setBtnLoaderGoogle(true);

                /*******************************/
                /** Create password */
                const gEmail = result?.data?.email;
                const gPassword = getPasswordHash(result?.data?.email);

                const response = await dbLogin(gEmail, gPassword);

                if (response?.data?.user && response?.data?.user?.isActive) {
                    let url = window.location.origin;
                    if (location.state?.forwardLocation?.pathname) url += location.state.forwardLocation.pathname;
                    if (location.state?.forwardLocation?.search) url += location.state.forwardLocation.search;
                    if (location.state?.forwardLocation?.hash) url += location.state.forwardLocation.hash;

                    window.location.replace(url);
                    return;
                };

                setErrors([...response.errors]);
                setBtnLoaderGoogle(false);
            }
        } catch (error) {
            console.log(`Error, googleLogin, ${error}`);
        }
    };

    /********************/
    /********************/
    /** CMS Login */
    const login = async () => {
        setBtnLoader(true);

        try {
            const response = await dbLogin(email, password);

            if (response?.data?.user && response?.data?.user?.isActive) {
                let url = window.location.origin;
                if (location.state?.forwardLocation?.pathname) url += location.state.forwardLocation.pathname;
                if (location.state?.forwardLocation?.search) url += location.state.forwardLocation.search;
                if (location.state?.forwardLocation?.hash) url += location.state.forwardLocation.hash;

                window.location.replace(url);
                return;
            }

            setErrors([...response.errors]);
            setBtnLoader(false);
        } catch (error) {
            setErrors([...error.response.data.errors]);
            setBtnLoader(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{text('page_login')}</title>
            </Helmet>
            <Modal show={true} onHide={handleClose} className="login-container">

                <Modal.Header style={{ flexDirection: 'column', border: 'none', paddingTop: '10px', paddingBottom: '5px' }}>
                    {/* <img src={metadata?.logos?.main} width="100px" alt={metadata.brand_name} /> */}
                </Modal.Header>

                <Modal.Body>
                    <ErrorHandler errors={errors} setErrors={setErrors} />

                    {
                        !isWebview && (
                            <div className="text-center">
                                <GoogleButton {...{ googleGoogleLogin, btnLoader: btnLoaderGoogle }} />
                                <div className="registration-method-separator">{text('registration_form_or')}</div>
                            </div>
                        )
                    }

                    <form onSubmit={handleLogin}>
                        <FloatingLabel controlId="loginInput" label={text('registration_form_email_label')} className="mb-2">
                            <Form.Control type="email" placeholder={text('registration_form_email_placeholder')} value={email} onChange={e => setEmail(e.target.value)} enterKeyHint="Go" />
                        </FloatingLabel>

                        <FloatingLabel controlId="loginPassword" label={text('registration_form_password_label')} className="mb-3">
                            <Form.Control type="password" placeholder={text('registration_form_password_label')} value={password} onChange={e => setPassword(e.target.value)} enterKeyHint="Go" />
                        </FloatingLabel>

                        <input type="submit" data-role="none" style={{ visibility: 'hidden', position: 'absolute' }} />
                    </form>

                    <p className="mb-2 font-mid">
                        {text('login_create_account')}&nbsp;
                        <Link to={`?register`} state={{ forwardLocation: location.state?.forwardLocation }}>{text('login_create_account_cta')}</Link>
                    </p>

                    <p className="mb-0">
                        <Link to={`?forgot-password`}>
                            {text('login_forgot_password_cta')}
                        </Link>
                    </p>


                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-secondary ps-4 pe-4' onClick={handleClose}>
                        {text('login_btn_close')}
                    </Button>
                    <Button className='btn-main ps-4 pe-4' onClick={login} disabled={btnLoader}>
                        {
                            !btnLoader ? text('login_cta') : (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    &nbsp;&nbsp;
                                    {text('login_cta')}
                                </>

                            )
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}