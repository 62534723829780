import React, { useState } from "react";
import { Toast, ToastContainer } from 'react-bootstrap';

import metadata from "../../metadata.json"

export default function Notification({ text, showNotification }) {
    const [show, setShow] = useState(true);

    return (
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 10000, position: "fixed" }}>
            <Toast className="notification-timer" show={showNotification && show} onClose={() => setShow(false)} delay={5000} autohide>
                <Toast.Header closeButton={false}>
                    <img src={metadata?.logos?.main}
                        className="rounded me-auto"
                        alt=""
                        style={{ width: "50px" }}
                    />
                </Toast.Header>
                <Toast.Body>{text}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}