import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux';

/** Theme */
import themes from "../../themes";

/** Components */
import DepositBonusSection from './DepositBonusSection';
import DepoositAmountSection from './DepositAmountSection';
import DepositRedirect from './DepositRedirect';
import DepositButtonBack from "./DepositButtonBack";
import CashierScrollTop from "./CashierScrollTop";

/** Brand Components */
import DepositMethod from "../../components/DepositMethod";

/** Utilities */
import useText from "../utilities/use-text";

export default function Deposit() {
    const user = useSelector((state) => state.user.data);
    const theme = useSelector((state) => state.global.data.theme?.name);
    const segment = useSelector((state) => state.global.data.segment);
    const location = useSelector((state) => state.global.data.location);
    const globalLimits = useSelector((state) => state.global.data.limits);
    const currencyLimits = globalLimits.filter(item => item.currency === user?.currency?.id)[0] || null;
    const presetAmounts = currencyLimits?.presetAmounts?.split(',');
    const text = useText();

    const step1 = useRef(null);
    const step2 = useRef(null);
    const step3 = useRef(null);
    const container = useRef(null);

    const [step, setStep] = useState(1);
    const [amount, setAmount] = useState(getAmount());
    const [paymentMethod, setPaymentMethod] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [limits, setLimits] = useState({
        depositMinAmount: currencyLimits?.depositMinAmount,
        depositMaxAmount: currencyLimits?.depositMaxAmount
    });


    function getAmount() {
        if (!location?.vpn &&
            user?.depositsMade === 0 &&
            user?.depositMinAmount > 0 &&
            segment?.countries?.includes(location?.country)
        ) {
            return parseFloat(presetAmounts[1]) + (user?.depositMinAmount / 2 || 0)
        }

        return parseFloat(presetAmounts[1]);
    }

    function setGlobalLimits() {
        setLimits({
            depositMinAmount: currencyLimits?.depositMinAmount,
            depositMaxAmount: currencyLimits?.depositMaxAmount
        })
    }

    function transitionToStep(nextStep) {
        if (nextStep === 1) {
            step2.current.classList.add("animate__fadeOutRight");
            setTimeout(() => {
                setStep(nextStep);
                step1.current.classList.remove("animate__fadeOutLeft");
                step1.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (nextStep === 2) {
            if (step === 1) {
                step1.current.classList.add("animate__fadeOutLeft");
            }

            if (step === 3) {
                step3.current.classList.add("animate__fadeOutRight");
            }

            setTimeout(() => {
                setStep(nextStep);
                step2.current.classList.remove("animate__fadeOutRight");
                step2.current.classList.remove("animate__fadeOutLeft");
                step2.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (nextStep === 3) {
            step2.current.classList.add("animate__fadeOutLeft");

            setTimeout(() => {
                setStep(nextStep);
                step3.current.classList.remove("animate__fadeOutRight");
                step3.current.classList.remove("animate__fadeOutLeft");
                step3.current.classList.add("animate__fadeIn");
            }, 300);
        }
    }

    return (
        <div className="deposit-container" ref={container}>
            <CashierScrollTop {...{ step, container }} />
            <div ref={step1} className="animate__animated animate__faster">
                {step === 1 && (
                    <div className="deposit-section">
                        {
                            /** Deposit banner based on a theme */
                            themes(theme, "deposit_banner")
                        }

                        {/* Bonus section */}
                        <DepositBonusSection {...{
                            promoCode,
                            setPromoCode,
                            setGlobalLimits,
                            setLimits
                        }} />

                        {/* Amount section */}
                        <DepoositAmountSection
                            amount={amount}
                            setAmount={setAmount}
                            promoCode={promoCode}
                            setPromoCode={setPromoCode}
                            depositMinAmount={limits.depositMinAmount}
                            depositMaxAmount={limits.depositMaxAmount}
                            transitionToStep={transitionToStep}
                        />
                    </div>
                )}
            </div>

            <div ref={step2} className="animate__animated animate__faster">
                {step === 2 && (
                    <div className="deposit-section">
                        <DepositMethod setPaymentMethod={setPaymentMethod} transitionToStep={transitionToStep} amount={amount} />
                        <DepositButtonBack toPreviousStep={() => { transitionToStep(1) }} copy={text('deposit_btn_back_to_amount')} />
                    </div>
                )}
            </div>


            <div ref={step3} className="animate__animated animate__faster">
                {step === 3 && (
                    <div className="deposit-section">
                        <DepositRedirect paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />
                        <DepositButtonBack toPreviousStep={() => { transitionToStep(2) }} copy={text('deposit_btn_back_to_payment_options')} />
                    </div>
                )}
            </div>
        </div>
    )
}