import { useSelector } from 'react-redux';

/** Shared copy */
import { en } from '../assets/translations/english';
import { es } from '../assets/translations/spanish';
import { pt } from '../assets/translations/portuguese';
import { fr } from '../assets/translations/french';

/** Brand copy */
import { en_brand } from '../../assets/translations/english';
import { es_brand } from '../../assets/translations/spanish';
import { pt_brand } from '../../assets/translations/portuguese';
import { fr_brand } from '../../assets/translations/french';

/** Metadata */
import metadata from '../../metadata';

export default function useText() {
    const language = useSelector((state) => state?.global?.data?.language);
    const messages = {
        "en": { ...en, ...en_brand },
        "fr": { ...fr, ...fr_brand },
        "es": { ...es, ...es_brand },
        "pt": { ...pt, ...pt_brand }
    }

    return function (key, props) {
        try {
            const value = messages[language][key];

            if (!value) {
                throw new Error(`copy missing, ${language} : ${key}`);
            }

            if (typeof value === 'function') {
                return messages[language][key](props)
            }

            return messages[language][key]
        } catch (error) {
            console.log(`useText, ${error}`);
        }

        try {
            const defaultLanguage = metadata?.default_language;
            const value = messages[defaultLanguage][key];
            
            if (!value) {
                throw new Error(`copy missing, ${defaultLanguage} : ${key}`);
            }

            if (typeof messages[defaultLanguage][key] === 'function') {
                return messages[defaultLanguage][key](props)
            }

            return messages[defaultLanguage][key]
        } catch (error) {
            console.log(`useText, en : ${key}`);
            return '';
        }
    };
}