import React from "react";

/** Brand Components */
import { TagNew, TagHot, TagLive, TagLiveRight } from '../../components/GameTags';

export default function GameTileTags({ tags, isRightTag }) {
    
    if (tags.toLowerCase().includes('tag_new')) return <TagNew />;

    if (tags.toLowerCase().includes('tag_hot')) return <TagHot />;

    if (tags.toLowerCase().includes('tag_live') && isRightTag) return <TagLiveRight />;

    if (tags.toLowerCase().includes('tag_live')) return <TagLive />;
    
    return null;
}