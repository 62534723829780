import React from "react";
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet-async";

/** Theme */
import themes from "../themes";

/** Components Shared*/
import { BackgroundGradientHome } from "../spinsoft-core-client-subtree/components/BackgroundGradient";
import GameRowSpotlightCategory from "../spinsoft-core-client-subtree/components/GameRowSpotlightCategory";
import PhoneVerification from "../spinsoft-core-client-subtree/components/PhoneVerification";
import WeeklyBonus from "../spinsoft-core-client-subtree/components/WeeklyBonus";
import BonusRowCategory from "../spinsoft-core-client-subtree/components/BonusRowCategory";
import GameRowJackpotCategory from "../spinsoft-core-client-subtree/components/GameRowJackpotCategory";
import GameRowCategory from "../spinsoft-core-client-subtree/components/GameRowCategory";
import GameRowColumnCategory from "../spinsoft-core-client-subtree/components/GameRowColumnCategory";
import GameRowDoubleCategory from "../spinsoft-core-client-subtree/components/GameRowDoubleCategory";
import GameRowLastPlayed from "../spinsoft-core-client-subtree/components/GameRowLastPlayed";
import FreespinsRow from "../spinsoft-core-client-subtree/components/FreespinsRow";
import ResponsibleGaming from "../components/ResponsibleGaming";

/** Components branded */
import Footer from "../components/Footer";
import CashbackRoll from "../components/CashbackRoll";

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function Home() {
    const user = useSelector((state) => state.user.data);
    const theme = useSelector((state) => state.global.data.theme?.name);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    return (
        <>
            <Helmet>
                <title>{text('page_home')}</title>
            </Helmet>
            {
                userLoggedIn ? (
                    <>
                        <BackgroundGradientHome />
                        {
                            /** Home banner based on a theme */
                            themes(theme, "home_banner")
                        }
                        <FreespinsRow />
                        <GameRowLastPlayed start={0} limit={10} title={text('last_played_10')} category={'last-played-10'} />
                        <PhoneVerification />
                        <CashbackRoll />
                        {
                            user.wagerBalance > 5 ? (
                                <>
                                    <GameRowSpotlightCategory categoryURL="home-spotlight" />
                                    <GameRowJackpotCategory categoryURL="jackpot-daily" />
                                    <GameRowLastPlayed start={10} limit={20} title={text('last_played_20')} category={'last-played-20'} />
                                    <BonusRowCategory />
                                    <GameRowCategory categoryURL="new-games-1" />
                                    <GameRowColumnCategory categoryURL="themes" />
                                    <WeeklyBonus type="WEEKLY-HIGH" />
                                    <GameRowColumnCategory categoryURL="live-games" />
                                    <GameRowJackpotCategory categoryURL="jackpot-hourly" />
                                    <WeeklyBonus type="WEEKLY-MID" />
                                    <GameRowCategory categoryURL="unique-games-3" />
                                    <GameRowDoubleCategory categoryURL="table-games-1" />
                                    <GameRowCategory categoryURL="unique-games-1" />
                                    <GameRowDoubleCategory categoryURL="table-games-2" />
                                    <GameRowCategory categoryURL="popular-3" />
                                    <GameRowColumnCategory categoryURL="game-providers" />
                                </>
                            ) : (
                                <>
                                    <BonusRowCategory />
                                    <GameRowJackpotCategory categoryURL="jackpot-daily" />
                                    <GameRowSpotlightCategory categoryURL="home-spotlight" />
                                    <GameRowCategory categoryURL="new-games-1" />
                                    <GameRowColumnCategory categoryURL="themes" />
                                    <WeeklyBonus type="WEEKLY-HIGH" />
                                    <GameRowColumnCategory categoryURL="live-games" />
                                    <GameRowJackpotCategory categoryURL="jackpot-hourly" />
                                    <WeeklyBonus type="WEEKLY-MID" />
                                    <GameRowCategory categoryURL="unique-games-3" />
                                    <GameRowDoubleCategory categoryURL="table-games-1" />
                                    <GameRowCategory categoryURL="unique-games-1" />
                                    <GameRowDoubleCategory categoryURL="table-games-2" />
                                    <GameRowCategory categoryURL="popular-3" />
                                    <GameRowColumnCategory categoryURL="game-providers" />
                                </>
                            )
                        }
                        <ResponsibleGaming />
                        <Footer />
                    </>
                ) : (
                    <>
                        <BackgroundGradientHome />
                        {
                            /** Home banner based on a theme */
                            themes(theme, "home_banner")
                        }
                        <PhoneVerification />
                        <CashbackRoll />
                        <BonusRowCategory />
                        <GameRowJackpotCategory categoryURL="jackpot-daily" />
                        <GameRowSpotlightCategory categoryURL="home-spotlight" />
                        <GameRowColumnCategory categoryURL="themes" />
                        <WeeklyBonus type="WEEKLY-HIGH" />
                        <GameRowColumnCategory categoryURL="live-games" />
                        <GameRowJackpotCategory categoryURL="jackpot-hourly" />
                        <GameRowCategory categoryURL="new-games-1" />
                        <WeeklyBonus type="WEEKLY-MID" />
                        <GameRowCategory categoryURL="popular-3" />
                        <GameRowDoubleCategory categoryURL="table-games-1" />
                        <GameRowCategory categoryURL="unique-games-1" />
                        <GameRowDoubleCategory categoryURL="table-games-2" />
                        <GameRowCategory categoryURL="unique-games-3" />
                        <GameRowColumnCategory categoryURL="game-providers" />
                        <ResponsibleGaming />
                        <Footer />
                    </>
                )
            }
        </>
    )
}