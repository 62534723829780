import React, { useState, useRef } from "react";
import { dbCorefyDeposit } from '../utilities/api-functions';
import { Button, Spinner, FloatingLabel, Form, Row, Col, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';

/** Components */
import DepositSummary from "./DepositSummary";
import ErrorHandler from "./ErrorHandler";

/** Utilities */
import useText from "../utilities/use-text";
import { func } from "prop-types";

export default function DepositRedirect({ amount, promoCode, paymentMethod }) {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [email, setEmail] = useState(user.email || '');
    const [phone, setPhone] = useState(user.phone || '');
    const [street, setStreet] = useState(user.street || '');
    const [city, setCity] = useState(user.city || '');
    const [state, setState] = useState(user.state || '');
    const [zip, setZip] = useState(user.zip || '');

    const [documentId, setDocumentId] = useState('');

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [redirectUrl, setRedirectUrl] = useState('');

    const container = useRef(null);

    const formSubmitHandler = async () => {
        setIsProcessing(true);

        const data = {
            amount: amount,
            service: paymentMethod,
            return_url: window.location.origin,

            /** Player information */
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            street: street,
            city: city,
            state: state,
            zip: zip,
            // countryCode: countryCode,

            /** Pix fields */
            document_id: documentId,

            /** Transaction information */
            promoCode: promoCode,
        }

        /*****************/
        /** Making a request */
        try {
            const result = await dbCorefyDeposit(data);

            /*****************/
            /** Success */
            if (result?.data?.hpp_url) {
                return setRedirectUrl(result?.data?.hpp_url);
            }

            /*****************/
            /** Errors */
            setIsProcessing(false);

            if (result?.data?.errors?.length > 0) {
                setErrors([{ message: text('deposit_redirect_error', result?.data?.errors[0].title) }]);
            }
            else {
                setErrors([{ message: text('deposit_redirect_error') }]);
            }
        } catch (error) {
            /*****************/
            /** Errors */
            setIsProcessing(false);

            if (error?.response?.data?.errors?.length > 0) {
                setErrors([{ message: text('deposit_redirect_error', error?.response?.data?.errors[0]?.title) }]);
            }
            else {
                setErrors([{ message: text('deposit_redirect_error') }]);
            }
        }

        scrollToTop()
    }

    /** Webview Modal */
    const [show, setShow] = useState(false);
    const isWebview = useSelector((state) => state?.global?.data?.device?.isWebview);
    /** Webview Modal END */

    function redirectClickHandler() {
        console.log('redirectClickHandler');

        if (isWebview) {
            setShow(true);
        }
        else {
            window.open(redirectUrl, '_blank');
            setRedirectUrl('');
        }

        setIsProcessing(false);
    }



    function scrollToTop() {
        try {
            if (container.current.parentNode.parentNode.parentNode.parentNode.scrollTop > 0) {
                container.current.parentNode.parentNode.parentNode.parentNode.scrollTop = 0;
            }
        } catch (error) { }
    }

    return (
        <div className="deposit-redirect-container" ref={container}>
            <h2 className="subtitle font-mid deposit-redirect-title">{text('deposit_redirect_title')}</h2>
            {
                !redirectUrl ? (
                    <>
                        <DepositSummary sign={user?.currency?.sign} amount={amount} promoCode={promoCode} />
                        <div className="animate__animated animate__faster animate__fadeIn" style={{ minHeight: 'auto' }}>
                            <ErrorHandler {...{ errors, setErrors }} />
                            <div className="animate__animated animate__faster animate__fadeIn">

                                {
                                    {
                                        "payment_card_eur_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "payment_card_usd_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "payment_card_cad_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "payment_card_aud_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "payment_card_nzd_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "payment_card_brl_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "payment_card_pnl_hpp": (
                                            <CardFields {...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip
                                            }} />
                                        ),
                                        "interac_aggregated_cad_hpp": (
                                            <InteracFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                email={email}
                                                setEmail={setEmail}
                                                phone={phone}
                                                setPhone={setPhone}
                                            />
                                        ),
                                        "bitcoin_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "bitcoin_cash_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "ethereum_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "litecoin_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "ripple_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "tether_trc20_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "tether_erc20_eur_hpp": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "pix_brl_hpp": (
                                            <PixFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                email={email}
                                                setEmail={setEmail}
                                                documentId={documentId}
                                                setDocumentId={setDocumentId}
                                            />
                                        ),
                                    }[paymentMethod]
                                }

                                <div>
                                    <Button onClick={formSubmitHandler} className="btn-main-lg btn-deposit" disabled={isProcessing}>
                                        {
                                            isProcessing ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    &nbsp;&nbsp;
                                                    <span>{text('deposit_redirect_cta_processing')}</span>
                                                </>
                                            ) : (
                                                <span>{text('deposit_redirect_cta')}</span>
                                            )
                                        }
                                    </Button>
                                </div>

                                <div className='deposit_amount_section_images'>
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/secure-logo.png" />
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-pcidss.png" />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="deposit-redirect-summary-container animate__animated animate__faster animate__fadeIn">
                        <div className="deposit-redirect-summary">
                            <FontAwesomeIcon icon={faCheckCircle} className="deposit-check-icon mb-1" />
                            <h2 className="title-xs font-full mb-1">{text('deposit_redirect_success_title')}</h2>
                            <p className="mb-0">{text('deposit_redirect_success_text')}</p>
                        </div>

                        <Button onClick={redirectClickHandler} className="btn-main-lg btn-deposit-redirect">
                            <div>
                                <span>{text('deposit_redirect_success_cta')}</span>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>

                        {
                            isWebview && <WebviewIframe {...{ show, setShow, redirectUrl, setRedirectUrl }} />
                        }
                    </div>
                )
            }
        </div>
    )
}

function WebviewIframe({ show, setShow, redirectUrl, setRedirectUrl }) {
    return (
        <Modal show={show} onHide={() => { setShow(false); setRedirectUrl(''); }} fullscreen={true} className="deposit-frame-modal" >
            <Modal.Header closeButton />
            <Modal.Body>
                <iframe
                    src={redirectUrl}
                    className="deposit-frame"
                    name='deposit'
                    title="Deposit Frame"
                    allowFullScreen={true}
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                />
            </Modal.Body>
        </Modal>
    )
}

function CardFields({ firstName, setFirstName, lastName, setLastName, street, setStreet, city, setCity, state, setState, zip, setZip }) {
    const text = useText();

    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('deposit_redirect_field_first_name')}>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('deposit_redirect_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label={text('deposit_redirect_field_address')} className="mb-2">
                <Form.Control
                    type="text"
                    value={street}
                    onChange={(e) => { setStreet(e.target.value); }} />
            </FloatingLabel>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('deposit_redirect_field_city')}>
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={(e) => { setCity(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('deposit_redirect_field_state')}>
                        <Form.Control
                            type="text"
                            value={state}
                            onChange={(e) => { setState(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <Col className="mb-0">
                <FloatingLabel label={text('deposit_redirect_field_zip')}>
                    <Form.Control
                        type="text"
                        value={zip}
                        onChange={(e) => { setZip(e.target.value); }} />
                </FloatingLabel>
            </Col>
        </>
    )
}

function InteracFields({ firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone }) {
    const text = useText();

    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('deposit_redirect_field_first_name')}>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('deposit_redirect_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label={text('deposit_redirect_field_email')} className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label={text('deposit_redirect_field_phone')} className="mb-0">
                <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function CoinspaidFields({ email, setEmail }) {
    const text = useText();

    return (
        <>
            <FloatingLabel label={text('deposit_redirect_field_email')}>
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function PixFields({ firstName, setFirstName, lastName, setLastName, email, setEmail, documentId, setDocumentId }) {
    const text = useText();

    return (
        <>
            {/* <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('deposit_redirect_field_first_name')}>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('deposit_redirect_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row> */}
            {/* <FloatingLabel label={text('deposit_redirect_field_email')} className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel> */}
            <FloatingLabel label="ID do documento (CPF, CNPJ)" className="mb-2">
                <Form.Control
                    type="text"
                    value={documentId}
                    onChange={(e) => { setDocumentId(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}