import React, { useRef } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** Components */
import GameTile from "./GameTile";
import ScrollButtons from './ScrollButtons';
import CategoryTitle from "./CategoryTitle";
import { DailyJackpot, HourlyJackpot } from "./GameRowJackpotTypes"
import QuestionMark from "./QuestionMark";

/** Utilities */
import useText from "../utilities/use-text";

export default function GameRowJackpotCategory({ categoryURL }) {
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? categories[categoryURL] : null;
    const text = useText();

    return (
        <Container fluid="lg" className="category-container row-container section-margin" id={categoryURL}>
            <div className="must-drop-jackpot-tile-wrapper mb-3">
                <CategoryTitle title={category?.title} className="title-xs mb-0" />
                <QuestionMark placement="top" text={text('question_mark_jackpot')} />
            </div>
            
            <GameRowJackpot category={category} />
        </Container>
    );
}

function GameRowJackpot({ category }) {
    const placeholder = new Array(10).fill(null);
    const container = useRef(null);

    const nameArray = category?.url?.split('-') || [];
    const type = nameArray[nameArray?.length - 1];

    return (
        <div className="position-relative">
            <div className="game-row-wrapper must-drop-jackpot-row-wrapper" ref={container}>
                {/* {
                    type === 'weekly' && <WeeklyJackpot />
                } */}
                {
                    type === 'daily' && <DailyJackpot />
                }
                {
                    type === 'hourly' && <HourlyJackpot />
                }
                {
                    category ?
                        category.games.map((game, index) =>
                            <GameTile game={game} key={`game-row-${category.url}-${game.id}-${index}`} />) :
                        placeholder.map((game, index) =>
                            <GameTile game={game} key={index} />)
                }
            </div>
            <ScrollButtons container={container} />
        </div>
    );
}