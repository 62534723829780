export default function overrideMinDepositBonus(bonuses, depositMinAmount) {
    /** Copy all the bonuses to a new object */
    let newBonuses = Object.assign({}, bonuses);

    /** Go through each bonus in the object */
    for (let [key, value] of Object.entries(bonuses)) {

        if (value.activationType === 'DEPOSIT') {
            /** Create a temporary copy of the bonus */
            let tempBonus = Object.assign({}, value);

            /** Create an empty array or currencies */
            let tempCurrencyList = [];

            /** Go through each currency option and and check the minimum deposit amount */
            for (let option of tempBonus?.currencyList) {
                if (option.depositMinAmount < depositMinAmount) {

                    /** Create a temporary option copy */
                    let tempOption = Object.assign({}, option);

                    /** Update the minimum deposit */
                    tempOption.depositMinAmount = depositMinAmount;

                    /** Add option to the new currency list */
                    tempCurrencyList.push(tempOption);
                }

                tempCurrencyList.push(option);
            }

            /** Add options to temporary bonus */
            tempBonus.currencyList = tempCurrencyList;

            /** Override the bonus */
            newBonuses[tempBonus.promoCode] = tempBonus;
        }
    }

    return newBonuses;
}