import React from "react";
import { Container } from 'react-bootstrap';

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

// import responsibleGaming from '../data/images/responsible-gaming.png';
// import gamblingAnonymous from '../data/images/rp-gamblersanonymous.png';
// import rgc from '../data/images/rp-rgc.png';
// import gamblingTherapy from '../data/images/rp-gamblingtherapy.png';

// responsible_gaming_title: 'Responsible gaming',
// responsible_gaming_text: 'We believe that it is our responsibility to our players and the gaming community to educate and provide support at the right moment.',
// responsible_gaming_cta: Learn more

export default function ResponsibleGaming() {
    const text = useText();

    return (
        <Container fluid="lg" className="">
            <div className="responsible-gaming-wrapper">
                <div className="responsible-gaming-content-wrapper">
                    <h2 className="title white-100 mb-2">{text('responsible_gaming_title')}</h2>
                    <p className="mb-3">{text('responsible_gaming_text')}</p>
                    <div>
                        <a href="https://cepgucehw.gleap.help/en/articles/3-responsible-gaming" className="btn btn-secondary-outline">{text('responsible_gaming_cta')}</a>
                    </div>
                    {/* <div className="responsible-gaming-logos">
                        <img className="responsible-gaming-logo" src={gamblingAnonymous} />
                        <img className="responsible-gaming-logo" src={rgc} />
                        <img className="responsible-gaming-logo" src={gamblingTherapy} />
                    </div> */}
                </div>
                <div className="responsible-gaming-image-wrapper">
                    <img className="responsible-gaming-image" src="https://spt-assets.b-cdn.net/orozino/assets/responsible_gambling.png" />
                </div>
            </div>
        </Container>
    )
}