import React from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isTablet, isMobile } from 'react-device-detect';

/** Components */
import RegistrationFormWithVerify from "../spinsoft-core-client-subtree/components/RegistrationFormWithVerify";

/** Utilities */
import { totalBalance } from "../spinsoft-core-client-subtree/utilities/balance";
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function HomeBanner() {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    return (
        <Container fluid="lg" className="section-margin">
            {
                userLoggedIn ? <LoggedInContent /> : (
                    <div className="home_banner-container">

                        <video className="home_banner-background"
                            loop={true}
                            autoPlay="autoplay"
                            muted
                            webkit-playsinline="true"
                            playsInline={true}
                        >
                            <source src={isMobile || isTablet ? 'https://spt-assets.b-cdn.net/orozino/banner/banner_background_mobile.webm' : 'https://spt-assets.b-cdn.net/orozino/banner/banner_background.webm'} type="video/webm" />
                        </video>

                        {/* Left Tree  */}
                        <div className="home_banner-left_tree animate__animated animate__faster animate__fadeInLeft">
                            <img className="home_banner-left_tree_image"
                                src="https://spt-assets.b-cdn.net/orozino/banner/banner_tree-left.png?quality=0&auto_optimize=low"
                                alt=""
                            />
                        </div>

                        {/* Right Tree  */}
                        <div className="home_banner-right_tree animate__animated animate__faster animate__fadeInRight">
                            <img className="home_banner-right_tree_image"
                                src="https://spt-assets.b-cdn.net/orozino/banner/banner_tree-right.png?quality=0&auto_optimize=low"
                                alt=""
                            />
                        </div>

                        <div className="home_banner-content_wrapper">
                            <LoggedOutContent />
                        </div>
                    </div>
                )
            }
        </Container>
    );
}

function LoggedOutContent() {
    const text = useText();

    return (
        <div className="home_banner-content">
            <div className="home_banner-offer-wrapper animate__animated animate__fadeIn">
                <div className="home_banner-offer">
                    <span className='title font-full'>{text('home_banner_log_out_title_1')}</span>
                    <h1 className='title font-full'>{text('home_banner_log_out_title_2')}</h1>
                    <p className="subtitle font-full mb-0">{text('home_banner_log_out_title_3')}</p>
                </div>
            </div>
            <div className="home_banner-form-container">
                <div className="home_banner-form-wrapper animate__animated animate__faster animate__fadeInUp">
                    <RegistrationFormWithVerify isOnHomePage={true} />
                </div>
            </div>
        </div>
    )
}

function LoggedInContent() {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    const balanceCutOff = 15;

    return (
        <div className="home_banner-logged-in">
            <div className="home_banner-greeting">
                {
                    user?.firstName?.length > 0 ? (
                        <h1 className='title font-full mb-0' >{text('home_banner_log_in_title_name', user.firstName)}</h1>
                    ) : (
                        <h1 className='title font-full mb-0' >{text('home_banner_log_in_title')}</h1>
                    )
                }
            </div>

            <p className='font-mid mb-0 mt-1' >{text('home_banner_log_in_text')}</p>

            {
                parseFloat(totalBalance(user)) < balanceCutOff && user.depositsMade > 0 && (
                    <div>
                        <Link to={`?cashier`} className="btn btn-main mt-3">{text('home_banner_log_in_deposit_cta')}</Link>
                    </div>
                )
            }
        </div>
    )
}