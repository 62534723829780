import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, FloatingLabel, Form, Container } from 'react-bootstrap';
import { dbResetPassword } from "../utilities/api-functions";
import { Helmet } from "react-helmet-async";

/** Components */
import ErrorHandler from "../components/ErrorHandler";

/** Utilities */
import useText from "../utilities/use-text";

export default function ResetPassword() {
    const location = useLocation();
    const text = useText();

    const [token, setToken] = useState(null);
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const resetPassword = () => {
        if (password !== passwordConfirmation) {
            setPasswordMatch(false);
            return
        }
        else {
            setPasswordMatch(true);
        }

        dbResetPassword(token, password)
            .then((res) => {
                if (res?.data?.message) {
                    setMessage(res.data.message);

                    setTimeout(() => {
                        window.location.href = window.location.origin;
                    }, 1000)
                }
            })
            .catch(error => {
                setErrors([...error.response.data.errors]);
            });
    };

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        /** If token is present, set token */
        if (!token && params.token) {
            setToken(params.token)
        }
    });

    return (
        <>
            <Helmet>
                <title>{text('page_reset_password')}</title>
            </Helmet>

            <Container>
                <h1 className="h3 mt-3 mb-3">{text('reset_password_title')}</h1>
                {
                    !token ? (
                        <p>{text('reset_password_error')}.</p>
                    ) : (
                        <div className="reset-password-section">
                            <ErrorHandler {...{ errors, setErrors }} />

                            <FloatingLabel label={text('reset_password_field_new_password')} controlId="password" className="mb-2">
                                <Form.Control
                                    required
                                    type="password"
                                    value={password}
                                    placeholder={text('reset_password_field_new_password')}
                                    onChange={e => setPassword(e.target.value)} />
                                <Form.Control.Feedback type="invalid">{text('reset_password_field_new_password_error')}</Form.Control.Feedback>
                            </FloatingLabel>

                            <FloatingLabel label={text('reset_password_field_confirm_password')} controlId="confirmPassword" className="mb-3">
                                <Form.Control
                                    required
                                    type="password"
                                    value={passwordConfirmation}
                                    placeholder={text('reset_password_field_confirm_password')}
                                    onChange={e => setPasswordConfirmation(e.target.value)} />
                                <Form.Control.Feedback type="invalid">{text('reset_password_field_confirm_password_error')}</Form.Control.Feedback>
                            </FloatingLabel>

                            {
                                !passwordMatch && (
                                    <p>{text('reset_password_error_match')}</p>
                                )
                            }

                            {
                                message.length === 0 && (
                                    <Button className='btn-main ps-4 pe-4' onClick={resetPassword}>
                                        {text('reset_password_cta')}
                                    </Button>
                                )
                            }

                            {
                                message.length > 0 && (
                                    <>
                                        <p className="mt-3 mb-0">{message}</p>
                                        <p className="mb-0">{text('reset_password_redirect_text')}</p>
                                    </>
                                )
                            }
                        </div>
                    )
                }
            </Container>
        </>
    );
}