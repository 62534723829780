import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { dbRegister, dbLogin, dbCheckEmailExists, dbSendWelcomeEmail } from "../utilities/api-functions";
import { Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import getPasswordHash from '../utilities/get-password-hash';
import Axios from 'axios';

/** Components */
import ErrorHandler from "./ErrorHandler";

/** Brand Components */
import GoogleButton from "../../components/GoogleButton";

/** Utilities */
import useText from "../utilities/use-text";

export default function RegistrationFormWithVerify() {
    const country = useSelector((state) => state?.global?.data?.country);
    const language = useSelector((state) => state?.global?.data?.language);
    const segment = useSelector((state) => state?.global?.data?.segment);
    const ipLocation = useSelector((state) => state?.global?.data?.location);
    const isWebview = useSelector((state) => state?.global?.data?.device?.isWebview);
    const globalLimits = useSelector((state) => state.global.data.limits);

    const text = useText();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false);

    const [step, setStep] = useState(1);
    const step1 = useRef(null);
    const step2 = useRef(null);
    const step3 = useRef(null);

    function transitionToStep(nextStep) {
        if (nextStep === 3) {
            step2.current.classList.add("animate__fadeOutLeft");
            setTimeout(() => {
                setStep(nextStep);
                step3.current.classList.remove("animate__fadeOutRight");
                step3.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (nextStep === 2) {
            step1.current.classList.add("animate__fadeOutLeft");
            setTimeout(() => {
                setStep(nextStep);
                step2.current.classList.remove("animate__fadeOutRight");
                step2.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (nextStep === 1) {
            step2.current.classList.add("animate__fadeOutRight");
            setTimeout(() => {
                setStep(nextStep);
                step1.current.classList.remove("animate__fadeOutLeft");
                step1.current.classList.add("animate__fadeIn");
            }, 300);
        }
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function handleRegistration(event) {
        event.preventDefault();
        register();
    }

    function getDepositMinAmount() {
        if (segment?.depositMinAmount > 0) {
            return segment?.depositMinAmount;
        }

        if (segment?.params?.traffic === 'paid') {
            const currencyLimits = globalLimits.filter(item => item.currency === country?.currency?.id)[0] || null;
            return currencyLimits?.depositMinAmountPT || 0;
        }
        
        return 0;
    }

    /********************/
    /********************/
    /** Google Registration */
    const googleGoogleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => googleRegister(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    async function googleRegister(credentials) {
        try {
            const result = await Axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentials.access_token}`, {
                headers: {
                    Authorization: `Bearer ${credentials.access_token}`,
                    Accept: 'application/json'
                }
            })

            if (result?.data?.email) {
                setBtnLoader(true);

                /*******************************/
                /** Create password */
                const gEmail = result?.data?.email;
                const gPassword = getPasswordHash(result?.data?.email);

                /*******************************/
                /** Check if such email already exists */
                const response = await dbCheckEmailExists(gEmail);

                /*******************************/
                /** If email exists execute login */
                if (response?.data?.totalDocs > 0) {
                    const loginResponse = await dbLogin(gEmail, gPassword);

                    if (loginResponse?.data?.user && loginResponse?.data?.user?.isActive) {
                        window.location.href = `${window.location.origin}`;
                    };

                    setBtnLoader(false);
                    return;
                }

                /*******************************/
                /** If no email exists execute registration */
                register(gEmail, gPassword, true);
            }
        } catch (error) {
            console.log(`Error, googleRegister, ${error}`);
            setBtnLoader(false);
        }
    };

    /********************/
    /********************/
    /** CMS Registration */
    async function cmsRegister() {
        setBtnLoader(true);

        /*******************************/
        /** Check for email validity */
        if (!isValidEmail(email)) {
            setErrors([{ message: text('registration_form_error_email') }])
            setBtnLoader(false);
            return;
        }

        /*******************************/
        /** Check such email already exists */
        try {
            const result = await dbCheckEmailExists(email);

            if (result?.data?.totalDocs > 0) {
                setErrors([{ message: text('registration_form_error_email_exists') }]);
                setBtnLoader(false);
                return;
            }
        }
        catch (error) {
            setErrors([{ message: text('registration_form_error_general') }]);
            setBtnLoader(false);
            return;
        }

        /*******************************/
        /** Check password */
        if (password?.length < 7) {
            setErrors([{ message: text('registration_form_error_password') }])
            setBtnLoader(false);
            return;
        }

        /*******************************/
        /** Start Registration */
        register(email, password, false);
    }

    /********************/
    /********************/
    /** Registration */
    async function register(email, password, isEmailVerified) {

        /*******************************/
        /** Create registration data */
        const data = {
            email: email,
            password: password,

            /** Experience country */
            country: country?.id,

            /** IP Information */
            lastIp: ipLocation.ip || '',
            ipCountry: ipLocation.country || '',
            ipCountryCode: ipLocation.country_code || '',
            ipRegion: ipLocation.region || '',
            ipRegionCode: ipLocation.region_code || '',
            ipCity: ipLocation.city || '',

            /** Information */
            firstName: '',
            lastName: '',
            street: '',
            zip: '',
            phone: '',
            city: ipLocation?.country === country?.name ? ipLocation?.city : '',
            state: ipLocation?.country === country?.name ? ipLocation?.region_code : '',
            playerCountry: ipLocation?.country === country?.name ? ipLocation?.country : '',
            playerCountryCode: ipLocation?.country === country?.name ? ipLocation?.country_code : '',

            /** Currency */
            currency: country?.currency?.id,
            playerCurrency: country?.currency?.code,

            /** Traffic data */
            params: JSON.stringify(segment?.params),
            depositMinAmount: getDepositMinAmount(),

            /** Other */
            language: language,
            bonusesClaimed: '',
            lastPlayedGames: '',

            /** Email needs verification */
            isEmailVerified: isEmailVerified
        }

        // console.log(data);

        try {
            /*******************************/
            /** Register */
            const registrationResponse = await dbRegister(data);

            if (!registrationResponse.data.doc) return;

            /*******************************/
            /** If email is not verified show step 3 of the registrations */
            if (!isEmailVerified) {
                transitionToStep(3)
                return;
            };

            /*******************************/
            /** Log in */
            const loginResponse = await dbLogin(data.email, data.password);

            if (!loginResponse?.data?.user) return;

            // dbSendWelcomeEmail();

            setTimeout(() => {
                window.location.href = `${window.location.origin}?cashier`
            }, 500);

        } catch (error) {
            setErrors([...error.response.data.errors]);
            setBtnLoader(false);
        }
    }

    return (
        <>
            <div className="registration-form">
                <div className="animate__animated animate__faster" ref={step1}>
                    {
                        step === 1 && (
                            <div className="registration-method-container">
                                <div className="registration-method-wrapper">
                                    {
                                        !isWebview && (
                                            <>
                                                <GoogleButton {...{ googleGoogleLogin, btnLoader }} />
                                                <div className="registration-method-separator">{text('registration_form_or')}</div>
                                            </>
                                        )
                                    }
                                    <button className="btn btn-main btn-register " onClick={() => transitionToStep(2)}>{text('registration_form_btn_cta')}</button>
                                </div>

                                <img width="250px" src="https://spt-assets.b-cdn.net/orozino/themes/default/registration_banner_payments_black.png" style={{opacity: "0.45", margin: "25px auto 15px auto"}} />
                                
                                <p className="mb-0 registration-form-terms">{text('registration_form_terms')}</p>
                            </div>
                        )
                    }
                </div>
                <div className="animate__animated animate__faster" ref={step2}>
                    {
                        step === 2 && (
                            <>
                                <div className="btn-back-registration-form-wrapper">
                                    <button className="btn btn-secondary btn-back-registration-form" onClick={() => transitionToStep(1)}><FontAwesomeIcon icon={faChevronLeft} /></button>
                                    <p className="subtitle-sm font-full mb-0">{text('registration_form_btn_cta')}</p>
                                </div>
                                <ErrorHandler errors={errors} setErrors={setErrors} />
                                <form onSubmit={handleRegistration}>
                                    <FloatingLabel label={text('registration_form_email_label')} className="mb-2">
                                        <Form.Control
                                            type="email"
                                            placeholder={text('registration_form_email_placeholder')}
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            isValid={isValidEmail(email)} />
                                    </FloatingLabel>

                                    <FloatingLabel label={text('registration_form_password_label')} className="mb-3">
                                        <Form.Control
                                            type="password"
                                            placeholder={text('registration_form_password_label')}
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            enterKeyHint={text('registration_form_key_hint')}
                                            isValid={password.length > 6} />
                                    </FloatingLabel>

                                    <p className="mb-0 mb-4 registration-form-terms">{text('registration_form_terms')}</p>

                                    <input type="submit" data-role="none" style={{ visibility: 'hidden', position: 'absolute' }} />
                                </form>

                                <Button onClick={cmsRegister} className="btn btn-main" disabled={btnLoader}>
                                    {
                                        btnLoader && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-3" />
                                    }
                                    <span>{text('registration_form_btn_cta')}</span>
                                </Button>
                            </>
                        )
                    }
                </div>
                <div className="animate__animated animate__faster" ref={step3}>
                    {
                        step === 3 && (
                            <div className="registration-method-container">
                                <div className="registration-method-wrapper">
                                    <div className="registration-verify-email-icon-wrap-success">
                                        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                                <path className="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                                <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                                            </g>
                                        </svg>
                                    </div>
                                    <p className="title-xs mb-2">{text('registration_form_verify_email_title')}</p>
                                    <p style={{maxWidth: "350px"}}>{text('registration_form_verify_email_subtitle')}</p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}