export function totalBalance(user) {
    const real = parseFloat(user.wagerBalance) + parseFloat(user.withdrawableBalance);
    const bonus = parseFloat(user.bonus[0]?.bonusDepositBalance || 0) + parseFloat(user.bonus[0]?.bonusDepositWinnings || 0) + parseFloat(user.bonus[0]?.bonusFreeBalance || 0) + parseFloat(user.bonus[0]?.bonusFreeWinnings || 0);
    return roundNumber(parseFloat(real) + parseFloat(bonus));
}

export function roundNumber(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function bonusBalance(user) {
    const bonus = parseFloat(user.bonus[0]?.bonusDepositBalance || 0) + parseFloat(user.bonus[0]?.bonusDepositWinnings || 0) + parseFloat(user.bonus[0]?.bonusFreeBalance || 0) + parseFloat(user.bonus[0]?.bonusFreeWinnings || 0);
    return bonus;
}

export function wagerPercentCalculate(user) {
    let percent = (user?.bonus[0].betsMade * 100) / user?.bonus[0].betsTarget;
    percent = isNaN(percent) ? 0 : percent;
    return percent > 100 ? 100 : roundNumber(percent);
}