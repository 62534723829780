import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container } from 'react-bootstrap';

/** Components */
import GameTile from "./GameTile";
import CategoryTitle from "./CategoryTitle";

/** Utilities */
import { useFetchSearchGames } from "../utilities/use-fetch";

/** TO DO:
 * Has to search via tags as well
 * Add "Suggested" rows if there are no options
 */

export default function GameSearch({ search }) {
    const [trigger, setTrigger] = useState(0);
    
    const { games } = useFetchSearchGames(search, trigger);

    const loader = useRef(null);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setTrigger((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);

        const loaderNode = loader?.current;

        if (loaderNode) observer.observe(loaderNode);

        return () => {
            if (loaderNode) {
                observer.unobserve(loaderNode)
            };
        }

    }, [handleObserver]);

    return (
        <Container id="search-container" fluid="lg" className="game-category-container lazy-loader-container section-margin">
            <CategoryTitle title={`Search: ${search}`} className="title-xs mb-3" />

            <div className={`game-list-wrapper`}>
                {
                    games.map((game, index) => <GameTile game={game} key={`search-list-${game.id}-${index}`} />)
                }
            </div>

            <div ref={loader} className="lazy-loader" />
        </Container>
    );
}