import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/** Metadata */
import metadata from '../../metadata.json';

export default function IPBlock() {
    const ipLocation = useSelector((state) => state.global.data.location);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(ipLocation);

        if (ipLocation?.vpn) {
            navigate(`/region-blocked`, { replace: true });
            return;
        }

        if (
            !metadata?.allowed?.country_code?.includes(ipLocation?.country_code) &&
            !metadata?.allowed?.ip?.includes(ipLocation?.ip)
        ) {
            if (!process.env.REACT_APP_PAYLOAD_URL?.includes('localhost')) {
                navigate(`/region-blocked`, { replace: true });
                return;
            }
        }
    }, []);

    return null;
}