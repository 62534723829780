import { createSlice } from "@reduxjs/toolkit";
import { dbGetGameCategories } from '../utilities/api-functions';
import submitError from "../utilities/submit-error";

export const gameCategories = createSlice({
    name: "gameCategories",
    initialState: {
        isLoading: true,
        list: {}
    },
    reducers: {
        updateGameCategories: (state, action) => {
            let newCategoryList = {};
            
            action.payload.list.forEach(category => {
                let newGames = [];
                let newCategories = [];

                /**********************/
                /** Category consists of games */
                if (category.games) {
                    category.games.forEach(item => {
                        if (item.game?.isActive === true) {
                            newGames.push(item.game);
                        }
                    });
                    category.games = [...newGames];
                }

                /**********************/
                /** Category consists of categories */
                if (category.categories) {
                    category.categories.forEach(item => {
                        if (item.category) {
                            newCategories.push(item.category)
                        }
                    });
                    category.categories = [...newCategories];
                }
                
                /**********************/
                /** Add category to the new list */
                newCategoryList[category.url] = category;
            });

            Object.assign(state, { ...state, ...{
                    list: newCategoryList,
                    isLoading: false
                }
            });
        }
    }
});

export const getGameCategoriesAsync = (global) => (dispatch) => {
    dbGetGameCategories(global?.language)
        .then(res => {
            if (res.data.docs) {
                dispatch(updateGameCategories({ list: res.data.docs }));
            }
        })
        .catch(error => {
            submitError('ERROR', `getGameCategoriesAsync, ${error}`);
        })
}

// Action creators are generated for each case reducer function
export const {
    updateGameCategories
} = gameCategories.actions;

export default gameCategories.reducer;