export const es = {

    // ClaimWinningsAllowed.js
    claim_winnings_allowed_message: 'We are currently reviewing your winnings and will contact you asap.',

    // WithdrawAllowed.js
    withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>You currently have an active withdrawal for {sign}{activeWithdrawal?.amount}. Please wait while we process to request your next withdrawal.</span>,

    // BonusRowCategory.js
    bonus_row_title: "Elige tu bono primero",
    bonus_row_title_2: "Elige tu próximo bono",

    // BonusTile.js
    bonus_tile_cta: 'Reclamar ahora',

    // BonusTimer.js
    bonus_timer_expired: '¡Caduca hoy!',

    // Deposit.js
    deposit_btn_back_to_amount: "Volver al monte del depósito",
    deposit_btn_back_to_payment_options: "Volver a las opciones de pago",

    // DepositAmountSection.js
    deposit_amount_title: "Importe",
    deposit_amount_cta: "Depositar",
    deposit_amount_field: "Importe del depósito",
    deposit_amount_field_label: (sign) => {
        return `Importe del depósito (${sign})`
    },
    deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `El importe del depósito debe estar entre ${sign}${depositMinAmount} y ${sign}${depositMaxAmount}.`
    },
    deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `El importe del depósito para bono seleccionado debe estar entre ${sign}${depositMinAmount} y ${sign}${depositMaxAmount}.`
    },

    // DepositBonusCard.js
    deposit_bonus_card_min_deposit: "Depósito mínimo:",

    // DepositBonusSection.js
    deposit_bonus_section_title: "Elige tu bono",
    deposit_bonus_section_title_selected: "Bono seleccionado:",

    // DepositPaymentMethod.js
    deposit_payment_method_title: "Elige tu método de pago",
    deposit_payment_method_bonus: "Bono adicional del 15%",

    // DepositRedirect.js
    deposit_redirect_title: "Completa tu pago",
    deposit_redirect_cta: "Depositar",
    deposit_redirect_cta_processing: "Procesamiento",
    deposit_redirect_success_title: "¡Ya casi!",
    deposit_redirect_success_text: "Haz clic a continuación para recargar y comenzar a jugar.",
    deposit_redirect_success_cta: "Completa tu pago",
    deposit_redirect_error: (error) => {
        return `${error ? error : 'Se produjo un error.'} Inténtalo de nuevo o elige otro método de pago.`;
    },
    deposit_redirect_field_first_name: 'Nombre',
    deposit_redirect_field_last_name: 'Apellidos',
    deposit_redirect_field_email: 'Correo electrónico',
    deposit_redirect_field_phone: 'Teléfono',
    deposit_redirect_field_address: 'Dirección',
    deposit_redirect_field_city: 'Ciudad',
    deposit_redirect_field_state: 'Provincia/Estado',
    deposit_redirect_field_zip: 'Código postal',

    // DepositSummary.js
    deposit_summary_amount: "Importe:",
    deposit_summary_bonus: "Bono:",
    deposit_summary_no_bonus: "No ha sido seleccionado ningun bono.",

    // DepositToggle.js
    deposit_toggle_btn_deposit: "Depositar",
    deposit_toggle_btn_withdraw: "Retirar",

    // FreespinsRow.js
    freespin_row_title: ({ number, game }) => <> ¡Tienes <span className="">{number} giros gratis</span> en <span className="">{game}</span>!</>,
    freespin_row_cta: "Juega ahora",

    // GameRowJackpotTypes.js
    jackpot_types_daily: 'Jackpot diario',
    jackpot_types_hourly: 'Jackpot por hora',
    jackpot_types_drops_in: 'Recompensas en',

    // GameSearchField.js
    game_search_input_placeholder: "Buscar el juego o el proveedor",
    game_search_input_hint: "Buscar",

    // IdleTimer.js
    idle_timer_title: "La sesión ha finalizado",
    idle_timer_text: "Has estado inactivo. Inicia sesión de nuevo.",
    idle_timer_cta: 'Iniciar sesión',

    // ModalClaimBonusWinnings.js
    claim_bonus_winnings_title: 'Reclama tus ganancias',
    claim_bonus_winnings_processing: 'Procesamiento...',
    claim_bonus_winnings_exceeded: 'Tu solicitud ha sido enviada para ser aprobada. Te contestaremos lo antes posible.',
    claim_bonus_winnings_error: 'Se produjo un error. Por favor, vuelve a intentarlo o ponte en contacto con el centro de ayuda..',

    // ModalCloseAccount.js
    close_account_title: 'Cerrar cuenta',
    close_account_text: <span>¿Estás seguro/a de que deseas cerrar tu cuenta?<br /><br /><i>Para finalizar el proceso de autoexclusión, inicia sesión con tu correo electrónico y contraseña y sigue las instrucciones.</i></span>,
    close_account_cta: 'Sí, cierra mi cuenta',

    // ModalOpenAccount.js
    open_account_title: 'Cuenta cerrada',
    open_account_text: 'Tu cuenta está cerrada actualmente. ¿Deseas abrir tu cuenta?',
    open_account_cta: 'Sí, abre mi cuenta',

    // Navigation.js
    navigation_btn_home: "Inicio",
    navigation_btn_games: "Juegos",
    nav_button_help: "Ayuda",
    navigation_btn_log_in: "Iniciar sesión",
    navigation_btn_register: "Registrarse",
    navigation_balance: "Saldo",

    // PhoneVerification.js
    phone_verification_log_out_title: (number) => <>¡Obtén <span className="verify-phone-t itle-spins">{number}</span> no se requiere&nbsp; depósito!</>,
    phone_verification_log_out_text: (number) => `¡Sólo por tiempo limitado! Recibe ${number} después de completar tu registro.`,
    phone_verification_log_in_title: (number) => <> ¡Verifica tu teléfono y obtén <span className="verify-phone-title-spins" >{number}</span>!</>,
    phone_verification_field_code_title: 'Introduce el código de confirmación',
    phone_verification_field_phone_label: 'Introduce tu teléfono',
    phone_verification_field_code_label: 'Código',
    phone_verification_btn_claim: 'Reclamar ahora',
    phone_verification_btn_verify_claim: 'Verificar y reclamar',
    phone_verification_btn_confirm: 'Confirmar',
    phone_verification_btn_try_again: 'Intentarlo de nuevo',
    phone_verification_confirmation_title: "Por favor, verifica tu teléfono. Una vez confirmado, no podrás cambiarlo por ti mismo",
    phone_verification_error_phone_already_verified: 'Este teléfono ya ha sido verificado.',
    phone_verification_error_wrong_phone: 'Este teléfono no coincide con el de tu perfil.',

    phone_verification_error_active_bonus: 'Ya tienes un bono. Por favor, refresca la página.',
    phone_verification_error_general: 'Se ha producido un error. Por favor, comprueba tu teléfono y vuelve a intentarlo.',

    // ProfilePlayerInformation.js
    profile_information_title: 'Información de facturación',
    profile_information_btn_save: 'Guardar',
    profile_information_change_phone: 'Nota: si deseas cambiar tu número, ponte en contacto con el servicio de asistencia.',
    profile_information_field_first_name: 'Nombre',
    profile_information_field_last_name: 'Apellidos',
    profile_information_field_email: 'Correo electrónico',
    profile_information_field_phone: 'Teléfono',
    profile_information_field_address: 'Dirección',
    profile_information_field_city: 'Ciudad',
    profile_information_field_state: 'Provincia/Región',
    profile_information_field_zip: 'Código postal',
    profile_information_field_dob: 'Fecha de nacimiento',

    // RegistrationForm.js
    registration_form_email_label: "Dirección de correo electrónico",
    registration_form_or: 'OR',
    registration_form_email_placeholder: "name@example.com",
    registration_form_password_label: "Contraseña",
    registration_form_key_hint: "Registrarse",
    registration_form_terms: 'Acepto los términos y condiciones.',
    registration_form_btn_cta: "Registrarse ahora",
    registration_form_error_email: "Por favor, proporciona una dirección de correo electrónico válida.",
    registration_form_error_password: "La contraseña debe tener al menos 7 caracteres",
    registration_form_error_terms: "Acepta nuestros términos y condiciones para completar el proceso de registro",
    registration_form_error_general: "Se ha producido un error. Por favor, inténtalo de nuevo.",
    error_turnstile: "Por favor, completa la prueba CAPTCHA.",
    registration_form_error_email_exists: <span>Dicho correo electrónico ya existe.<br /> ¿A lo mejor estás intentando <a href="?login">iniciar sesión</a>?</span>,

    // WeeklyBonus.js
    weekly_bonus_cta: 'Reclamar ahora',

    // Withdraw.js
    withdraw_balance: 'Saldo retirable',
    withdraw_bonus_balance: 'Saldo de bono',
    withdraw_wager_progress: 'Progreso de la apuesta',
    withdraw_choose_amount: 'Introduce el importe a retirar',
    withdraw_choose_amount_label: (sign) => `Importe (${sign})`,
    withdraw_error_insufficient_funds: "Fondos insuficientes",
    withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => `Fondos insuficientes. El monto mínimo de retiro es ${sign}${minAmount}`,
    withdraw_error_wrong_amount_limit_min_max: ({ sign, maxAmount }) => `La cantidad máxima de retiro es ${sign}${maxAmount}`,
    withdraw_btn_back_to_payment_options: 'Volver a las opciones de retiro',

    // WithdrawMethod.js
    withdraw_method_interac_legal: '® Marca comercial de Interac Corp. Usada bajo licencia.',
    withdraw_method_choose_amount: 'Introduce el importe para continuar',

    // WithdrawRedirect.js
    withdraw_redirect_title: 'Completa tu retiro',
    withdraw_redirect_cta: "Retirar",
    withdraw_redirect_cta_processing: 'Procesamiento...',
    withdraw_redirect_success_title: '¡Éxito!',
    withdraw_redirect_success_text_1: 'Tu solicitud de retiro se está procesando y debe completarse en un plazo de 1 a 5 días hábiles, en función del método de retiro.',
    withdraw_redirect_success_text_2: 'Tan pronto como se procese tu retiro, el monto seleccionado se deducirá del saldo de tu cuenta.',
    withdraw_redirect_error: (error) => {
        return `${error ? error : 'Se produjo un error.'} Inténtalo de nuevo o elige otro método de retiro.`;
    },
    withdraw_redirect_field_first_name: 'Nombre',
    withdraw_redirect_field_last_name: 'Apellidos',
    withdraw_redirect_field_email: 'Correo electrónico',
    withdraw_redirect_field_phone: 'Teléfono',
    withdraw_redirect_field_address: 'Dirección',
    withdraw_redirect_field_city: 'Ciudad',
    withdraw_redirect_field_state: 'Provincia/Región',
    withdraw_redirect_field_zip: 'Código postal',
    withdraw_redirect_field_card_number: 'Número de tarjeta',
    withdraw_redirect_field_exp_month: 'Mes de expedición (MM)',
    withdraw_redirect_field_exp_year: 'Año de expedición (YY)',

    // WithdrawSummary.js
    withdrawal_summary_amount: "Importe:",

    // ForgotPassword.js
    forgot_password_title: 'Restablece tu contraseña',
    forgot_password_field_email: "Introduce tu correo electrónico de registro",
    forgot_password_text_1: "Revisa tu bandeja de entrada y la carpeta de spam.",
    forgot_password_text_2: "Si no recibes un correo electrónico en un plazo de 30 minutos, intenta enviar este formulario de nuevo o ponte en contacto con el servicio de asistencia.",
    forgot_password_btn_close: "Cerrar",
    forgot_password_btn_submit: "Enviar",

    // Login.js
    login_forgot_password_cta: "¿Olvidaste tu contraseña?",
    login_create_account: "¿Aún no tienes una cuenta?",
    login_create_account_cta: "Registrarse",
    login_btn_close: "Cerrar",
    login_cta: "Iniciar sesión",

    // Profile.js
    profile_title: 'Perfil del cliente',
    profile_balance: 'Saldo total:',
    profile_balance_withdrawable: 'Saldo retirable:',
    profile_balance_bonus: 'Saldo de bono:',
    profile_wager_progress: 'Progreso de la apuesta:',
    profile_bonus_promocode: 'Código de promoción:',
    profile_bonus_expires: 'Caduca el',
    profile_btn_claim_winnings: 'Reclama tus ganancias',
    profile_btn_bonus_cancel: 'Cancelar bono',
    profile_btn_support: 'Contactar el servicio de asistencia',
    profile_close_account: '¿Quieres tomarte un descanso?',
    profile_btn_close_account: 'Cerrar cuenta',
    profile_btn_log_out: 'Cerrar sesión',

    // RegionBlocked.js
    region_blocked_title: 'Región bloqueada.',

    // ResetPassword.js
    reset_password_title: 'Restablece tu contraseña',
    reset_password_field_new_password: 'Nueva contraseña',
    reset_password_field_new_password_error: 'Por favor, introduce tu contraseña',
    reset_password_field_confirm_password: 'Confirmar contraseña',
    reset_password_field_confirm_password_error: 'Por favor, introduce nuevamente tu contraseña',
    reset_password_error: 'Se produjo un error. Por favor, sigue nuevamente el enlace de restablecimiento o ponte en contacto con el servicio de asistencia. ',
    reset_password_error_match: '¡Las contraseñas no coinciden! Por favor, haz las correcciones necesarias y vuelve a intentarlo. ',
    reset_password_cta: 'Restablecer contraseña',
    reset_password_redirect_text: 'Redirigiendo a la página de inicio...'
}