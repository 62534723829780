import React, { useState, useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logOutUser } from '../reducers/user';
import { Modal, Button } from 'react-bootstrap';
import { dbLogout } from "../utilities/api-functions";

/** Utilities */
import useText from "../utilities/use-text";

export default function IdleTimerWrapper() {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    return (
        <>
            {userLoggedIn && <IdleTimer />}
        </>
    )
}

function IdleTimer() {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const text = useText();

    const minutes = 60;
    const [show, setShow] = useState(false);

    const handleOnIdle = () => {
        //setShow(true); 
        dbLogout();
        setTimeout(() => {
            navigate(0);
        }, 1500)
    }
    // const handleClose = () => { setShow(false); dispatch(logOutUser()); navigate(0); }
    // const handleLogin = () => { setShow(false); dispatch(logOutUser()); navigate(`?login`); }

    useIdleTimer({
        timeout: 1000 * 60 * minutes,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    // useEffect(() => {
    //     if (show) { 
    //         dbLogout();
    //         setTimeout(() => {
    //             navigate(`?login`);
    //         }, 1500)
    //     }
    // }, [show])

    return null;
    // return (
    //     <Modal show={show} onHide={handleClose} centered>
    //         <Modal.Header closeButton>
    //             <Modal.Title>{text('idle_timer_text')}</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //             <p>{text('idle_timer_text')}</p>
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <Button className="btn-main" onClick={handleLogin}>
    //                 {text('idle_timer_cta')}
    //             </Button>
    //         </Modal.Footer>
    //     </Modal>
    // )
}