
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container } from 'react-bootstrap';

/** Components */
import CategoryTitle from "./CategoryTitle";
import GameTile from "./GameTile";

/** Utilities */
import { useFetchAllGames } from "../utilities/use-fetch";

export default function GamesListAll() {
    const placeholder = new Array(10).fill(null);
    const [page, setPage] = useState(1);
    const { games } = useFetchAllGames(page);

    const loader = useRef(null);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);

        const element = loader?.current;

        if (element) observer.observe(element);

        return () => {
            if (element) {
                observer.unobserve(element)
            };
        }

    }, [handleObserver]);

    return (
        <section className="game-category-container" id={`game-category-all-games`}>
            <span id={`game-category-anchor-all-games`}></span>
            <Container fluid="lg" className="category-container lazy-loader-container section-margin" id="all-games">
                <CategoryTitle title="All Games" className="title-xs mb-3" />
                <div className={`game-list-wrapper`}>
                    {
                        games?.length < 1 ? (
                            placeholder.map((game, index) => <GameTile game={game} key={index} />)
                        ) : (
                            games.map((game, i) => <GameTile game={game} key={`all-games-${game.id}+${i}`} />)
                        )

                    }
                </div>

                <div ref={loader} className="lazy-loader" />
            </Container>
        </section>
    );
}