import React from "react";
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function DepositAmountPreset({ amount, setAmount }) {
    const user = useSelector((state) => state.user.data);
    const location = useSelector((state) => state.global.data.location);
    const segment = useSelector((state) => state.global.data.segment);
    const globalLimits = useSelector((state) => state.global.data.limits);
    const currencyLimits = globalLimits.filter(item => item.currency === user?.currency?.id)[0] || null;
    const presetAmounts = currencyLimits?.presetAmounts?.split(',');

    function depostAmounts() {
        if (!location?.vpn &&
            user?.depositsMade === 0 &&
            user?.depositMinAmount > 0 &&
            segment?.countries?.includes(location?.country)
        ) {
            return {
                low: parseFloat(presetAmounts[0]) + (user?.depositMinAmount / 2 || 0),
                mid: parseFloat(presetAmounts[1]) + (user?.depositMinAmount / 2 || 0),
                high: parseFloat(presetAmounts[2]) + (user?.depositMinAmount / 2 || 0),
            }
        }

        return {
            low: parseFloat(presetAmounts[0]),
            mid: parseFloat(presetAmounts[1]),
            high: parseFloat(presetAmounts[2]),
        }
    }

    return (
        <ButtonGroup className="deposit_amount_preset">
            <ToggleButton
                className='deposit_amount_preset_button'
                id="deposit_amount_preset_button_1"
                type="checkbox"
                checked={parseInt(depostAmounts().low) === amount}
                onChange={() => { setAmount(depostAmounts().low); }}
            >
                {user.currency.sign}{depostAmounts().low}
            </ToggleButton>
            <ToggleButton
                className='deposit_amount_preset_button'
                id="deposit_amount_preset_button_2"
                type="checkbox"
                checked={parseInt(depostAmounts().mid) === amount}
                onChange={() => { setAmount(depostAmounts().mid); }}
            >
                {user.currency.sign}{depostAmounts().mid}
            </ToggleButton>
            <ToggleButton
                className='deposit_amount_preset_button'
                id="deposit_amount_preset_button_3"
                type="checkbox"
                checked={parseInt(depostAmounts().high) === amount}
                onChange={() => { setAmount(depostAmounts().high); }}
            >
                {user.currency.sign}{depostAmounts().high}
            </ToggleButton>
        </ButtonGroup>
    )
}