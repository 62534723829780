import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { isTablet, isDesktop } from 'react-device-detect';

/** Components */
import Deposit from '../components/Deposit';
import Withdraw from '../components/Withdraw';
import OffcanvasBtnClose from '../components/OffcanvasBtnClose';
import DepositToggle from '../components/DepositToggle';

/** Utilities */
import useText from "../utilities/use-text";

export default function Cashier() {
    const navigate = useNavigate();
    const location = useLocation();
    const text = useText();

    const [show, setShow] = useState(true);
    const [toggle, setToggle] = useState('deposit');

    function handleClose() {
        setShow(false);
        setTimeout(() => { navigate(location.pathname); }, 300);
    };

    return (
        <>
            <Helmet>
                <title>{text('page_cashier')}</title>
            </Helmet>
            <Offcanvas show={show} onHide={handleClose} placement={isTablet || isDesktop ? 'end' : 'bottom'}>
                <Offcanvas.Header className='cashier_offcanvas_header'>
                    <DepositToggle {...{ toggle, setToggle }} />
                </Offcanvas.Header>

                <Offcanvas.Body className='cashier_offcanvas_body'>
                    {
                        {
                            deposit: <Deposit />,
                            withdraw: <Withdraw />
                        }[toggle]
                    }
                    <OffcanvasBtnClose onClick={handleClose} />
                </Offcanvas.Body>
            </Offcanvas>
        </>

    )
}