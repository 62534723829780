export const fr_brand = {
    /******************/
    /** Page titles */
    
    page_home: "",
    page_games: "",
    page_game: "",
    page_login: "",
    page_registration: "",
    page_cashier: "",
    page_profile: "",
    page_forgot_password: "",
    page_reset_password: "",
    page_region_blocked: "",
    
    /******************/
    /** Brand Pages */

    // Home
    last_played_10: "Jump back in",
    last_played_20: "Time capsule",

    /******************/
    /** Brand components */

    // CashbackRoll.js
    cashback_roll_title: "Pick your prize",
    cashback_roll_next_ticket: "Next ticket unlocks in",
    // cashback_roll_deposit_title: "",
    // cashback_roll_btn_deposit: "",
    cashback_roll_tickets_left: `Tickets left:`,

    // HomeBanner.js
    home_banner_log_out_title_1: 'Bonus up to',
    home_banner_log_out_title_2: <><span className="title-lg home_baner-promo_font">600%</span> and <span className="title-lg home_baner-promo_font">1800 free spins!</span></>,
    home_banner_log_out_title_3: <><span className="subtitle-lg home_baner-promo_font">+ 10 free spins</span> with <span className="subtitle-lg home_baner-promo_font">no deposit</span></>,
    home_banner_log_in_title: 'Bienvenue à Orozino!',
    home_banner_log_in_title_name: function (name) { return `Bienvenue, ${name}` },
    home_banner_log_in_text: "Bonne chance aujourd'hui",
    home_banner_log_in_deposit_text: 'Il est temps de recharger votre solde!',
    home_banner_log_in_deposit_cta: "Déposer maintenant",

    // ResponsibleGaming.js
    responsible_gaming_title: 'Responsible gaming',
    responsible_gaming_text: 'We believe that it is our responsibility to our players and the gaming community to educate and provide support at the right moment.',
    responsible_gaming_cta: 'Learn more',

    // FirstDepositPromo.js
    first_deposit_promo_badge: "LIMITED TIME OFFER",
    first_deposit_promo_title: "Pick your first bonus and unlock over 30 bonus options more up to 1000% and 3000 free spins.",
    first_deposit_promo_title_home: "",
    first_deposit_promo_title_registration: "",
    first_deposit_cta: 'Enter the draw',

    // GoogleButton.js
    google_button_text: 'Continue with Google',

    // Footer.js
    footer_legal: "Orozino.com is operated by Spinsoft Interactive N.V. (Reg no. 160756), Abraham de Veerstraat 9, Willemstad, P.O. Box 3421, Curaçao, which having all the rights to operate the gaming software and/or offer games of chance under the Curacao License no. 365/JAZ. Payments are processed by Ashglade Ltd (ΗΕ 383723) registered in Cyprus, acting as the payments agent as per agreement between the two companies. Play responsibly.",
    footer_btn_terms: "Terms and conditions",
    footer_btn_privacy_policy: "Privacy policy",
    footer_btn_responsible_gaming: "Responsible gaming",
    footer_btn_dispute_resolution: "Dispute resolution",
    footer_btn_self_exclusion: "Self exclusion",
}