import { createSlice } from "@reduxjs/toolkit";
import { dbUserFromToken } from '../utilities/api-functions';

export const user = createSlice({
    name: "user",
    initialState: {
        isLoading: true,
        isLoggedIn: false,
        data: {},
    },
    reducers: {
        updateUser: (state, action) => {
            Object.assign(state, { ...state, ...action.payload });
        },
        logOutUser: (state) => {
            Object.assign(state, {
                ...state, ...{
                    isLoggedIn: false,
                    data: {}
                }
            });
        }
    },
});

export const checkUserAsync = () => async (dispatch) => {
    try {
        const result = await dbUserFromToken();

        if (!result?.data?.user) {
            dispatch(updateUser({
                isLoading: false,
                isLoggedIn: false,
            }));
            return;
        }

        dispatch(updateUser({
            isLoading: false,
            isLoggedIn: true,
            data: result.data.user,
        }));

    } catch (error) {
        dispatch(updateUser({
            isLoading: false,
            isLoggedIn: false
        }));
    }
};

// Action creators are generated for each case reducer function
export const { updateUser, logOutUser } = user.actions;

export default user.reducer;