import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** Components */
import isInViewport from "../utilities/is-in-viewport";

export default function GameFilter({ filter, setSearch, activeCategory, setIsScrolling }) {
    const refFilter = useRef(null)
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    useEffect(() => {
        const categoryLink = document.querySelector(`#game-filter-category-${activeCategory}`);

        /** Scroll the filter category */
        if (categoryLink?.offsetLeft && !isInViewport(categoryLink)) {
            refFilter.current.scrollLeft = categoryLink.offsetLeft - 50;
        }

    }, [activeCategory]);

    return (
        <Container fluid className={`games-filter-container ${userLoggedIn ? 'games-filter-container-logged-in' : ''}`} >
            <div className="games-filter" ref={refFilter}>
                {
                    !filter?.categories ? Array.apply(null, Array(7)).map((category, index) => (
                        <Placeholder as="div" animation="glow" key={index}>
                            <Placeholder className="games-category-placeholder" />
                        </Placeholder>
                    )) : (
                        filter?.categories?.map((category, index) =>
                            <Link to={`/games/${category.url}`}
                                className={`games-category ${category?.url === activeCategory ? 'games-category-active' : ''}`}
                                id={`game-filter-category-${category.url}`}
                                key={`game-filter-category-${index}`}
                                onClick={() => {
                                    setIsScrolling(true);
                                    setSearch('');
                                }}
                            >
                                {category?.title}
                            </Link>
                        )
                    )
                }
            </div>
        </Container>
    )
}