import React from "react";

/** Components */
import Loading from "./Loading";

export default function LoadingScreen() {
    return (
        <div className="loading-container">
            <Loading />
        </div>
    );
}