
import React from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Components */
import BonusTimer from "./BonusTimer";

/** Utilities */
import useText from "../utilities/use-text";

export default function BonusTile({ bonus }) {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();
    
    return (
        <>
            <div className="bonus-tile  animate__animated animate__fadeIn animate__delay-1s">
                {
                    bonus?.promoCode?.includes('TIMER') && (
                        <div className="bonus-tile-timer-wrapper">
                            <BonusTimer {...{ bonus }} />
                        </div>
                    )
                }

                <img src={`${bonus?.content?.imageUrl}?quality=50`} />
                <div className="bonus-tile-content">
                    <h3 className="subtitle font-full bonus-tile-title">{bonus?.content?.title}</h3>
                    <Link to={userLoggedIn ? `?cashier&promoCode=${bonus.promoCode}` : '?register'} className="btn btn-main bonus-tile-content-button">{text('bonus_tile_cta')}</Link>
                </div>
            </div>
        </>
    )
}