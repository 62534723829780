import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../utilities/use-text";

export default function DepositSummary({ sign, amount, promoCode }) {
    const text = useText();

    return (
        <div className="deposit-summary">
            <div>
                <p className="mb-0 font-mid">
                    {text('deposit_summary_amount')} <span className="font-full">{sign}{amount}</span>
                </p>
                <p className="mb-0 mt-1 font-mid deposit-summary-promocode">
                    {text('deposit_summary_bonus')} <span className="font-full">{promoCode ? promoCode : text('deposit_summary_no_bonus')}</span>
                </p>
            </div>
            <div>
                <FontAwesomeIcon icon={faCheckCircle} className="deposit-summary-icon" />
            </div>
        </div>
    )
}