import React from "react";

export function TagNew() {
    return (
        <div className="tag-container-right">
            <img src="https://spt-assets.b-cdn.net/orozino/tags/new.png" className="tag-image" alt="" />
            <span className="tag-name">New</span>
        </div>
    )
}

export function TagHot() {
    return (
        <div className="tag-container-right">
            <img src="https://spt-assets.b-cdn.net/orozino/tags/popular.png" className="tag-image" alt="" />
            <span className="tag-name">Hot</span>
        </div>
    )
}

export function TagLive() {
    return (
        <div className="tag-container-left">
            <span className="img-live" ></span>
            <span className="tag-name">Live</span>
        </div>
    )
}

export function TagLiveRight() {
    return (
        <div className="tag-container-right">
            <span className="img-live" ></span>
            <span className="tag-name">Live</span>
        </div>
    )
}