import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { useLocation } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import { useChannel } from 'ably/react';

/** Reducers */
import { checkUserAsync, updateUser } from './reducers/user';
import { getGlobalContentAsync } from './reducers/global-content';
import { getGameCategoriesAsync } from './reducers/game-categories';
import { getPromotionsAsync } from './reducers/promotions';
import { getBonusesAsync } from './reducers/bonuses';

/** Utilities */
import submitError from './utilities/submit-error';

/** Componnets */
import Navigation from './components/Navigation';
import ScrollRestoration from './components/ScrollRestoration';
import SmoothScroll from './components/SmoothScroll';
import IdleTimerWrapper from './components/IdleTimer';
import LoadingScreen from './components/LoadingScreen';
import IPBlock from './components/IPBlock';
import RouteChange from './components/RouteChange';
import ModalOpenAccount from './components/ModalOpenAccount';

/** Local routes */
import RenderRoutes from '../routes';

/** Metadata */
import metadata from '../metadata';

/** Styles */
// import '../App.scss';

export default function App() {
	const dispatch = useDispatch();

	/** User redux */
	const user = useSelector((state) => state.user.data);
	const userLoading = useSelector((state) => state.user.isLoading);
	const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

	/** Global content */
	const global = useSelector((state) => state.global.data);
	const globalLoading = useSelector((state) => state.global.isLoading);

	/** Ably Subscription */
	useChannel("player-updates", (message) => {
		// console.log(message.data.id)
		if (userLoggedIn) {
			if (message.data.id === user.id) {
				dispatch(updateUser({ data: { ...user, ...message.data } }));
			}
		}
	});

	function getLanguage(user) {
		try {
			let language = localStorage.getItem("selected_language") || user?.language || new URLSearchParams(window?.location?.search)?.get("lang") || window?.navigator?.language?.split('-')[0];

			if (!metadata?.languages?.includes(language)) {
				throw new Error(`Language ${language} is not supported`);
			}

			return language;
		} catch (error) {
			submitError('WARNING', `getLanguage, ${error}`);
			return metadata.default_language;
		}
	}

	useEffect(() => {

		/** Check if there is a logged in user */
		if (userLoading) {
			dispatch(checkUserAsync());
		}

		/** Once we know if the user is logged in or not, we load global content */
		if (!userLoading && globalLoading) {

			/** We get language from the browser. If that language is not supported, we take the default brand language */
			const language = getLanguage(user);

			/** Global content dispatcher*/
			dispatch(getGlobalContentAsync(language, user));
		}

		/** Once the website is loaded, we are getting all the other information */
		/** If we need to filter out promotions based on the user info, we pass it at this point */
		if (!userLoading && !globalLoading) {

			/** Game categories */
			dispatch(getGameCategoriesAsync(global))

			/** Bonuses */
			dispatch(getBonusesAsync(global, user));

			/** Promotions */
			dispatch(getPromotionsAsync(global));
		}

		return () => { };

	}, [userLoading, globalLoading]);

	return (
		<>
			{
				userLoading || globalLoading ? <LoadingScreen /> : (
					<>
						<HelmetProvider>
							<SmoothScroll />
							<ScrollRestoration />
							<RouteChange />
							<Navigation />
							<RenderRoutes />
							<IPBlock />
							<IdleTimerWrapper />
							<ModalOpenAccount />
						</HelmetProvider>
					</>
				)
			}
		</>
	);
}